import React from "react";
import {useQuery} from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartColumn, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle, faFilePdf, faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import "./PostPreviewBottomBar.css";
import { t } from "i18next";
import LikePostBtn from "./components/LikePostBtn/LikePostBtn";
import SavePostBtn from "./components/SavePostBtn/SavePostBtn";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import genRequest from "includes/request";
import documents_treat from "includes/documents_treat";
import Button from "components/Button/Button";
import useAccount from "classes/Accounts/hooks/useAccount";

function PostPreviewBottomBar(props) {
	const {account, isAdmin} = useAccount();
	const { post, setIsCommentsExtended, toggleDocsExtend, toggleExtend, isExtended, extend, toggleStats} = props;

	return (
		<div className="post-bottom-bar mt-2 mt-auto d-flex w-100 justify-content-between flex-column">
			{
				props.canComment ?
					(<Button className="btn btn-primary mb-2" onClick={() => setIsCommentsExtended(true)}>{props.commentBtnTxt ? props.commentBtnTxt : "Commenter"}</Button>)
					:
					false
			}
			<div className="d-flex w-100 justify-content-between align-items-center">
				{
					props.stats !== false &&
					<div className="d-flex justify-content-between align-items-center gap-3">
						{
							(account.EmployesId === post.EmployesId || isAdmin()) &&
								<Button
									className="unstyled btn-h-bg p-1"
									title={"Statistiques de la publication"}
									onClick={toggleStats}
								>
									<FontAwesomeIcon icon={faChartColumn}/>
								</Button>
						}
						<LikePostBtn postId={post.PostId} likesCount={post.LikesCount}/>
						<SavePostBtn postId={post.PostId} />
						{
							props.showComments &&
								<div className="post-comments-count-cont d-flex align-items-center cursor-pointer" title={t("Posts.SHOW_COMMENTS")} onClick={() => setIsCommentsExtended(true)}>
									<FontAwesomeIcon icon={faCommentAlt}/>
									<div className="post-comments-count ms-1">
										<InfosFetcher
											queryKey={["Comments", "CommentOfPost", + post.PostId]}
											queryFn={() => genRequest("Comments/CommentOfPost/" + post.PostId)}
											treat={(resp) => (resp.length)}
											loadText={"..."}
										/>
									</div>
								</div>
						}
					</div>
				}
				<div className="d-flex ms-auto">
					<DocumentsButton postId={post.PostId} toggleDocsExtend={toggleDocsExtend} documents={props.documents}/>
					{
						extend ?
							<div className="post-extend-btn cursor-pointer ms-3" title={!isExtended ? t("Posts.EXTEND_POST") : t("Posts.REDUCE_POST")} onClick={toggleExtend}>
								<FontAwesomeIcon icon={isExtended || (post && document.body.clientWidth <= 1200) ? faTimesCircle : faExpandAlt} />
							</div>
							:
							false
					}
				</div>
			</div>
		</div>
	);
}

function DocumentsButton({postId, toggleDocsExtend, documents}) {
	const fetchDocs = () => {
		let req = genRequest("Documents/DocumentOfPostAndType/" + postId + "/4")
			.then((resp) => documents_treat(resp, "PublishedDate"));
		return (req);
	};

	const {isLoading, data} = useQuery(
		["Documents", "DocumentOfPostAndType", postId, 4],
		fetchDocs,
		{
			enabled: (postId && !documents ? true : false)
		}
	);

	let count = documents ? documents?.length : data?.length;

	if ((!postId && !documents) || isLoading || (!isLoading && !count))
		return (false);

	return (
		<Button className="post-docs-btn btn-primary radius-3" onClick={toggleDocsExtend} title={t("Posts.DISPLAY_DOCS")}>
			<FontAwesomeIcon icon={faFilePdf} className="me-2"/>
			{count} { t("Posts.DOCS_BTN", {count}) }
		</Button>
	);
}

export default PostPreviewBottomBar;
