import React, {useState} from "react";
import {useQueryClient} from "react-query";
import Moment from "react-moment";
import { AsyncScrollTable } from "components/ScrollTable/ScrollTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { t } from "i18next";
import genRequest from "includes/request";
import confirmToast from "components/Toasts/ConfirmToast";
import Button from "components/Button/Button";
import DocUploader from "Pages/Documents/components/DocUploader/DocUploader";
import LoadCont from "components/LoadCont/LoadCont";
import promiseToast from "components/Toasts/PromiseToast";

export default function UserDocsStep(props) {
	const [importing, setImporting] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [progress, setProgress] = useState(false);
	let user = props.object;

	const handleUpload = (doc) => {
		setProgress(0);
		doc.siteId(user.siteId());
		doc.employeId(user.employesId());
		setIsSending(true);
		setImporting(false);
		let req = doc.send(0, handleUploadProgress).then(() => {
			setIsSending(false);
		}, () => {
			setIsSending(false);
		});
		promiseToast(req);
		return (req);
	};

	const handleUploadProgress = (status) => setProgress(Math.round((status.loaded / status.total) * 100));

	if (isSending)
		return (
			<div className="inner-step-cont">
				<LoadCont
					text={"Envoi du document - " + progress + "%"}
				/>
			</div>
		);

	if (importing)
		return (
			<div className="inner-step-cont">
				<DocUploader
					selCats={false}
					typeId={1}
					companyId={user.companyId()}
					employesId={user.employesId()}
					title="Importer un document"
					onConfirm={handleUpload}
					onCancel={() => setImporting(false)}
				/>
			</div>
		);

	return (
		<div className="inner-step-cont">
			<div className="d-flex justify-content-between">
				<h4>Documents personnels</h4>
				<div>
					<Button className="btn-blue" onClick={() => setImporting(true)}>Importer un document</Button>
				</div>
			</div>
			<AsyncScrollTable
				queryKey={["Documents", "DocumentOfEmployeAndType", user.employesId(), "1"]}
				queryFn={() => user.fetchLinkedDocs()}
				itemId="DocumentId"
				cols={[
					{
						title: "Nom",
						name: "Name"
					},
					{
						title: "Date",
						name: "LastChanged",
						component: DateCell
					},
					{
						title: "Aperçu",
						name: "preview",
						component: PreviewCell
					},
					{
						title: "",
						name: "rm",
						component: DeleteDoc,
						sort: false,
						childProps: {
							employesId: user.employesId()
						}
					}
				]}
				sortBy="LastChanged"
				revSort={true}
				className="dark-bg"
			/>
		</div>
	);
}

function DateCell({item}) {
	return (
		<div className="d-flex align-items-center flex-wrap gap-1 justify-content-between">
			<Moment
				titleFormat="D MMM YYYY HH:mm"
				withTitle
				format={t("Events.TILE_DATE_FORMAT")}
			>
				{item.LastChanged}
			</Moment>
		</div>
	);
}

function PreviewCell(props) {
	let doc = props.item;

	if (!doc?.Url_Origin)
		return (<div>{t("Commons.ERROR")}</div>);
	if (doc.TempId)
		return (<div>{t("Docs.IMPORTED_DOCS")}</div>);
	return (
		<a href={doc.Url_Origin} rel="noreferrer" onClick={(e) => e.stopPropagation()} target="_blank">{t("Table.PREVIEW")}</a>
	);
}

function DeleteDoc({item, employesId}) {
	const [deleting, setDeleting] = useState(false);
	const QCL = useQueryClient();

	if (!item.DocumentId)
		return (false);

	const handleDelete = () => {
		let t = confirmToast(
			"Êtes-vous sûr de vouloir supprimer le document ?",
			deleteDoc,
			() => {}
		);
		return (t);
	};

	const deleteDoc = () => {
		setDeleting(true);
		let req = genRequest(
			`Documents/DocumentId=${item.DocumentId}AnddeleteExpenses=true`,
			null,
			"delete"
		).then(() => {
			setDeleting(false);
			QCL.refetchQueries(["Documents", "DocumentOfEmployeAndType", employesId, "1"]);
		}, () => {
			setDeleting(false);
		});
		return (req);
	};

	if (deleting)
		return (
			<LoadCont text="Suppression" className="inline gap-2"/>
		);

	return (
		<div className="d-flex align-items-center gap-1" onClick={handleDelete}>
			<FontAwesomeIcon icon={faTrashAlt} color={"red"}/>{t("Commons.DELETE")}
		</div>
	);
}
