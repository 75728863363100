import React, { useEffect, useState } from "react";
import { t } from "i18next";
import genRequest from "includes/request";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import Tabs from "components/Tabs/Tabs";
import useAccount from "classes/Accounts/hooks/useAccount";
// eslint-disable-next-line
import { DatePickerToggler } from "components/DatePIcker/DatePicker";
import { InnerStepCont } from "components/EditDrawer/EditDrawer";
import useTemplate from "hooks/useTemplate";
import { isVisible } from "@syncfusion/ej2-base";

export default function DocSendOptionsStep(props) {
	const { getSettings, account } = useAccount();
	let doc = props.object;
	const { getAppFunction } = useTemplate();
	const [, setUpdate] = useState(0);
	const [NotifyDoc, setNotifyDoc] = useState(
		doc.notification() || doc.isDiffered()
	);
	const [ShareableDoc, setShareableDoc] = useState(
		doc.isShareable() !== null
			? doc.isShareable()
			: getAppFunction("DocumentsPage.AllowSharing")
	);
	const [tabs, setTabs] = useState([]);

	function handleNotify(checked) {
		doc.notification(checked);
		setNotifyDoc(checked);
	}

	// eslint-disable-next-line
	function handleDiffered(value) {
		doc.publishedDate(value);
		handleNotify(true);
		setUpdate(Date.now());
	}

	function handleShare(value) {
		doc.isShareable(value);
		setShareableDoc(value);
	}

	useEffect(() => {
		setTabs([
			{
				title: t("Commons.PUBLISH_BY_SITES"),
				child: () => <PublishBySites doc={doc} />,
				isVisible: true,
			},
			{
				title: t("Groups.PUBLISH_BY_GROUPS"),
				child: () => <PublishByGroups doc={doc} />,
				isVisible: true,
			},
		]);
	}, [account]);

	let cur_index = 0;
	let linksTo = doc.getLinksTo();
	let initialLinks = doc.links();

	if (!linksTo.links?.length)
		doc.setLinkTo(initialLinks.links, initialLinks.type, doc.siteId());

	let default_link = getSettings("DefaultLinkType");

	if (!doc.documentId()) cur_index = default_link === "groups" ? 1 : 0;
	else if (doc.linkType() === "groups") cur_index = 1;

	return (
		<div>
			<InnerStepCont title={t("Commons.TARGETS")}>
				<div className="inner-step-cont">
					<Tabs
						items={tabs}
						allowOverflow={true}
						activeIndex={cur_index}
						onChange={(tab) => {
							doc.linkType(tab.id === 1 ? "groups" : "sites");
						}}
					/>
				</div>
			</InnerStepCont>
			<InnerStepCont title={t("Commons.PUBLISH_OPTIONS")}>
				{/* <DatePickerToggler
					className="mt-2"
					onChange={handleDiffered}
					startDate={new Date(doc.publishedDate())}
					isChecked={doc.isDiffered()}
					label={"Publication différée"}
				/> */}
				<div className="mt-2 inner-step-cont">
					<SwitchInput
						label={t("Docs.ALLOW_SHARE")}
						onlyState={true}
						checked={ShareableDoc}
						onChange={handleShare}
					/>
				</div>
				<div className="mt-2 inner-step-cont">
					<SwitchInput
						label={
							t("Commons.NOTIFY_USERS") +
							(doc.isDiffered()
								? ` (${t("Commons.DEFERRED")})`
								: "")
						}
						onlyState={true}
						checked={NotifyDoc}
						onChange={handleNotify}
					/>
				</div>
			</InnerStepCont>
		</div>
	);
}

function PublishBySites({ doc }) {
	const { account } = useAccount();

	function sitesSelect(values) {
		let siteIds = values.map((a) => a.value);
		doc.setLinkTo(
			siteIds,
			"sites",
			siteIds.indexOf(account.SiteId) !== -1 ? account.SiteId : false
		);
	}

	function getInitialValues() {
		let initialValues = doc.getLinksTo();

		let ret = initialValues.links.map((a) => ({
			label: t("Commons.LOADING"),
			value: a,
		}));
		if (!initialValues?.links?.length)
			ret = doc
				.linkedSites()
				.map((a) => ({ label: t("Commons.LOADING"), value: a.SiteId }));
		if (doc.siteId())
			ret.push({ label: t("Commons.LOADING"), value: doc.siteId() });
		return ret;
	}

	return (
		<div className="sites-drop-cont">
			<QuerySelect
				placeholder={t("Commons.SELECT_SITES")}
				className={"bg-color w-100 rounded"}
				classNamePrefix="dropdown"
				queryKey={["Sites", "SitesOfCompany", account.CompanyId]}
				queryFn={() =>
					genRequest("Sites/SitesOfCompany/" + account.CompanyId)
				}
				treat={(site) => ({
					value: site.SiteId,
					label: site.Name + " - " + site.Town,
				})}
				all={{
					label: t("Commons.ALL_SITES"),
					value: "ALL",
				}}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				onSelect={sitesSelect}
				initialValue={getInitialValues()}
			/>
		</div>
	);
}

function PublishByGroups({ doc }) {
	const { account } = useAccount();

	function groupsSelect(values) {
		doc.setLinkTo(
			values.map((a) => a.value),
			"groups"
		);
	}

	function getInitialValues() {
		let initialValues = doc.getLinksTo();

		if (!initialValues?.links?.length)
			return doc.linkedGroups().map((a) => ({
				label: t("Commons.LOADING"),
				value: a.GroupId,
			}));
		return initialValues.links.map((a) => ({
			label: t("Commons.LOADING"),
			value: a,
		}));
	}

	return (
		<div className="groups-drop-cont">
			<QuerySelect
				placeholder={t("Groups.SELECT_GROUPS")}
				className={"bg-color w-100 rounded"}
				classNamePrefix="dropdown"
				queryKey={["Groups", "OfCompany", account.CompanyId]}
				queryFn={() =>
					genRequest("Groups/OfCompany/" + account.CompanyId)
				}
				treat={(cat) => ({
					value: cat.GroupId,
					label: cat.Name,
				})}
				all={{
					label: t("Groups.ALL_GROUPS"),
					value: "ALL",
				}}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				onSelect={groupsSelect}
				initialValue={getInitialValues()}
			/>
		</div>
	);
}
