import React, { Component, useState } from "react";
import FormInput from "components/Inputs/FormInput";
import "./DocUploader.css";
import { t } from "i18next";
import { humanFileSize } from "includes/documents_treat";
import DocumentObject from "classes/Documents/DocumentObject";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import PageLoad from "components/PageLoad/PageLoad";
import DocumentTile from "../Tile/DocumentTile";
import generateUUID from "includes/UUID";
import Button from "components/Button/Button";
import CategoriesSelect from "components/CustomSelects/CategoriesSelect";
import BackButton from "components/Button/BackButton";
import useAccount from "classes/Accounts/hooks/useAccount";
import { TypeRights, returnRightsFromName } from "includes/rightType";

class DocUploader extends Component {
	#default_max_size = 20 * Math.pow(1024, 2);

	constructor(props) {
		super(props);

		this.input_ref = React.createRef();
		this.state = {
			file: props.doc?.typeof ? props.doc.file() : false,
			error: false,
			isLoading: false,
			doc: props.doc?.typeof ? props.doc : false,
		};
		this.handleFile = this.handleFile.bind(this);
		this.catsSelect = this.catsSelect.bind(this);
		this.modifyFile = this.modifyFile.bind(this);
		this.handleConfirm = this.handleConfirm.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
	}

	componentDidMount() {
		if (!this.state.doc) {
			const doc = new DocumentObject({
				DocTypeId: this.props.typeId,
				CompanyId: this.props.companyId,
				EmployeId: this.props.employesId,
				IsNew: true,
				IsTargetDoc: true,
			});
			this.setState({ doc: doc }, () => {
				if (this.props.focusOnLoaded !== false)
					this.input_ref?.current?.click();
			});
		}
	}

	handleFile(event) {
		let files = event.target.files;
		let reader = new FileReader();
		let file = files[0];
		this.setState({
			file: false,
			error: false,
		});
		if (file) {
			let max = this.props.maxSize
				? this.props.maxSize
				: this.#default_max_size;
			if (max && file.size > max) {
				this.setState({
					error:
						t("Files.FILE_SIZE_ERROR") +
						" (" +
						humanFileSize(file.size) +
						") " +
						t("Files.FILE_SIZE_ERROR_2") +
						" " +
						humanFileSize(max),
				});
				event.target.value = "";
				return false;
			}
			this.setState({ isLoading: true });
			reader.readAsDataURL(file);
			reader.onloadend = (e) => {
				let loaded_file = file;
				loaded_file.data = e.target.result;
				this.setState(
					{
						file: loaded_file,
					},
					() => {
						this.state.doc.file(file);
						this.state.doc.documentFile(
							loaded_file.data.split(",")[1]
						);
						this.state.doc.urlOrigin(loaded_file.data);
						if (this.props.onChange)
							this.props.onChange(this.state.doc);
					}
				);
				event.target.value = "";
				this.setState({ isLoading: false });
			};
		}
	}

	catsSelect(values) {
		this.state.doc.categories(
			values.map((a) => ({
				CategoryId: !a.isNew ? a.value : false,
				DefaultHexaColor: a.color,
				Name: a.label,
				isNew: a.isNew ? true : false,
			}))
		);
	}

	modifyFile() {
		this.input_ref?.current?.click();
	}

	handleConfirm() {
		let doc = this.state.doc;
		if (this.props.onConfirm) {
			let errors = [];
			if (!doc.name()) errors.push(t("Files.SET_NAME"));
			if (!doc.documentFile()) errors.push(t("Files.SELECT_FILE"));
			if (errors.length)
				return this.setState({
					error: errors,
				});
			this.props.onConfirm(doc);
		}
	}

	handleCancel() {
		if (this.props.onCancel) this.props.onCancel();
	}

	render() {
		return (
			<div
				className={
					"DocUploader" +
					(this.props.className ? " " + this.props.className : "")
				}
			>
				{(this.props.title ||
					this.props.onConfirm ||
					this.props.onCancel) && (
					<div className="d-flex justify-content-between align-items-center mb-4">
						<div className="d-flex gap-2 align-items-center">
							{this.props?.onCancel && (
								<BackButton
									onClick={this.handleCancel}
									value={t("Commons.CANCEL")}
								></BackButton>
							)}
							{this.props.title}
						</div>
						{this.props?.onConfirm && (
							<div>
								<Button
									className="btn-green"
									onClick={this.handleConfirm}
								>
									{t("Commons.ADD")}
								</Button>
							</div>
						)}
					</div>
				)}
				<ErrorsCont className="mb-4" errors={this.state.error} />
				<div className="mb-4">
					{this.state.doc && (
						<FormInput
							className="w-100"
							title={t("Files.FILE_NAME")}
							value={this.state.doc?.name()}
							onChange={(val) => this.state.doc?.name(val)}
						/>
					)}
				</div>
				{this.props.selCats !== false && (
					<CategoriesSelect
						className="mb-4"
						type={"documents"}
						companyId={this.props.companyId}
						initialValue={
							this.state.doc ? this.state.doc.categories() : false
						}
						onSelect={this.catsSelect}
					/>
				)}
				{this.props.docsUtilImport === true && this.state.doc && (
					<DocUpload fc={this.state.doc?.addToDocsUtiles} />
				)}
				<div>
					<div className="mb-2 d-flex justify-content-between align-items-center">
						<div>{t("Files.FILE")}</div>
						{this.state.file && (
							<div>
								<Button
									className="btn btn-blue"
									onClick={() => this.modifyFile()}
								>
									{t("Commons.MODIFY")}
								</Button>
							</div>
						)}
					</div>
					<div
						className={
							"drop-cont " +
							(this.state.file ? "d-none " : "d-flex ") +
							(this.state.error && !this.state.file
								? "error "
								: "") +
							" justify-content-center align-items-center"
						}
					>
						{this.state.isLoading && (
							<div className="">{t("Files.FILE_LOAD")}</div>
						)}
						{!this.state.isLoading && (
							<div className="placeholder">
								{t("Files.FILE_PH")}
							</div>
						)}
						<input
							ref={this.input_ref}
							className="file-input"
							accept="application/pdf"
							type="file"
							onChange={this.handleFile}
						/>
					</div>
					{this.state.file && (
						<DocPreview
							file={this.state.file}
							url={this.state.file?.data}
							name={this.state.file.name}
						/>
					)}
				</div>
			</div>
		);
	}
}

const DocUpload = (props) => {
	const { account } = useAccount();
	const checkRight = () => {
		const rigthName = TypeRights.AdminDocument;
		try {
			return returnRightsFromName(account.Rights, rigthName);
		} catch (error) {
			return true;
		}
	};

	return (
		<>
			{checkRight() && (
				<div className="mb-4">
					<div className="options">{t("Commons.OPTIONS")}</div>
					<SwitchInput
						label={t("Docs.ADD_TO_DOCS_UTIL")}
						onlyState={true}
						checked={props.fc()}
						onChange={(val) => props.fc(val)}
					/>
				</div>
			)}
		</>
	);
};

function DocPreview({ url, file, name }) {
	const [isLoaded, setIsLoaded] = useState(false);
	if (!url) return false;

	function handleLoad() {
		setIsLoaded(true);
	}

	return (
		<div className="DocPreview">
			<DocumentTile
				doc={{
					Name: name,
					DocumentId: generateUUID(),
				}}
				onClick={() => {}}
				handleDelete={false}
				handleSave={false}
				categories={false}
				size={file.size}
				className={"pe-none"}
			/>
			{file?.size < 2000000 ? (
				<>
					<iframe
						title={name}
						src={url}
						className="mt-2 w-100"
						onLoad={handleLoad}
					/>
					{!isLoaded && (
						<div className="loading-frame">
							<PageLoad text={t("Commons.PREVIEW_LOAD")} />
						</div>
					)}
				</>
			) : (
				<div className="mt-2 w-100 text-center too-heavy-msg">
					{t("Docs.PREVIEW_SIZE_ERROR")}
				</div>
			)}
		</div>
	);
}

export default DocUploader;
