import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { registerLicense } from "@syncfusion/ej2-base";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
//import reportWebVitals from "./reportWebVitals";
import "./i18n/i18n";
import ServerCheck from "ServerCheck";
import MarkerIoButton from "components/MarkerIoButton/MarkerIoButton";

//syncfusion licence
registerLicense(
	"ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdEJiWH1ZdXBcT2hY"
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

if (!process.env.NODE_ENV === "development")
	Sentry.init({
		dsn: "https://fe25c1d07f5c4dbd94d8fb58b22d68da@o4504009593585664.ingest.sentry.io/4504009595092992",
		integrations: [new BrowserTracing()],
		environment:
			process.env.NODE_ENV === "development"
				? "development"
				: process.env.REACT_APP_ENVIRONMENT,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.3,
	});

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ServerCheck>
				<App />
			</ServerCheck>
			<MarkerIoButton />
			<ReactQueryDevtools initialIsOpen={false} position="top-left" />
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
