import React, { useState, useRef } from "react";
import Moment from "react-moment";
import { t } from "i18next";
import formatDate from "includes/formatDate";
import useUnread from "classes/Accounts/hooks/useUnread";
import TagLabel, { NewLabel } from "components/Tags/Label/TagLabel";
import useAccount from "classes/Accounts/hooks/useAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import DocsTargetsCell from "./Cells/Targets";
import DocsTableToolbar from "./DocsTableToolbar";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import genRequest from "includes/request";
import Table from "components/Table/Table";

export default function DocsTable(props) {
	const { isAdmin } = useAccount();
	const { setAsReaded } = useUnread();
	const [selected, setSelected] = useState([]);
	const state_ref = useRef();

	React.useEffect(() => {
		if (props.state !== state_ref.current && selected?.length)
			setSelected([]);
		state_ref.current = props.state;
	}, [selected, props, setSelected]);

	const cols = [
		{
			title: t("Table.TITLE"),
			field: "Name",
			classes: "post-title-cell",
			render: (data) => <TitleCell item={data} />,
		},
		{
			title: t("Posts.VIEWS"),
			field: "ViewCount",
			classes: "post-views-cell",
			noValuePh: "0",
			render: (data) => <ViewsCell item={data} />,
			hidden: !isAdmin(),
		},
		{
			title: t("Posts.PUBLISHED_DATE"),
			field: "PublishedDate",
			classes: "post-date-cell",
			render: (data) => <DateCell item={data} />,
		},
		{
			title: t("Commons.CATEGORIES"),
			field: "Categories",
			classes: "post-cats-cell",
			render: (data) => <CategoriesCell item={data} />,
			sorting: false,
		},
		{
			title: t("Commons.TARGETS"),
			field: "Targets",
			render: (data) => <DocsTargetsCell item={data} />,
			sorting: false,
			hidden: !isAdmin(),
		},
	];

	// useless for now
	function filterItems(a) {
		return a;
	}

	function incrementViews(doc) {
		let req = genRequest("Documents/IncrementView", doc.DocumentId, "put")
			.then((resp) => {
				return resp;
			})
			.catch(() => {});
		return req;
	}

	function handleClick(e, item) {
		incrementViews(item).then(() => {
			setAsReaded("NotViewedDocUtils", item.DocumentId);
		});
		props.handleDocClick(item);
	}

	let items = props.displayed;

	if (!items || !items.length) items = [];

	return (
		<div
			className={
				"DocsTable w-100" + (props.curDoc?.DocumentId ? " has-cur" : "")
			}
		>
			<Table
				onRowClick={handleClick}
				columns={cols}
				filter={filterItems}
				data={props.displayed}
				rowIdKey={"DocumentId"}
				activeRowId={props.curDoc?.DocumentId}
				options={{
					selection: isAdmin(),
				}}
				overloads={{
					Toolbar: (cprops, options) => (
						<DocsTableToolbar {...cprops} {...options} />
					),
				}}
			/>
		</div>
	);
}

const TitleCell = React.memo(({ item }) => {
	const { isReaded } = useUnread();
	let isDocReaded = isReaded("NotViewedDocUtils", item.DocumentId);
	let title = item.Name;

	return (
		<div className="title-cell">
			{!isDocReaded && <NewLabel className="me-1" />}
			{title}
		</div>
	);
});
TitleCell.displayName = "TitleCell";

const ViewsCell = React.memo(({ item }) => {
	return (
		<InfosFetcher
			queryFn={() =>
				genRequest(`LtEmployesViewDoc/DocumentId/${item.DocumentId}`)
			}
			queryKey={["LtEmployesViewDoc", "DocumentId", item.DocumentId]}
			treat={(infos) => {
				item.ViewCount = infos?.length;
				return infos?.length ? infos.length : 0;
			}}
			displayOnError={true}
			options={{
				retry: 0,
			}}
		/>
	);
});
ViewsCell.displayName = "ViewsCell";

const DateCell = React.memo(({ item }) => {
	let date = formatDate();

	return (
		<div className="d-flex align-items-center flex-wrap gap-1 justify-content-between">
			<Moment
				titleFormat="D MMM YYYY HH:mm"
				withTitle
				format={t("Events.TILE_DATE_FORMAT")}
			>
				{item.PublishedDate}
			</Moment>
			{item.PublishedDate >= date && (
				<div className="d-flex flex-wrap gap-1 align-items-center justify-content-center me-1 date-pictos">
					{item.PublishedDate >= date && (
						<div
							title={`${t("Commons.DEFERRED_TO")} ${formatDate(
								item.PublishedDate,
								t("Commons.CUSTOM_FORMAT_DATE")
							)}`}
						>
							<FontAwesomeIcon
								className="differed-picto"
								title={t("Commons.DEFERRED_PUBLISH")}
								icon={faCalendarAlt}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
});
DateCell.displayName = "DateCell";

const CategoriesCell = React.memo(({ item }) => {
	return (
		<InfosFetcher
			queryKey={["Categories", "Document", item.DocumentId]}
			queryFn={() => genRequest("Categories/Document/" + item.DocumentId)}
			treat={(resp) => {
				if (!resp?.length) return false;
				return (
					<div className="d-flex flex-wrap gap-1">
						{resp
							.sort((a, b) => (a.Name < b.Name ? -1 : 1))
							.map((a) => (
								<TagLabel
									key={a.CategoryId}
									text={a.Name}
									color={a.DefaultHexaColor}
								/>
							))}
					</div>
				);
			}}
			options={{
				retry: 0,
			}}
		/>
	);
});
CategoriesCell.displayName = "CategoriesCell";
