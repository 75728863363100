import React, { useState, useRef, useEffect } from "react";
import { t } from "i18next";
import genRequest from "includes/request";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import Tabs from "components/Tabs/Tabs";
import useAccount from "classes/Accounts/hooks/useAccount";
import { DatePickerToggler } from "components/DatePIcker/DatePicker";
import { InnerStepCont } from "components/EditDrawer/EditDrawer";
import ReactionsTypeSelect from "components/CustomSelects/ReactionsTypeSelect";
import useTemplate from "hooks/useTemplate";

export default function PostSendOptionsStep(props) {
	const { isAdmin, getSettings, account } = useAccount();
	const { template } = useTemplate();
	let post = props.object;

	const [, setUpdate] = useState(0);
	const [NotifyPost, setNotifyPost] = useState(
		post.notification() || post.isDiffered()
	);
	const isInitied = useRef();
	const cur_index = useRef(0);
	const [tabs, setTabs] = useState([]);

	React.useEffect(() => {
		if (!isInitied.current) {
			let linksTo = post.getLinksTo();
			let initialLinks = post.links();

			if (!linksTo.links?.length)
				post.setLinkTo(
					initialLinks.links,
					initialLinks.type,
					post.siteId()
				);

			if (post.linkType() === "groups") cur_index.current = 1;

			if (!isAdmin())
				post.reactionType({
					ReactionTypeId: template.getDefaultReaction(
						post.postTypeName
					),
				});
			isInitied.current = true;
		}
	}, []);

	function handleNotify(checked) {
		post.notification(checked);
		setNotifyPost(checked);
	}

	function handleDiffered(value) {
		post.publishedDate(value);
		handleNotify(true);
		setUpdate(Date.now());
	}

	function handlePriority(value) {
		if (value) post.priorityTo(value);
		else post.priorityTo(post.publishedDate());
	}

	useEffect(() => {
		setTabs([
			{
				title: t("Commons.PUBLISH_BY_SITES"),
				child: () => <PublishBySites post={post} />,
				isVisible: true,
			},
			{
				title: t("Groups.PUBLISH_BY_GROUPS"),
				child: () => <PublishByGroups post={post} />,
				isVisible: true,
			},
		]);
	}, [account]);

	let default_link = getSettings("DefaultLinkType");

	if (!post.postId()) cur_index.current = default_link === "groups" ? 1 : 0;
	else if (post.linkType() === "groups") cur_index.current = 1;

	return (
		<div>
			<InnerStepCont title={t("Commons.TARGETS")}>
				<div className="inner-step-cont">
					<Tabs
						items={tabs}
						allowOverflow={true}
						activeIndex={cur_index.current}
						onChange={(tab) => {
							post.linkType(tab.id === 1 ? "groups" : "sites");
						}}
					/>
				</div>
			</InnerStepCont>
			<InnerStepCont title={t("Commons.PUBLISH_OPTIONS")}>
				<DatePickerToggler
					className="mt-2"
					onChange={handleDiffered}
					startDate={new Date(post.publishedDate())}
					isChecked={post.isDiffered()}
					label={t("Commons.DEFERRED_PUBLISH")}
				/>
				<DatePickerToggler
					className="mt-2"
					onChange={handlePriority}
					nullable={true}
					startDate={
						post.priorityTo()
							? new Date(post.priorityTo())
							: new Date()
					}
					isChecked={post.isPriority()}
					label={t("Commons.PRIORITY_PUBLISH")}
				/>
				<div className="mt-2 inner-step-cont">
					<SwitchInput
						label={
							t("Commons.NOTIFY_USERS") +
							(post.isDiffered()
								? ` (${t("Commons.DEFERRED")})`
								: "")
						}
						onlyState={true}
						checked={NotifyPost}
						onChange={handleNotify}
					/>
				</div>
				{getSettings("Reactions") && (
					<div className="mt-2 inner-step-cont">
						<ReactionsTypeSelect
							title={"Type de réaction"}
							type={post.postTypeId()}
							initialValue={
								post.reactionType()?.ReactionTypeId
									? {
										value: post.reactionType()
											?.ReactionTypeId,
										label: "",
									}
									: false
							}
							onDefaultLoaded={(val) => {
								let type = post.reactionType();
								if (!type)
									post.reactionType(
										val
											? {
												ReactionTypeId:
													val.ReactionTypeId,
											}
											: false
									);
							}}
							onSelect={(val) =>
								post.reactionType(
									val ? { ReactionTypeId: val } : false
								)
							}
						/>
					</div>
				)}
			</InnerStepCont>
		</div>
	);
}

function PublishBySites({ post }) {
	const { account } = useAccount();

	function sitesSelect(values) {
		let siteIds = values.map((a) => a.value);
		post.setLinkTo(
			siteIds,
			"sites",
			siteIds.indexOf(account.SiteId) !== -1 ? account.SiteId : false
		);
	}

	function getInitialValues() {
		let initialValues = post.getLinksTo();

		let ret = initialValues.links.map((a) => ({
			label: t("Commons.LOADING"),
			value: a,
		}));
		if (!initialValues?.links?.length)
			ret = post
				.linkedSites()
				.map((a) => ({ label: t("Commons.LOADING"), value: a.SiteId }));
		if (post.siteId())
			ret.push({ label: t("Commons.LOADING"), value: post.siteId() });
		return ret;
	}

	return (
		<div className="sites-drop-cont">
			<QuerySelect
				placeholder={t("Commons.SELECT_SITES")}
				className={"bg-color w-100 rounded"}
				classNamePrefix="dropdown"
				queryKey={["Sites", "SitesOfCompany", account.CompanyId]}
				queryFn={() =>
					genRequest("Sites/SitesOfCompany/" + account.CompanyId)
				}
				treat={(site) => ({
					value: site.SiteId,
					label: site.Name + " - " + site.Town,
				})}
				all={{
					label: t("Commons.ALL_SITES"),
					value: "ALL",
				}}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				onSelect={sitesSelect}
				initialValue={getInitialValues()}
			/>
		</div>
	);
}

function PublishByGroups({ post }) {
	const { account } = useAccount();

	function groupsSelect(values) {
		post.setLinkTo(
			values.map((a) => a.value),
			"groups"
		);
	}

	function getInitialValues() {
		let initialValues = post.getLinksTo();

		if (!initialValues?.links?.length)
			return post.linkedGroups().map((a) => ({
				label: t("Commons.LOADING"),
				value: a.GroupId,
			}));
		return initialValues.links.map((a) => ({
			label: t("Commons.LOADING"),
			value: a,
		}));
	}

	return (
		<div className="groups-drop-cont">
			<QuerySelect
				placeholder={t("Groups.SELECT_GROUPS")}
				className={"bg-color w-100 rounded"}
				classNamePrefix="dropdown"
				queryKey={["Groups", "OfCompany", account.CompanyId]}
				queryFn={() =>
					genRequest("Groups/OfCompany/" + account.CompanyId)
				}
				treat={(cat) => ({
					value: cat.GroupId,
					label: cat.Name,
				})}
				all={{
					label: t("Groups.ALL_GROUPS"),
					value: "ALL",
				}}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				onSelect={groupsSelect}
				initialValue={getInitialValues()}
			/>
		</div>
	);
}

export function checkTargets(post) {
	let linkTo = post.getLinksTo();
	if (!linkTo || (linkTo.type === "groups" && !linkTo?.links?.length))
		return t("Assignator.NO_TARGET");
	return true;
}
