import React, { useState } from "react";
import { useQueryClient } from "react-query";
import AnimationsController from "classes/Animations/AnimtionsController";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import PreviewDrawer from "components/PreviewDrawer/PreviewDrawer";
import useUnread from "classes/Accounts/hooks/useUnread";
import genRequest from "includes/request";
import AnimationObject from "classes/Animations/AnimationObject";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import formatDate, { dateAdd } from "includes/formatDate";
import useEditing from "components/EditDrawer/useEditing";
import promiseToast from "components/Toasts/PromiseToast";
import useSelect from "hooks/useSelect";
import EventsList from "./components/EventsList/EventsList";
import AnimationEditDrawer from "./components/AnimationEditDrawer/AnimationEditDrawer";
import AnimationsDraftsTable from "./components/AnimationsDraftsTable";
import Calendar from "./components/Calendar/Calendar";
import AnimationPreview from "./components/AnimationPreview/AnimationPreview";
import useTemplate from "hooks/useTemplate";

export default function AnimationsPage(props) {
	return (
		<>
			<AnimationsController
				childComponent={AnimsPageBody}
				childprops={props}
				events={props.animations}
			/>
		</>
	);
}

function AnimsPageBody(props) {
	const QCL = useQueryClient();
	const { account, isAdmin } = useAccount();
	const { getAppFunction } = useTemplate();
	const { setAsReaded } = useUnread();
	const [curDay, setCurday] = useState(false);
	const [curEvent, setCurEvent] = useState(false);
	const [editObj, setEditObj] = useState(false);
	const [ShowDrafts, setShowDrafts] = useState(false);
	const { editing, setEditing } = useEditing();
	const { isMenuOpen, setIsMenuOpen } = props;
	const { confirmDelete } = useSelect();

	function handleEventClick(event) {
		setCurEvent(event);
		incrementViews(event).then(() => {
			setAsReaded("NotViewedEvent", event.id);
		});
	}

	function handleDraftClick(event) {
		setCurEvent({ ...event, id: event.AnimationId });
	}

	const incrementViews = (event) => {
		let req = genRequest(
			"Animations/IncrementView/" + event.id,
			null,
			"put"
		)
			.then((resp) => {
				return resp;
			})
			.catch(() => {});
		return req;
	};

	function handleDateClick(arg, events) {
		setCurday({
			events: events,
			date: arg.date,
		});
		setCurEvent(false);
	}

	function handleClosePreview() {
		setCurEvent(false);
	}

	function handleAdd(initAnimObj) {
		setCurEvent(false);
		if (!isAdmin()) return false;
		let date = formatDate(initAnimObj?.DateStart || new Date());
		let obj = new AnimationObject({
			CompanyId: account.CompanyId,
			DateStart: date,
			DateEnd: dateAdd(date, { hours: 1 }),
			author: account,
			LinkType: "sites",
			DisplayParticipation: getAppFunction(
				"AnimationsPage.CanParticipate"
			),
			...initAnimObj,
		});
		setEditObj(obj);
		setEditing(true);
	}

	function handleModify(item) {
		if (!isAdmin()) return false;
		let anim = item;
		if (item.extendedProps) anim = item.extendedProps;
		let obj = new AnimationObject({ ...anim, IsDraft: false });
		let prom = obj.fetchForEdit().then(() => {
			setEditObj(obj);
			setEditing(true);
		});
		return prom;
	}

	function handleDelete(item) {
		let title = `${t("Events.THE_EVENT")} "${item.title}" ${t(
			"Events.WILL_BE_DELETE"
		)}`;
		confirmDelete(
			() => {
				deleteEvent(item);
			},
			null,
			title
		);
	}

	function deleteEvent(item) {
		if (!isAdmin()) return false;
		let animationId = item.extendedProps?.animationId || item.id;
		let req = genRequest(
			`Animations/AnimationId=${animationId}AndDeletePost=trueAndDeleteParticipation=true`,
			null,
			"delete",
			{
				reportOnFail: true,
			}
		).then(() => {
			QCL.refetchQueries(["Animations"]);
			if (item.remove) item.remove();
			setCurEvent(false);
		});
		promiseToast(req, {
			pending: t("Events.DELETING"),
		});
		return req;
	}

	function handleClone(item) {
		if (!isAdmin()) return false;
		let anim = item;
		if (item.extendedProps) anim = item.extendedProps;
		let obj = new AnimationObject({ ...anim, IsDraft: false });
		let prom = obj.fetchForClone().then(() => {
			obj.animationId(false);
			obj.isCloned = true;
			let post = obj.linkedPost();
			if (post) obj.linkedPost().postId(false);
			setEditObj(obj);
			setEditing(true);
		});
		return prom;
	}

	function handleCloseEdit() {
		setEditing(false);
		setEditObj(false);
		QCL.resetQueries(["Animations"]);
	}

	function filterEvents() {
		if (!props?.events?.length) return [];
		let ret = props.events.filter((a) => {
			let item = { ...a };
			if (item.DateStart === item.DateEnd)
				item.DateEnd = formatDate(
					dateAdd(item.DateStart, { minutes: 1 })
				);
			if ((!ShowDrafts && !item.IsDraft) || (ShowDrafts && item.IsDraft))
				return item;
			return false;
		});
		return ret;
	}

	return (
		<div className="AnimationsPage position-relative d-flex flex-column overflow-hidden h-100">
			{!editObj ? (
				<>
					<PageHeadBar
						typeId={8}
						siteSelect={false}
						search={false}
						handleAdd={props.isAdmin ? () => handleAdd() : false}
						addTitle={t("Events.ADD_TITLE")}
						title={props.title}
						setIsMenuOpen={setIsMenuOpen}
						isMenuOpen={isMenuOpen}
						categories={false}
						headButtons={[
							{
								title: t("Commons.DRAFTS"),
								classes: ShowDrafts ? "btn-primary" : "",
								enabled: props.isAdmin,
								onClick: () => setShowDrafts(!ShowDrafts),
							},
						]}
					/>
					{!ShowDrafts ? (
						<div className="overflow-auto h-100 d-flex flex-column">
							<Calendar
								handleDateClick={handleDateClick}
								handleEventClick={handleEventClick}
								handleAdd={handleAdd}
								handleClone={handleClone}
								handleDelete={handleDelete}
								handleModify={handleModify}
								events={filterEvents()}
							/>
							{curDay && (
								<EventsList
									{...curDay}
									onItemClick={handleEventClick}
								/>
							)}
						</div>
					) : (
						<AnimationsDraftsTable
							events={filterEvents()}
							onItemClick={handleDraftClick}
						/>
					)}
					{curEvent && (
						<PreviewDrawer
							slideRight={true}
							key={curEvent.id}
							isOpen={true}
							onClose={handleClosePreview}
							child={AnimationPreview}
							childProps={{
								event: curEvent,
								responsive: false,
								handleModify,
								handleClone,
								handleDelete,
							}}
							backBtnText={t("Commons.CLOSE")}
						/>
					)}
				</>
			) : (
				<>
					{editObj && (
						<AnimationEditDrawer
							onClose={handleCloseEdit}
							animation={editObj}
							isOpen={editing && editObj}
							account={account}
							initQueries={[
								{
									queryKey: [
										"AnimationEdit",
										"FetchForEdit",
										Date.now(),
									],
									queryFn: () => editObj.fetchForEdit(),
									retry: 0,
									suspense: true,
									enabled:
										editObj.isCloned ||
										!editObj.animationId()
											? false
											: true,
								},
							]}
						/>
					)}
				</>
			)}
		</div>
	);
}
