import React, {useState} from "react";
import {useQuery} from "react-query";
import SendingCont from "components/EditDrawer/SendingCont/SendingCont";
import genRequest from "includes/request";

export default function FilledFormSendStep(props) {
	const [isSended, setIsSended] = useState(false);
	const form = props.object;

	React.useEffect(() => {
		if (props.isActive && !props.isDone && isSended === true )
		{
			setTimeout(()=> {
				props.instance.nextStep();
				props.setIsDone(true);
			}, 1000);
		}
	}, [props, isSended]);

	if (!props.isActive)
		return (false);

	return (
		<div className="sending-cont">
			<SendAnswers form={form} isSended={isSended} setIsSended={setIsSended}/>
		</div>
	);
}

function SendAnswers({form, isSended, setIsSended}) {
	const [isSending, setIsSending] = useState(false);

	function getAnswers() {
		let answers = [];

		for (let sec of form.Sections)
			if (sec.Questions?.length)
				for (let quest of sec.Questions)
					if (quest.Answers?.length)
						for (let ans of quest.Answers)
							answers.push(ans.values());
		return (answers);
	}

	function send() {
		let answers = getAnswers();

		if (!answers?.length)
			return (Promise.reject(false));
		setIsSending(true);
		let prom = genRequest(
			"FormAnswer/AddMultipleAnswer",
			answers,
			"post"
		)
		return (prom);
	}

	const req = useQuery(
		["SendAnswer", "Form", form.FormId, form.LastChanged],
		send,
		{
			enabled: !isSended && !isSending,
			staleTime: 0,
			cacheTime: 0,
			retry: 3,
			onSuccess: () => {
				setIsSended(true);
				setIsSending(false);
			}
		}
	);

	return (
		<SendingCont title={"Envoi des réponses"} {...req} isSended={isSended}/>
	);
}
