import React, { useState } from "react";
import Document from "classes/Documents/DocumentModel";
import DocPreview from "Pages/Documents/components/DocPreview/DocPreview";
import { t } from "i18next";
import "./PostDocumentsDrawer.css";
import BackButton from "components/Button/BackButton";
import AsyncPostDocumentsDrawer from "./AsyncPostDocumentsDrawer";

export default function PostDocumentsDrawer(props) {
	const {documents, postId, async, onClose, isDocsExtended} = props;
	const [CurDoc, setCurDoc] = useState(false);

	if (!documents?.length)
	{
		if (postId && async !== false)
			return (
				<AsyncPostDocumentsDrawer
					{...props}
				/>
			);
		return (false);
	}

	return (
		<div className="DocumentsDrawer">
			{
				isDocsExtended &&
					<div className={"post-linked-docs-cont" + (isDocsExtended ? " open" : "")}>
						<div className="d-flex mb-3 align-items-center">
							<BackButton className="me-3" title={t("Posts.CLOSE_DOCS")} onClick={onClose}/>
							<h4 className="font-bold m-0 p-0 mt-1">Documents</h4>
						</div>
						<div className={"post-linked-docs-inner-cont"}>

							{
								documents?.length ?
									documents.map((doc) => (
										<Document
											className={`fixed-tile mx-0 ${!doc?.DocumentId ? "disabled" : ""}`}
											datas={doc}
											key={doc.DocumentId}
											onClick={() => setCurDoc(doc?.DocumentId ? doc : false)}
										/>
									))
									:
									false
							}
						</div>
					</div>
			}
			{
				(CurDoc ? <DocPreview id="doc-preview" className="doc-preview" onClose={() => setCurDoc(false)} document={CurDoc} documentId={CurDoc.DocumentId}/> : false)
			}
		</div>
	);
}
