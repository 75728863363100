import React, { useCallback, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import genRequest from "includes/request";
import FormInput from "components/Inputs/FormInput";
import Button from "components/Button/Button";
import LoadCont from "components/LoadCont/LoadCont";

export default function ForgetCont(props) {
	const [mailFailed, setMailFailed] = useState(false);

	return (
		<div className="form-cont">
			{
				mailFailed ?
					<InfosCont {...props} mailFailed={mailFailed} onCancel={()=> setMailFailed(false)}/>
					:
					<MailCont {...props} onError={setMailFailed} />
			}
		</div>
	);
}

function MailCont(props) {
	const [isSending, setIsSending] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [error, setError] = useState(false);
	const [Mail, setMail] = useState(props.mail);
	const [isInitied, setIsInitied] = useState(false);
	const [AskAccount, setAskAccount] = useState(props.mail ? true : false);
	const handleSubmit = useCallback((e) => {
		if (e)
			e.preventDefault();

		if (!Mail?.length)
		{
			setError(<div>{t("Login.ERROR_3")}</div>);
			return (false);
		}
		if (isSending)
			return (false);
		setIsSending(true);
		let prom = genRequest(
			"Employes/AnonymousEmployeWithMail",
			{ mail: Mail }
		).then(() => {
			setIsSending(false);
			setIsDone(true);
		}).catch(() => {
			setIsSending(false);
			if (props.onError)
				props.onError(Mail);
		});
		return (prom);
	}, [Mail, isSending, props]);

	function confirmAccount() {
		setAskAccount(false);
		if (Mail)
			handleSubmit();
	}

	function changeAccount() {
		setAskAccount(false);
		setMail(false);
		if (props.onAccountChange)
			props.onAccountChange();
	}

	React.useEffect(() => {
		if (isInitied)
			return (false);
		setIsInitied(Mail ? "fromProps" : true);
	}, [isInitied, Mail, handleSubmit, setIsInitied]);

	if (isSending)
		return (<LoadCont/>);

	if (isDone)
		return (
			<div className='text-center'>
				<div className="mb-2">{t("Login.SEND_OK")}<br/>{t("Login.SEND_OK2")}</div>
				<Button onClick={props.onCancel} className='btn-conn'>{t("Login.BACK_HOME")}</Button>
			</div>
		);

	if (AskAccount)
		return (
			<div>
				{!props.showMailField &&
					<div className="w-100 text-center mb-4">
						<b>{t("Login.FORGET_ASK_NEW_MSG")}</b>
					</div>
				}
				{
					props.showMailField &&
					<>
						<div className="mb-2">{t("Login.MAIL_MSG")}</div>
						<div className="inputs-wrapper mb-2">
							<label className={"d-flex align-items-center login-input"}>
								<FontAwesomeIcon icon={faUser}/>
								<FormInput className="ms-1 w-100" placeholder={t("Login.MAIL")} value={Mail} onChange={setMail}/>
							</label>
						</div>
					</>
				}
				<Button onClick={confirmAccount} className="btn-green mb-1">{t("Login.FORGET_THIS_ACCOUNT_BTN")}</Button>
				{ !props.showMailField && <Button onClick={changeAccount} className="btn-primary">{t("Login.FORGET_CHANGE_ACCOUNT_BTN")}</Button>}
				<Button onClick={props.onCancel} className="btn-grey mt-1">{t("Login.BACK_HOME")}</Button>
			</div>
		);

	return (
		<div>
			<div className="mb-2">{t("Login.MAIL_MSG")}</div>
			<div className="mb-3 text-center errors-cont">
				{
					error &&
						<div>{error}</div>
				}
			</div>
			<form onSubmit={handleSubmit}>
				<div className="inputs-wrapper">
					<label className={"d-flex align-items-center login-input"}>
						<FontAwesomeIcon icon={faUser}/>
						<FormInput className="ms-1 w-100" placeholder={t("Login.MAIL")} value={Mail} onChange={setMail}/>
					</label>
				</div>
				
				<div className="d-flex align-items-center mt-3">
					<div>
						<Button href="/#" type="button" className={"unstyled me-2 back-btn"} onClick={props.onCancel}>
							<FontAwesomeIcon className={"arrow-icon"} icon={faArrowLeft}/>
						</Button>
					</div>
					<div className="w-100 d-flex gap-2">
						<Button onClick={() => props.onError(true)}>Identifiant oublié ?</Button>
						<Button className="btn btn-conn btn-green" type="submit">{isSending ? t("Commons.SENDING") : t("Commons.CONFIRM")}</Button>
					</div>
				</div>
			</form>
		</div>
	);
}

function InfosCont(props) {
	const [isSending, setIsSending] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [error, setError] = useState(false);
	const [LastName, setLastName] = useState(false);
	const [FirstName, setFirstName] = useState(false);
	const [Login, setLogin] = useState(false);
	const [CompName, setCompName] = useState(false);
	const formRef = useRef();

	function checkFields() {
		let errors = [];

		if (!LastName)
			errors.push(<div key="error-1">{t("Login.ERROR_1")}</div>);
		if (!FirstName)
			errors.push(<div key="error-2">{t("Login.ERROR_2")}</div>);
		if (!Login)
			errors.push(<div key="error-3">{t("Login.ERROR_3")}</div>);
		if (!CompName)
			errors.push(<div key="error-4">{t("Login.ERROR_4")}</div>);
		if (errors.length)
		{
			setError(errors);
			return (false);
		}
		return ({
			lastName: LastName,
			firstName: FirstName,
			login: Login,
			comp_name: CompName,
		});
	}

	function handleSubmit(e) {
		e.preventDefault();

		let values = checkFields();

		if (!values || isSending)
			return (false);

		setIsSending(true);
		let mail = {
			"Email": "yvidal@joonsoft.fr",
			"CcEmail": "",
			"BccEmail": "",
			"MailSubjectOverride": "Un User Only All souhaite réinitialiser son mot de passe !",
			"MailBodyOverride": "",
			"FirstName": values.firstName,
			"LastName": values.lastName,
			"Login": values.login,
			"Password": "",
			"CompanyName": values.comp_name,
			"DocName": "",
			"DocPath": "",
			"TotalNewNews": 0,
			"TotalNewAd": 0,
			"TotalNewCarrer": 0
		};

		let prom = genRequest(
			"Account/CreateResetPswdRequestMailQueueFile",
			mail
		).then(() => {
			setIsSending(false);
			setIsDone(true);
		}).catch(() => {
			setIsSending(false);
			setIsDone(false);
			setError(
				<div>
					<div className="mb-2">
						{t("Login.SERV_ERROR_1")}<br/>
						{t("Login.SERV_ERROR_2")}<br/>
						{t("Login.SERV_ERROR_3")}
					</div>
					<a className='btn btn-conn' href='/'>{t("Login.BACK_HOME")}</a>
				</div>
			);
		});

		return (prom);
	}

	if (isDone)
		return (
			<div className='text-center'>
				<div className="mb-2">{t("Login.SEND_OK")}<br/>{t("Login.SEND_OK2")}</div>
				<a className='btn btn-conn' href='/'>{t("Login.BACK_HOME")}</a>
			</div>
		);

	return (
		<div>
			<div className="mb-3 text-center errors-cont">
				{
					!error && props.mailFailed?.length ?
						<div>{t("Login.MAIL_ERROR_1")}<br/><b>{props.mailFailed}</b><br/>{t("Login.FILL_FIELDS")}</div>
						:
						error
				}
			</div>
			<form onSubmit={handleSubmit} ref={formRef}>
				<div className="infos-cont inputs-wrapper">
					<div className="login-input mb-1">
						<div>{t("User.LAST_NAME")}:</div>
						<label className={"inputCont w-100 mailCont"}>
							<FormInput tabIndex="1" value={LastName} onChange={setLastName} field="last-name" placeholder={t("Login.NAME_PH")}/>
						</label>
					</div>
					<div className="login-input mb-1">
						<div>{t("User.FIRST_NAME")}:</div>
						<label className={"inputCont w-100 mailCont"}>
							<FormInput tabIndex="2" value={FirstName} onChange={setFirstName} field="first-name" placeholder={t("Login.FNAME_PH")}/>
						</label>
					</div>
					<div className="login-input mb-1">
						<div>{t("User.LOGIN")}:</div>
						<label className={"inputCont w-100 mailCont"}>
							<FormInput tabIndex="3" value={Login} onChange={setLogin} field="login" placeholder={t("Login.RESET_LOGIN_PH")}/>
						</label>
					</div>
					<div className="login-input mb-1">
						<div>{t("User.COMPANY_NETWORK")}</div>
						<label className={"inputCont w-100 mailCont"}>
							<FormInput tabIndex="4" value={CompName} onChange={setCompName} field="comp-name" placeholder={t("Login.COMP_PH")}/>
						</label>
					</div>
				</div>
				<div className="d-flex align-items-center mt-3">
					<div>
						<Button href="/#" type="button" className={"unstyled me-2 back-btn"} onClick={props.onCancel}>
							<FontAwesomeIcon className={"arrow-icon"} icon={faArrowLeft}/>
						</Button>
					</div>
					<button className="btn btn-conn" type="submit">{isSending ? t("Commons.SENDING") : t("Commons.CONFIRM")}</button>
				</div>
			</form>
		</div>
	);
}
