import React, { useState } from "react";
import formatDate, { dateAdd } from "includes/formatDate";
import useTheme from "hooks/useTheme";
import { useQuery } from "react-query";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import useToken from "classes/Accounts/hooks/useToken";
import StatsTopBar from "../StatsTopBar/StatsTopBar";
import StatsChartCanvas from "./StatsChartCanvas";
import PageLoad from "components/PageLoad/PageLoad";
import LoadCont from "components/LoadCont/LoadCont";
import Button from "components/Button/Button";
import noAccent from "includes/no_accents";
import { t } from "i18next";
import NumberChart from "components/Charts/Number/NumberChart";

const stats_url = "https://stats.onlyall.fr";

export default function StatsChart(props) {

	const { account } = useAccount();
	const { token } = useToken();

	const [graphDisp, setGraphDisp] = useState(false);
	const { theme } = useTheme();

	const date = new Date();
	const [Dates, setDates] = useState({
		start: dateAdd(date, { months: -1 }),
		end: dateAdd(date, { days: -1 })
	});

	function fetchStats() {
		if (!props?.company?.Name)
			return (Promise.reject(new Error("Bad Company Name provided")));
		let encoded_name = noAccent(props.company.Name).replace(/\s/gi, "_");
		let datas = {
			CompanyName: encoded_name,
			StartDate: formatDate(Dates.start, "Y/M/D"),
			EndDate: formatDate(Dates.end, "Y/M/D"),
			token: token
		};
		let params = new URLSearchParams(datas).toString();

		let req = genRequest(
			stats_url + "/?" + params,
			null,
			"get",
			{
				forceUrl: true
			}
		);
		return (req);
	}

	const { isLoading, data } = useQuery(
		["Stats", account.CompanyId, Dates.start, Dates.end],
		fetchStats
	);

	const stats = data;
	const Weeks = data?.Weeks ? data.Weeks : [];

	return (
		<div className="StatsChart">
			<StatsTopBar company={props?.company} dates={{ ...Dates }} setDates={setDates} />
			<div className="mt-2">
				<div className="mb-4">
					{
						isLoading ?
							<PageLoad className="radius-2" style={{ height: "50vh" }} />
							:
							<StatsChartCanvas
								weeks={Weeks}
								value={graphDisp}
								setDates={setDates}
								startDate={formatDate(Dates.start, "Y/M/D")}
								theme={theme}
								endDate={formatDate(Dates.end, "Y/M/D")}
							/>
					}
				</div>
				<div className="row mb-4">
					<CountButton
						isActive={graphDisp === "FirstConnexions"}
						onClick={() => setGraphDisp((graphDisp !== "FirstConnexions" ? "FirstConnexions" : false))}
						className={"mb-2"} title={t("Analytics.FIRST_CONN")} isLoading={isLoading} value={stats?.FirstConn} color={"#B52653"} />
					<CountButton
						isActive={graphDisp === "TotalConnexions"}
						onClick={() => setGraphDisp((graphDisp !== "TotalConnexions" ? "TotalConnexions" : false))}
						className={"mb-2"} title={t("Analytics.TOTAL_CONN")} isLoading={isLoading} value={stats?.TotalConn} color={"#1B4378"} />
					<CountButton
						isActive={graphDisp === "PostsOfTheDay"}
						onClick={() => setGraphDisp((graphDisp !== "PostsOfTheDay" ? "PostsOfTheDay" : false))}
						className={"mb-2"} title={t("Analytics.NEW_POSTS")} isLoading={isLoading} value={stats?.NbrNewPosts} color={"#DE742D"} />
				</div>

			</div>
		</div>
	);
}

function CountButton({ title, color, value, className, isLoading, isActive, onClick }) {
	return (
		<div className={"CountButton col-6 col-md-4 flex-fill text-center" + (className ? " " + className : "") + (isActive ? " active" : "")}>
			<div>{title}</div>
			{
				<Button onClick={onClick} className="btn text-center" style={isActive ? { backgroundColor: color } : {}}>
					{
						isLoading ?
							<LoadCont />
							:
							<NumberChart value={value} color={color} />
					}
				</Button>
			}
		</div>
	);
}
