import { Cards } from "components-v2/Cards/cards.component";
import { useAccountContext } from "providers/AccountProvider";
import { useEffect, useState } from "react";
import { RssService } from "service/rss.service";
import "./rss.styles.css";
import PageLoad from "components/PageLoad/PageLoad";
import { t } from "i18next";
import Button from "components/Button/Button";
import PostObject from "classes/Posts/PostObject";
import formatDate from "includes/formatDate";
import { useRssContext } from "providers/RssProvider";

export const RssPostsPage = ({ handleAddingNewPost }) => {
	const [rssPosts, setRssPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [previewPost, setPreviewPost] = useState(null);
	const { accountObj } = useAccountContext();
	const { rssFilters } = useRssContext();
	// const { isAdmin, isSup } = useAccount();

	const getPostsRssFeeds = async (CompanyId) => {
		setIsLoading(true);
		const data = await RssService.getAllPostsRssFeeds(CompanyId);
		setRssPosts(data);
		setIsLoading(false);
	};

	const getAllPostsRssFeedsByUrl = async () => {
		setIsLoading(true);
		const data = await RssService.getAllPostsRssFeedsByUrl(rssFilters[0]);
		setRssPosts(data);
		setIsLoading(false);
	}

	useEffect(() => {
		if (rssFilters.length > 0 && !isLoading) {
			setPreviewPost(null);
			getAllPostsRssFeedsByUrl();
		} else if (rssFilters.length === 0) {
			const { CompanyId } = accountObj.account;
			setPreviewPost(null);
			getPostsRssFeeds(CompanyId);
		}
	}, [rssFilters]);


	useEffect(() => {
		const { CompanyId } = accountObj.account;
		getPostsRssFeeds(CompanyId);
	}, []);

	const getInspiredByRss = async (item) => {
		const { account } = accountObj;
		let new_post = new PostObject({
			EmployesId: account.EmployesId,
			CompanyId: account.CompanyId,
			SiteId: account.SiteId,
			PublishedDate: formatDate(),
			LastChanged: formatDate(),
			PriorityTo: null,
			PostTypeId: 1,
			Adress: window.location?.origin,
			ValidationModeration: account.ByPass_Moderation,
			account: account,
			Title: item.Title,
			Txt: `${item.Txt} <br/><br/><br/><br/><br/><br/><br/><br/>  ${item.ArticleLink}`,
		});
		new_post.rssImages(item.RssThumbmail ? [item.RssThumbmail] : []);
		handleAddingNewPost(new_post);
	};
	return (
		<>
			{isLoading ? (
				<PageLoad background={false} text={"Loading Rss Posts..."} />
			) : (
				<div className="mb-4 d-flex">
					<Cards items={rssPosts} setPreviewPost={setPreviewPost} />
					{previewPost && (
						<div className="post-preview-cont rss-preview PostContent ">
							<div className="post-preview">
								{previewPost.RssThumbmail && (
									<div
										className="post-image"
										style={{
											backgroundImage: `url(${previewPost.RssThumbmail})`,
										}}
									></div>
								)}
								<div className="d-flex EditButtons gap-2 flex-wrap">
									<Button
										className="Button btn btn btn-blue"
										onClick={() =>
											getInspiredByRss(previewPost)
										}
									>
										{t("Rss.INSPIRED_BY_RSS")}
									</Button>
								</div>

								<div className="post-content">
									<div className="post-title font-bold">
										{previewPost.Title}
									</div>
									<div className="post-text">
										{previewPost.Txt}
									</div>
								</div>
								<div className="post-bottom-bar rss-link-article">
									<div className="">
										<a target="_blank" href={previewPost.ArticleLink}>{t('Rss.FULL_POST')}</a>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};
