import useAccount from "classes/Accounts/hooks/useAccount";
import { motion } from "framer-motion";
import { getMenuItem } from "components/Menu/MenuItems";
import UserImage from "components/User/Image/UserImage";
import React from "react";
import { Link } from "react-router-dom";
import "./MobileNavBar.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PlateformChanger from "components/PlateformChanger/PlateformChanger";
import CompanyChanger from "components/CompanyChanger/CompanyChanger";
import MenuToggler from "components/MenuToggler/MenuToggler";
import { PICTOS_URL } from "includes/paths";
import useTemplate from "hooks/useTemplate";

export default function MobileNavBar(props) {
	const myonlyall = getMenuItem("myonlyall");
	const { isAdmin, isSup } = useAccount();

	const slideUp = {
		hidden: {
			bottom: "-100%",
			opacity: 1,
		},
		show: {
			bottom: "0%",
			opacity: 1,
			transition: {
				ease: "easeOut",
				duration: 0.5,
			}
		}
	};

	return (
		<motion.div
			variants={slideUp}
			initial="hidden"
			animate="show"
			className="MobileNavBar"
		>
			<Slider
				dots={true}
				appendDots={DotsNav}
				arrows={false}
				autoplay={false}
				pauseOnHover={false}
				speed={500}
				infinite={true}
				usageStatistics={false}
				initialSlide={isSup("EloAdmin") >= 0 ? 1 : 0}
			>
				{isSup("EloAdmin") >= 0 && <Page3 {...props} myonlyall={myonlyall} />}
				<Page1 {...props} myonlyall={myonlyall} />
				{isAdmin() && <Page2 {...props} myonlyall={myonlyall} />}
			</Slider>
		</motion.div>
	);
}

function DotsNav(dots) {
	return (
		<div>
			<div className="DotsNav">
				<ul className="m-0 p-0">
					{dots}
				</ul>
			</div>
		</div>
	);
}

function Page1(props) {
	const { curPage, myonlyall } = props;

	return (
		<div className="NavBarPage">
			<MenuButton {...props} />
			<NavButton {...props} isActive={curPage === "/news"} item={getMenuItem("news")} />
			<NavButton {...props} isActive={curPage === "/corporate"} item={getMenuItem("corporate")} />
			<NavButton {...props} isActive={curPage === "/peoples"} item={getMenuItem("peoples")} />
			<NavButton {...props} isActive={curPage === "/contacts"} item={getMenuItem("contacts")} />
			<NavButton {...props} isActive={curPage === "/myonlyall"} item={myonlyall} child={<ProfileButton page={myonlyall} />} />
		</div>
	);
}

function MenuButton(props) {
	return (
		<NavButton
			{...props}
			item={{
				title: "Menu",
				name: "menu",
				url: "#",
				icon: PICTOS_URL + "House_Picto.png",
			}}
			callback={() => props.setIsMenuOpen(true)}
			iconComponent={() => <MenuToggler />}
		/>
	);
}

function Page2(props) {
	const { curPage, myonlyall } = props;

	return (
		<div className="NavBarPage">
			<NavButton {...props} isActive={curPage === "/analytics"} item={getMenuItem("analytics")} />
			<NavButton {...props} isActive={curPage === "/config"} item={getMenuItem("config")} />

			{document.body.clientWidth > 700 && <PlateformChanger platform={props.platform} setPlatform={props.setPlatform} />}
			<NavButton {...props} isActive={curPage === "/myonlyall"} item={myonlyall} child={<ProfileButton page={myonlyall} />} />
		</div>
	);
}

function Page3(props) {
	const { curPage, myonlyall } = props;
	const { isSup } = useAccount();

	if (isSup("EloAdmin") < 0)
		return (false);

	return (
		<div className="NavBarPage justify-content-between">
			<NavButton {...props} isActive={curPage === "/devs"} item={getMenuItem("devs")} />
			<CompanyChanger className="w-75 ms-1 mb-1" menuPlacement={"top"} resetBtn={false} />
			<NavButton {...props} isActive={curPage === "/myonlyall"} item={myonlyall} child={<ProfileButton page={myonlyall} />} />
		</div>
	);
}

function ProfileButton() {
	const { account } = useAccount();

	return (
		<div className="d-flex align-items-center flex-column">
			<UserImage className="mb-1" width={"32px"} res={"Url_Min"} employeId={account.EmployesId} />
		</div>
	);
}

function NavButton(props) {
	const { isSup } = useAccount();
	const { template } = useTemplate();
	const { isActive, item, callback, notifs } = props;
	const { title, icon, cName, url } = item;

	function handleClick() {
		callback(item);
	}

	const getEnabledChilds = (items) => {
		let res = items.filter((a) => isEnabled(a));
		return (res?.length ? res : false);
	};

	const isEnabled = (page_item) => {
		if ((page_item.platform && page_item.platform !== props.platform))
			return (false);
		if (page_item.appFunction && template.getAppFunction) {
			let func = template.getAppFunction(page_item.appFunction);
			if (!func && props.platform !== "admin")
				return (false);
		}
		return (true);
	};

	if (!item || (item.role && isSup(item.role) < 0))
		return (false);

	if (item?.items) {
		let childs = getEnabledChilds(item.items);
		if (childs?.length === 1)
			return (<NavButton {...props} item={childs[0]} isActive={props.curPage === childs[0].url} />);
	}

	return (
		<div className={"NavButton classic" + (isActive ? " active" : "") + (props.className ? " " + props.className : "")} onClick={handleClick}>
			<Link className={`nav-link d-flex align-items-center justify-content-between ${cName}`} to={url} draggable={false} >
				{
					props.child ?
						props.child
						:
						<>
							<div className="d-flex align-items-center flex-column">
								{
									props.iconComponent ?
										<props.iconComponent />
										:
										<div className="nav-button-page-icon button-bg" style={{ "backgroundImage": `url(${icon})` }}></div>
								}
								<div className="nav-button-page-title">{title}</div>
							</div>
							<div className="d-flex align-items-center">
								{(notifs?.length > 0) && <div className="notif-count">{notifs?.length}</div>}
							</div>
						</>
				}
			</Link>
		</div>
	);
}
