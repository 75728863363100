import useEditing, { CloseEditToast } from "components/EditDrawer/useEditing";
import React, {	useCallback, useEffect,	useState } from "react";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./RouterPrompt.css";
import { t } from "i18next";
import useCurPage from "hooks/useCurPage";

export function RouterPrompt(props) {
	const {
		when,
		onOK,
		onCancel,
		onDraft,
		title,
		okText,
		cancelText
	} = props;
	const { setEditing } = useEditing();
	const history = useHistory();
	const { setCurPage } = useCurPage();
	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState("");

	useEffect(() => {
		if (when) {
			history.block((prompt) => {
				setCurrentPath(prompt.pathname);
				setShowPrompt(true);
				return "true";
			});
		} else {
			history.block(() => {});
		}

		return () => {
			history.block(() => {});
		};
	}, [history, when]);

	const handleOK = useCallback(async () => {
		if (onOK) {
			const canRoute = await Promise.resolve(onOK());
			if (canRoute) {
				history.block(() => {});
				setCurPage(currentPath, true);
				setEditing(false);
				history.push(currentPath);
			}
		}
	}, [currentPath, history, onOK, setCurPage, setEditing]);

	const handleCancel = useCallback(async () => {
		if (onCancel) {
			const canRoute = await Promise.resolve(onCancel());
			if (canRoute) {
				history.block(() => {});
				history.push(currentPath);
			}
		}
		setShowPrompt(false);
	}, [currentPath, history, onCancel]);

	const handleDraft = useCallback(async () => {
		if (onDraft) {
			const canRoute = await Promise.resolve(onDraft());
			if (canRoute) {
				history.block(() => {});
				history.push(currentPath);
			}
		}
		setShowPrompt(false);
	}, [setShowPrompt, currentPath, history, onDraft]);

	return (
		showPrompt ?
			<div className="RouterPrompt">
				<CloseEditToast
					title={title}
					visible={showPrompt}
					onOk={handleOK}
					okText={okText}
					onDraft={onDraft ? handleDraft : false}
					onCancel={handleCancel}
					cancelText={cancelText}
				>
					<FontAwesomeIcon icon={faExclamationTriangle} style={{fontSize: "2rem"}} className="mb-2" color={"orange"}/>
					{ t("Editing.UNSAVED_WARN") }
				</CloseEditToast>
			</div>
			:
			null
	);
}
