import React from "react";
import { useQueryClient } from "react-query";
import { t } from "i18next";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import ButtonsStyle from "./components/ButtonsStyle";
import DevFunctions from "./components/DevFunctions";
import "./Settings.css";
import AppSettings from "./components/AppSettings";
// import FontSizeSetting from "./components/FontSize";
import useAccount from "classes/Accounts/hooks/useAccount";
import useTemplate from "hooks/useTemplate";
import LanguageSwitcher from "i18n/LanguageSwitcher/LanguageSwitcher";
import DefaultLinkType from "./components/DefaultLinkType";
import PrimaryColor from "./components/PrimaryColor";

export default function SettingsPage(props) {
	const { isAdmin } = useAccount();
	const { getAppFunction } = useTemplate();

	const Settings = [
		{
			title: t("Settings.LANGUAGE"),
			component: () => (
				<LanguageSwitcher handleChange={props.setLangCode} />
			),
		},
		{
			title: t("Settings.BUTTONS_STYLE"),
			component: ButtonsStyle,
		},
		{
			title: t("Settings.CUSTOM_COLOR"),
			component: PrimaryColor,
		},
		{
			title: t("Settings.DEFAULT_LINK"),
			component: DefaultLinkType,
		},
		// {
		// 	title: "Font size",
		// 	component: FontSizeSetting,
		// 	role: "EloAdmin",
		// },
		{
			title: t("Settings.FEATURES"),
			component: AppSettings,
			isDisabled:
				!getAppFunction("PostsPage.CanUseMarkdown") && !isAdmin(),
		},
		{
			title: "Fonctionalités des développeurs",
			component: DevFunctions,
			role: "EloAdmin",
		},
	];

	const QCL = useQueryClient();

	const refresh = (reload) => {
		if (reload) return QCL.refetchQueries(["ServerPing"]);
		QCL.refetchQueries(["Template"]);
	};

	return (
		<div className="SettingsPage">
			<PageHeadBar
				title={props.title}
				isMenuOpen={props.isMenuOpen}
				setIsMenuOpen={props.setIsMenuOpen}
				siteSelect={false}
				search={false}
			/>
			<div className="settings-list d-flex gap-2 flex-column mt-4">
				{Settings.map((a, key) => {
					return (
						<SettingSection
							key={key}
							{...a}
							handleRefresh={refresh}
						/>
					);
				})}
			</div>
		</div>
	);
}

function SettingSection(props) {
	const { isSup } = useAccount();
	const { title, role, isDisabled } = props;

	if (isDisabled || (role && isSup(role) < 0)) return false;

	return (
		<div>
			<h5 className="mb-2">{title}</h5>
			<props.component
				{...props?.props}
				handleRefresh={props.handleRefresh}
			/>
		</div>
	);
}
