import React from "react";
import Button from "components/Button/Button";
import { t } from "i18next";
import "./PostsStateSelector.css";
import useAccount from "classes/Accounts/hooks/useAccount";
import { getRightFromTypeId } from "includes/mappingTypeIdName";
import { returnRightsFromName } from "includes/rightType";
import useTemplate from "hooks/useTemplate";

export default function PostsStateSelector(props) {
	const { state, changeState, typeId, displayRss } = props;
	const { account } = useAccount();
	const { template } = useTemplate();
	let rssPerm = template.getAppFunction("PeoplePage.Rss");

	const checkRight = () => {
		const rigthName = getRightFromTypeId(typeId);
		try {
			return returnRightsFromName(account.Rights, rigthName);
		} catch (error) {
			return true;
		}
	};

	const onChangeState = (state, rssValue) => {
		displayRss(rssValue);
		changeState(state);
	};

	return (
		<div className="sel-posts-state-menu d-flex mb-2 gap-2">
			{typeId === 1 && rssPerm && (
				<Button
					className={
						"sel-state-btn" + (state === "rss" ? " active" : "")
					}
					onClick={() => onChangeState("rss", true)}
				>
					{t("Rss.RSS_INSPIRATION")}
				</Button>
			)}
			{checkRight() && (
				<Button
					className={
						"sel-state-btn" + (state === "waiting" ? " active" : "")
					}
					onClick={() => onChangeState("waiting", false)}
				>
					{t("Posts.WAITING_BTN")}
				</Button>
			)}
			{checkRight() && (
				<Button
					className={
						"sel-state-btn" +
						(state === "moderated" ? " active" : "")
					}
					onClick={() => onChangeState("moderated", false)}
				>
					{t("Posts.WAITING_MODIF_BTN")}
				</Button>
			)}
			<Button
				className={
					"sel-state-btn" + (state === "published" ? " active" : "")
				}
				onClick={() => onChangeState("published", false)}
			>
				{t("Posts.PUBLISHED_BTN")}
			</Button>
			{/* <div className={"sel-state-btn" + (state === "drafts" ? " active" : "")} onClick={() => changeState("drafts")}>{t("Posts.DRAFT_BTN")}</div> */}
		</div>
	);
}
