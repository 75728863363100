import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import { useQuery } from "react-query";
import genRequest from "includes/request";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import "./FormTiles.css";
import formatDate from "includes/formatDate";
import { t } from "i18next";

export default function FormsTiles({ onClick, showDrafts }) {
	const { account, isAdmin } = useAccount();
	const { isLoading, data } = useQuery(
		["Forms", "FormAffectedToCompany", account.CompanyId],
		() => genRequest("Forms/FormAffectedToCompany/" + account.CompanyId)
	);

	if (isLoading)
		return (
			<div className="FormTile tile loading">
				<div className="w-100"></div>
				<div className="w-75"></div>
				<div className="w-50"></div>
			</div>
		);

	if (!data?.length) return <div>Aucun formulaire</div>;

	let date = formatDate();

	return (
		<div className="tiles-cont">
			{data.map((a) => {
				if (showDrafts !== a.IsDraft) return false;
				if (
					((!a.AvailableToDate || a.AvailableToDate >= date) &&
						a.PublishedDate <= date) ||
					isAdmin()
				)
					return (
						<FormTile
							onClick={() => onClick(a)}
							key={a.FormId}
							form={a}
						/>
					);
				return false;
			})}
		</div>
	);
}

export function FormTile({ form, onClick, className }) {
	if (!form) return false;

	return (
		<div
			className={"FormTile tile" + (className ? " " + className : "")}
			onClick={onClick}
		>
			<div>
				<div className="form-title font-bold">{form.Title}</div>
				<div className="form-text">{form.Txt}</div>
			</div>
			<div className="form-infos">
				<InfosFetcher
					queryKey={["FormQuestions", "QuestionOfForm", form.FormId]}
					queryFn={() =>
						genRequest(
							"FormQuestions/QuestionOfForm/" + form.FormId
						)
					}
					treat={(infos) => {
						return t("Forms.QUESTIONS", { count: infos.length });
					}}
				/>
			</div>
		</div>
	);
}
