import Button from "components/Button/Button";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import FormInput from "components/Inputs/FormInput";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import generateUUID from "includes/UUID";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import StepTarget from "./StepTarget";

export default function TourStepEditor(props) {
	const [Errors, setErrors] = useState(false);
	const [Step, setStep] = useState({
		title: "",
		content: "",
		target: "",
		disableBeacon: true,
		page: window.location.pathname,
		stepId: generateUUID()
	});

	const handleSave = (e) => {
		if (!Step.title?.length)
			return (setErrors("Veuillez entrer un titre pour l'étape"))
		if (!Step.target?.length)
			return (setErrors("Veuillez sélectionner une cible pour l'étape"))
		Step.target = Step.target.replace(".tour-editor-highlight", "")
		props.saveEvent(Step, e)
	}

	return (
		<div
			className="TourStepPopper d-flex flex-column gap-2"
		>
			<FormInput title={"Titre"} value={Step.title} onChange={(val) => Step.title = val}/>
			<FormInput
				title="Description"
				textarea="true"
				value={Step.content} onChange={(val) => Step.content = val}
			/>
			<FormInput title="Page" value={Step.page} onChange={(val) => {
				Step.page = val
				setStep({...Step})
			}} externvalue={"true"}/>
			<SwitchInput
				label={"Activer le clic sur l'élément"}
				checked={Step.spotlightClicks}
				onlyState={true}
				onChange={(val) => {
					Step.spotlightClicks = val
					setStep({...Step})
				}}
			/>
			<StepTarget step={Step} target={props.isAdding?.target} highlight={props.highlight}/>
			<ErrorsCont errors={Errors}/>
			<div className="d-flex gap-2">
				<Button className="btn-red" onClick={props.onCancel}>Annuler</Button>
				<Button className="btn-green" onClick={handleSave}>Enregistrer</Button>
			</div>
		</div>
	)
}

export function TourStepPopper(props) {

	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(props.refElem, popperElement, {
		placement: "bottom"
	});

	return (
		<div
			ref={setPopperElement}
			style={styles.popper}
			{...attributes.popper}
		>
			<TourStepEditor
				saveEvent={props.saveEvent}
				onCancel={props.onCancel}
				isAdding={props.isAdding}
				highlight={props.highlight}
			/>
		</div>
	)
}
