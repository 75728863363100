import React, { useState } from "react";
import "./PostDocumentsStep.css";
import DocsSelector from "./DocsSelector/DocsSelector";
import { DocsList } from "./DocsSelector/DocsSelector";
import { rm_double } from "includes/arrays";

// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import { t } from "i18next";
import Button from "components/Button/Button";
import ReactModal from "react-modal";

function PostDocumentStep(props) {
	const post = props.object;

	const getSelected = () => {
		let ret = post.documents().map(a => (
			(typeof a.typeof !== "undefined" && a.typeof() === "DocumentObject") ? a.values(true) : a)).filter((a) => a.DocumentId?.length);
		return (ret);
	};

	const getUploaded = () => {
		let ret = post.documents().map(a => (
			(typeof a.typeof !== "undefined" && a.typeof() === "DocumentObject") ? a.values(true) : a)).filter((a) => a.TempId?.length);
		return (ret);
	};

	const [selected, setSelected] = useState(getSelected());
	const [uploaded, setUploaded] = useState(getUploaded());
	const [adding, setAdding] = useState(false);

	function handleSelect(elems) {
		let dup = rm_double(elems, "DocumentId");
		setSelected(dup);
		props.refreshPreview();
	}

	function handleUpload(doc) {
		let dup = [...uploaded];
		dup.push(doc);
		dup = rm_double(dup, "TempId");
		setUploaded(dup);
		setAdding(false);
		props.refreshPreview();
	}

	function rm_uploaded(id)
	{
		let dup = uploaded;
		for (let x in dup)
			if (dup[x].TempId === id)
			{
				dup.splice(x, 1);
				return (setUploaded([...dup]));
			}
		return (false);
	}

	function DelBtn({id}) {
		return (
			<div className="del-btn me-2" title={t("Docs.DEL_DOC_TITLE")}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					rm_uploaded(id);
				}}
			>
				<FontAwesomeIcon icon={faTrashAlt} color={"red"}/>
			</div>
		);
	}

	const items = selected.concat(
		uploaded.map(a => {
			let values = (a.typeof ? a.values(true) : a);
			return ({
				...values,
				docObject: a,
				checkbox: <DelBtn key={a.TempId} id={a.TempId}/>
			});
		})
	);

	post.documents(items);

	return (
		<div className="PostDocumentsStep">
			<div className="inner-step-cont mb-3">
				<div className="font-bold">Documents</div>
				<div className="d-flex justify-content-between">
					<div>{t("Docs.LINKED_DOCS", {count: items?.length})} </div>
					<div>
						<Button
							className={"btn btn-" + (adding ? "grey" : "primary")}
							value="Ajouter"
							onClick={() => setAdding(!adding)}
						>
							{adding ? t("Commons.BACK") : t("Commons.ADD")}
						</Button>
					</div>
				</div>
				{(items.length || adding) && <hr/>}
				<DocsList
					mouseover={false}
					docs={items}
					uploaded={uploaded}
					setUploaded={setUploaded}
					selected={selected}
					setSelected={handleSelect}
					onItemClick={(elem) => {
						if (elem.docObject)
							setAdding(elem.docObject);
					}}
				/>
				{
					adding &&
						<ReactModal
							isOpen={true}
						>
							<DocsSelector
								mouseover={true}
								selected={selected}
								setSelected={handleSelect}
								handleUpload={handleUpload}
								curDoc={adding}
								handleClose={() => setAdding(false)}
							/>
						</ReactModal>
				}
			</div>
		</div>
	);
}

export default PostDocumentStep;
