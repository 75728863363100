import documents_treat from "includes/documents_treat";
import formatDate from "includes/formatDate";
import { SERV_URL } from "includes/paths";
import genRequest from "includes/request";

class AccountObject {
	#EmployesId = null;
	#FirstName = "";
	#LastName = "";
	#Sex = null;
	#HiredDate = null;
	#Workplace = null;
	#Login = null;
	#Telephone = null;
	#Mail = null;
	#SiteId = null;
	#AspNetUserId = null;
	#CompanyId = null;
	#CompanyRankId = null;
	#WorkFamillyId = null;
	#WorkFunctionId = null;
	#LastChanged = null;
	#FirstConnexion = null;
	#LastConnexion = null;
	#Hobby = null;
	#LastChange_AuthorId = null;
	#Token_Regeneration = null;
	#Display_Phone = true;
	#Display_Mail = true;
	#ByPass_Moderation = null;
	#Role = "UserPublisher";
	#OldRole = null;
	#UserTemplateId = null;
	#LanguageId = null;
	#IsVisibleInApp = null;

	#Company = false;
	#CompanyBackground = false;
	#CompanyLogo = false;

	#Settings = {};

	#Token = false;
	#Image = false;

	constructor(obj) {
		this.#Token = (obj?.Token ? obj.Token : false);
		this.#Company = (obj?.Company ? obj.Company : false);
		this.#CompanyBackground = (obj?.CompanyBackground ? obj.CompanyBackground : false);
		this.#CompanyLogo = (obj?.CompanyLogo ? obj.CompanyLogo : false);
		this.#Settings = (obj?.Settings ? obj.Settings : {});
		this.#initValues(obj);
	}

	#initValues(obj) {
		let date = formatDate();

		this.#EmployesId = (obj?.EmployesId ? obj.EmployesId : null);
		this.#FirstName = (obj?.FirstName ? obj.FirstName : "");
		this.#LastName = (obj?.LastName ? obj.LastName : "");
		this.#Sex = (obj?.Sex ? obj.Sex : "");
		this.#HiredDate = (obj?.HiredDate ? obj.HiredDate : null);
		this.#Workplace = (obj?.Workplace ? obj.Workplace : "");
		this.#Login = (obj?.Login ? obj.Login : "");
		this.#Telephone = (obj?.Telephone ? obj.Telephone : "");
		this.#Mail = (obj?.Mail ? obj.Mail : "");
		this.#SiteId = (obj?.SiteId ? obj.SiteId : null);
		this.#AspNetUserId = (obj?.AspNetUserId ? obj.AspNetUserId : null);
		this.#CompanyId = (obj?.CompanyId ? obj.CompanyId : null);
		this.#CompanyRankId = (obj?.CompanyRankId ? obj.CompanyRankId : 1);
		this.#WorkFamillyId = (obj?.WorkFamillyId ? obj.WorkFamillyId : null);
		this.#WorkFunctionId = (obj?.WorkFunctionId ? obj.WorkFunctionId : null);
		this.#LastChanged = (obj?.LastChanged ? obj.LastChanged : date);
		this.#FirstConnexion = (obj?.FirstConnexion ? obj.FirstConnexion : null);
		this.#LastConnexion = (obj?.LastConnexion ? obj.LastConnexion : null);
		this.#Hobby = (obj?.Hobby ? obj.Hobby : "");
		this.#LastChange_AuthorId = (obj?.LastChange_AuthorId ? obj.LastChange_AuthorId : null);
		this.#Token_Regeneration = (obj?.Token_Regeneration ? obj.Token_Regeneration : false);
		this.#Display_Phone = (obj?.Display_Phone ? obj.Display_Phone : true);
		this.#Display_Mail = (obj?.Display_Mail ? obj.Display_Mail : true);
		this.#ByPass_Moderation = (obj?.ByPass_Moderation ? obj.ByPass_Moderation : false);
		this.#Role = (obj?.Role ? obj.Role : "UserPublisher");
		this.#UserTemplateId = (obj?.UserTemplateId ? obj.UserTemplateId : null);
		this.#LanguageId = (obj?.LanguageId ? obj.LanguageId : 1);
		this.#IsVisibleInApp = (obj?.IsVisibleInApp ? obj.IsVisibleInApp : true);
	}

	/*
	** -- Log
	*/

	connect(username, password) {
		let login = (username || this.#Login);
		if (!this.#Token || (login && password))
			return (this.passwordConnect(login, password));
	}

	passwordConnect(username, password) {
		let that = this;

		this.#Token = false;
		let prom = genRequest(
			SERV_URL + "Token",
			new URLSearchParams({
				grant_type: "password",
				username,
				password
			}),
			"post",
			{
				forceUrl: true,
				contentType: "application/x-www-form-urlencoded",
				timeout: 15000
			}
		).then((resp) => {
			that.#Token = resp;
			return (resp);
		});
		return (prom);
	}

	/*
	** -- Fetch
	*/

	fetch() {
		let prom = new Promise((resolve, reject) => {
			this.fetchInfos()
				.then(() => {
					return (this.fetchCompany());
				}, reject)
				.then(() => resolve(this.values()));
		});
		return (prom);
	}

	fetchInfos() {
		let that = this;
		let req = genRequest("Me").then((resp) => {
			if (resp?.length)
				that.#initValues(resp[0]);
			else
				throw (new Error("Failed retrieve user"));
			return (resp);
		});
		return (req);
	}

	fetchCompany() {
		let prom = new Promise((resolve, reject) => {
			this.fetchCompanyInfos()
				.then(() => {
					return (this.fetchImages());
				}, reject)
				.then(resolve, reject);
		});
		return (prom);
	}

	fetchCompanyInfos() {
		let that = this;
		let req = genRequest("Companies/" + this.#CompanyId).then((resp) => {
			that.#Company = {
				CompanyId: resp.CompanyId,
				Name: resp.Name,
			};
		});
		return (req);
	}

	fetchImages() {
		let that = this;
		let proms = [
			this.fetchCompanyImage(10).then((resp) => {
				that.#CompanyLogo = resp;
			}),
			this.fetchCompanyImage(12).then((resp) => {
				that.#CompanyBackground = resp;
			}),
			this.fetchProfilePicture().then((resp) => {
				that.#Image = resp;
			})
		];
		return (Promise.allSettled(proms));
	}

	fetchCompanyImage(typeId) {
		if (!this.#CompanyId || !typeId)
			return (false);
		let req = genRequest("Documents/DocumentOfCompanyAndType/" + this.#CompanyId + "/" + typeId)
			.then((resp) => {
				let img = documents_treat(resp)[0];
				if (!img)
					return (false);
				return ({
					Url_Origin: img.Url_Origin,
					Url_Mobile: img.Url_Mobile,
					Url_Min: img.Url_Min,
				});
			});
		return (req);
	}

	fetchProfilePicture() {
		if (!this.#EmployesId)
			return (false);
		let req = genRequest("Documents/DocumentOfEmployeAndType/" + this.#EmployesId + "/2")
			.then((resp) => {
				let img = documents_treat(resp)[0];
				if (!img)
					return (false);
				return ({
					Url_Origin: img.Url_Origin,
					Url_Mobile: img.Url_Mobile,
					Url_Min: img.Url_Min,
				});
			});
		return (req);
	}

	isTokenValid() {
		if (!this.#Token)
			return (false);
		let expires = this.#Token[".expires"];
		if (!expires)
			return (false);
		let date = new Date();
		let exp = new Date(expires);
		return (date < exp);
	}

	/*
	** -- GET
	*/

	// defaults

	get EmployesId() { return (this.#EmployesId); }

	get FirstName() { return (this.#FirstName); }

	get LastName() { return (this.#LastName); }

	get Login() { return (this.#Login); }

	get SiteId() { return (this.#SiteId); }

	get CompanyId() { return (this.#CompanyId); }

	get FirstConnexion() { return (this.#FirstConnexion); }

	get LastConnexion() { return (this.#LastConnexion); }

	get Token_Regeneration() { return (this.#Token_Regeneration); }

	get LanguageId() { return (this.#LanguageId); }

	// customs

	get image() { return (this.#Image); }

	get CompanyBackground() { return (this.#CompanyBackground); }
	get CompanyLogo() { return (this.#CompanyLogo); }

	get Token() { return (this.#Token); }

	values(with_token) {
		let vals = {
			EmployesId: this.#EmployesId,
			FirstName: this.#FirstName,
			LastName: this.#LastName,
			Sex: this.#Sex,
			HiredDate: this.#HiredDate,
			Workplace: this.#Workplace,
			Login: this.#Login,
			Telephone: this.#Telephone,
			Mail: this.#Mail,
			SiteId: this.#SiteId,
			AspNetUserId: this.#AspNetUserId,
			CompanyId: this.#CompanyId,
			CompanyRankId: this.#CompanyRankId,
			WorkFamillyId: this.#WorkFamillyId,
			WorkFunctionId: this.#WorkFunctionId,
			LastChanged: this.#LastChanged,
			FirstConnexion: this.#FirstConnexion,
			LastConnexion: this.#LastConnexion,
			Hobby: this.#Hobby,
			LastChange_AuthorId: this.#LastChange_AuthorId,
			Token_Regeneration: this.#Token_Regeneration,
			Display_Phone: this.#Display_Phone,
			Display_Mail: this.#Display_Mail,
			ByPass_Moderation: this.#ByPass_Moderation,
			Role: this.#Role,
			UserTemplateId: this.#UserTemplateId,
			LanguageId: this.#LanguageId,
			IsVisibleInApp: this.#IsVisibleInApp,

			Image: this.#Image,
			CompanyBackground: this.#CompanyBackground,
			CompanyLogo: this.#CompanyLogo,
			Company: this.#Company,

			Settings: this.#Settings
		};

		if (with_token)
			vals.Token = this.#Token;
		return (vals);
	}
}

export default AccountObject;
