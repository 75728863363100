import React, { useEffect, useState, useRef, memo } from "react";
import Moment from "react-moment";
import { t } from "i18next";
import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import "./PostsTable.css";
import formatDate from "includes/formatDate";
import useUnread from "classes/Accounts/hooks/useUnread";
import { NewLabel } from "components/Tags/Label/TagLabel";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import TargetsCell from "./Cells/Targets";
import PostTableToolbar from "./ToolBar/PostTableToolbar";
import Table from "components/Table/Table";
import ReactionsStats from "../PostStats/components/Reactions";
import { usePopper } from "react-popper";

export default function PostsTable(props) {
	const { isAdmin, getSettings } = useAccount();
	const { isReaded, setAsReaded } = useUnread();
	const [selected, setSelected] = useState([]);
	const state_ref = useRef();

	useEffect(() => {
		if (props.state !== state_ref.current && selected?.length)
			setSelected([]);
		state_ref.current = props.state;
	}, [selected, props, setSelected]);

	const cols = [
		{
			title: t("Table.TITLE"),
			name: "Title",
			field: "Title",
			className: "post-title-cell",
			render: (data) => <TitleCell item={data} />,
		},
		{
			title: t("Table.AUTHOR"),
			name: "Author",
			field: "FullName",
			className: "post-author-cell",
			render: (data) => <AuthorCell item={data} />,
			sorting: false,
			hidden: props.typeId === 13,
		},
		{
			title: t("Posts.VIEWS"),
			name: "ViewCount",
			field: "ViewCount",
			className: "post-views-cell",
			hidden: !isAdmin(),
		},
		{
			title: t(
				"Posts." + (getSettings("Reactions") ? "REACTIONS" : "LIKES")
			),
			name: "LikesCount",
			field: "LikesCount",
			className: "post-likes-cell",
			render: (data) => <LikesCell item={data} />,
			hidden: props.typeId === 13,
		},
		{
			title: t("Posts.PUBLISHED_DATE"),
			name: "PriorityTo",
			field: "PriorityTo",
			className: "post-date-cell",
			render: (data) => <DateCell item={data} />,
		},
		{
			title: t("Commons.TARGETS"),
			name: "Targets",
			render: (data) => <TargetsCell item={data} />,
			sorting: false,
			hidden: !isAdmin(),
		},
	];

	const incrementViews = (item) => {
		let isPostReaded = isReaded(
			"NotViewedPost_" + item.PostTypeId,
			item.PostId
		);

		if (isPostReaded) return Promise.resolve(false);
		let req = genRequest(
			"Posts/IncrementPostViewCount/" + item.PostId,
			null,
			"put"
		).then(() => {
			setAsReaded("NotViewedPost_" + item.PostTypeId, item.PostId);
			return true;
		});
		return req;
	};

	function handleClick(e, item) {
		incrementViews(item).then(() => {
			if (props.handlePostClick) props.handlePostClick({ datas: item });
		});
	}

	let items = props.displayed;

	if (!items || !items.length) items = [];

	return (
		<div
			className={
				"PostsTable w-100" + (props.curPost?.PostId ? " has-cur" : "")
			}
		>
			<Table
				columns={cols}
				options={{
					selection: isAdmin(),
				}}
				data={props.items}
				rowIdKey={"PostId"}
				activeRowId={props.curPost?.PostId}
				onRowClick={handleClick}
				overloads={{
					Toolbar: (cprops, options) => (
						<PostTableToolbar {...cprops} {...options} />
					),
				}}
			/>
		</div>
	);
}

const TitleCell = ({ item }) => {
	const { isReaded } = useUnread();
	let isPostReaded = isReaded(
		"NotViewedPost_" + item.PostTypeId,
		item.PostId
	);
	let title = item.Title;

	if (item.PostTypeId === 3) {
		let split = title.split(/\/__CUT__\\/gi);
		title = (
			<>
				<div className="sub-title font-light">{split[0]}</div>
				{split[1]?.length && <div>{split[1]}</div>}
			</>
		);
	}
	return (
		<div className="title-cell font-bold">
			{!isPostReaded && <NewLabel className="me-1" />}
			{title}
		</div>
	);
};
TitleCell.displayName = "TitleCell";

const DateCell = memo(({ item }) => {
	let date = formatDate();

	return (
		<div className="d-flex align-items-center flex-wrap gap-1 justify-content-between">
			<Moment
				titleFormat="D MMM YYYY HH:mm"
				withTitle
				format={t("Events.TILE_DATE_FORMAT")}
			>
				{item.PublishedDate}
			</Moment>
			{(item.PriorityTo >= date || item.PublishedDate >= date) && (
				<div className="d-flex flex-wrap gap-1 align-items-center justify-content-center me-1 date-pictos">
					{item.PublishedDate >= date && (
						<div
							title={`Différée au ${formatDate(
								item.PublishedDate,
								t("Commons.CUSTOM_FORMAT_DATE")
							)}`}
						>
							<FontAwesomeIcon
								className="differed-picto"
								title={t("Commons.DEFERRED_PUBLISH")}
								icon={faCalendarAlt}
							/>
						</div>
					)}
					{item.PriorityTo >= date && (
						<div
							title={`Prioritaire jusqu'au ${formatDate(
								item.PriorityTo,
								t("Commons.CUSTOM_FORMAT_DATE")
							)}`}
						>
							<FontAwesomeIcon
								className="priority-picto"
								title={t("Commons.PRIORITY_PUBLISH")}
								icon={faThumbtack}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
});
DateCell.displayName = "DateCell";

const AuthorCell = memo(({ item }) => {
	return (
		<AuthorPreview
			employeId={item.EmployesId}
			className="posts-table-author-preview mb-0"
			format={["EmployeFirstName EmployeLastName"]}
			imgWidth={"20px"}
			modifiers={{
				EmployeLastName: (item) => item.toUpperCase(),
			}}
			onSettled={(resp) => {
				item.FullName =
					resp.EmployeFirstName + " " + resp.EmployeLastName;
			}}
		/>
	);
});
AuthorCell.displayName = "AuthorCell";

const LikesCell = memo(({ item }) => {
	const { getSettings } = useAccount();
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: "bottom",
	});

	const [IsOpen, setIsOpen] = useState(false);

	// temp hide reactions - show only likes
	if (!getSettings("Reactions")) return item.LikesCount;

	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
			ref={setReferenceElement}
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
		>
			<ReactionsStats
				postId={item.PostId}
				hideIfNull={true}
				onHide={() => setIsOpen(false)}
			>
				{(data) => {
					return data.totalCount || 0;
				}}
			</ReactionsStats>
			{IsOpen && (
				<div
					className="likes-popper px-2 pb-0 pt-2 radius-2 bg-color-secondary"
					ref={setPopperElement}
					onMouseLeave={() => setIsOpen(false)}
					style={styles.popper}
					{...attributes.popper}
				>
					<ReactionsStats
						postId={item.PostId}
						hideIfNull={true}
						onHide={() => setIsOpen(false)}
					/>
				</div>
			)}
		</div>
	);
});
