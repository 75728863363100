import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faUpload, faDownload, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/Button/BackButton";
import "./UsersManager.css";
import Button from "components/Button/Button";
import UsersExporter from "./components/UsersExporter/UsersExporter";
import TagsList from "components/Tags/TagsList/TagsList";
import useAccount from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";

const items = [
	{
		key: 0,
		title: "Exporter les utilisateurs",
		component: UsersExporter,
		icon: faUpload,
		props: {
			tableClassName: "dark-bg"
		},
	},
	{
		key: 1,
		title: (
			<div className="text-center">
				Importer les utilisateurs<br/>
					(prochainement)
			</div>
		),
		role: "EloAdmin",
		disabled: true,
		component: false,
		icon: faDownload
	}
];

export default function UsersManager(props) {

	const {isSup} = useAccount();
	const [active, setActive] = useState(false);
	const count = React.useRef();

	const getItems = React.useCallback(() => {
		let ret = items.filter(a => {
			if (a.role && isSup(a.role) < 0)
				return (false);
			return (a);
		});
		return (ret);
	}, [isSup]);

	React.useEffect(() => {
		if (!count.current)
		{
			let gen_items = getItems();
			count.current = gen_items.length;
			if (gen_items?.length === 1 && !active)
				setActive(gen_items[0]);
		}
	}, [count, setActive, active, isSup, getItems]);

	const handleClose = () => {
		if (active && items?.length > 1 && count.current > 1)
			setActive(false);
		else
			props.handleClose();
	};

	let list = getItems();

	if (!list?.length)
		return (false);

	return (
		<div className="UsersManager gap-4 overflow-auto">
			<div className="d-flex align-items-center gap-2">
				<BackButton onClick={handleClose}/>
				<h4>{active?.title || "Gestion des utilisateurs"}</h4>
			</div>
			<Filters {...props}/>
			{
				active ?
					<active.component users={props.users} selected={props.selected} {...active.props}/>
					:
					<div>
						<div className="d-flex flex-wrap mt-4 gap-2">
							{
								list.map((a) => {
									return (<ManagerItem key={a.key} {...a} onClick={() => setActive(a)}/>);
								})
							}
						</div>
					</div>
			}
		</div>
	);
}

function Filters(props) {
	const genTitle = () => {
		let ret = [];

		if (props.search)
			ret.push(`- Résultats pour la recherche: "${props.search}"`);
		if (props.showSaved)
			ret.push("- Parmis les utilisateurs favoris");
		if (props.userState !== false)
			ret.push("- " + (props.userState === "actives" ? "Seulement les utilisateurs actifs" : "Seulement les utilisateurs inactifs"));
		if (props.site)
			ret.push(<SiteList siteId={props.site.value}/>);
		if (props.groups?.length)
			ret.push(<GroupsList groups={props.groups}/>);
		return (ret);
	};

	let title = genTitle();

	if (!title.length)
		return (false);

	return (
		<div className="Filters">
			<div className="d-flex align-items-center gap-2">
				<h4><FontAwesomeIcon icon={faExclamationTriangle} color={"orange"}/></h4>
				<h5>Des filtres sont appliqués à la liste des utilisateurs</h5>
			</div>
			<div className="mt-1" style={{fontSize: "1em"}}>
				<b>{props.users?.length}</b> utilisateurs sélectionnables</div>
			<hr/>
			{ title.map((a, key) => <div key={key}>{a}</div>) }
		</div>
	);
}

function SiteList(props) {
	return (
		<div>
			<InfosFetcher
				queryKey={["Sites", props.siteId]}
				queryFn={() => genRequest("Sites/" + props.siteId)}
				treat={(resp) => {
					return (<div>- Appartenant au site <b>"{resp.Name + " -  " + resp.Town}"</b></div>);
				}}
			/>
		</div>
	);
}

function GroupsList(props) {
	const {account} = useAccount();
	if (!props.groups?.length)
		return (false);
	return (
		<div>
			- Faisant parti des groupes:
			<InfosFetcher
				queryKey={["Groups", "OfCompany", account.CompanyId]}
				queryFn={() => genRequest("Groups/OfCompany/" + account.CompanyId)}
				treat={(items) => {
					if (!items?.length)
						return (false);
					let treated = items.map((a) => (props.groups.indexOf(a.GroupId) !== -1 ? {
						color: a.DefaultHexaColor,
						text: a.Name
					} : false))
						.filter(a => a);

					return (
						<TagsList
							className={"ms-2"}
							sortBy={"text"}
							items={treated}
						/>
					);
				}}
			/>
		</div>
	);
}

function ManagerItem(props) {
	return (
		<Button className="ManagerItem btn-h-blue gap-2" onClick={props.onClick} disabled={props.disabled ? true : false}>
			<FontAwesomeIcon icon={props.icon}/>
			{props.title}
		</Button>
	);
}
