import React from "react";
import { useQuery, useQueryClient} from "react-query";
import useAccount from "classes/Accounts/hooks/useAccount";
import genRequest from "includes/request";
import DocumentsPage from "Pages/Documents/components/Page/DocumentsPage";
import "./MyDocuments.css";
import PageLoad from "components/PageLoad/PageLoad";
import { t } from "i18next";
import useTemplate from "hooks/useTemplate";

export default function MyDocuments() {
	const {account} = useAccount();
	const { template } = useTemplate();
	const queryClient = useQueryClient();

	function handleDelete(doc) {
		let req = genRequest(
			"Documents/DocumentId=" + doc.DocumentId + "AnddeleteExpenses=true",
			null,
			"delete"
		)
			.then(() => {
				queryClient.invalidateQueries(["Documents", "DocumentOfEmployeAndType", account.EmployesId, 1]);
			})
			.catch(() => {});
		return (req);
	}

	const fetchMyDocuments = async () => genRequest("Documents/DocumentOfEmployeAndType/" + account.EmployesId + "/" + 1).catch(() => []);

	const {isLoading, error, data} = useQuery(["Documents", "DocumentOfEmployeAndType", account.EmployesId, 1], fetchMyDocuments);

	if (isLoading)
		return ( <PageLoad background={false} color={template?.primaryColor()} text={t("Docs.LOADING")}/>);

	if (error)
		return (<div>{t("Docs.LOAD_ERROR")}</div>);
	return (
		<div className="my-documents-cont">
			<DocumentsPage
				docs={data}
				menuToggler={false}
				handleDelete={handleDelete}
			/>
		</div>
	);
}
