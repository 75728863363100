import useAccount from "classes/Accounts/hooks/useAccount";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
	const accountObj = useAccount();
	const [userOrAdmin, setUserOrAdmin] = useState("");
	const { data, error, isLoading } = useQuery(
		["Account", "UserInfo", accountObj.account.CompanyId],
		() => accountObj.fetchInfos(!accountObj.account.IsTemp),
		{ refetchOnWindowFocus: false }
	);

	// const tweekRights = (rights) => {
	// 	return rights.filter((right) => right.Name !== "AdminEvent");
	// };

	useEffect(() => {
		let isMounted = true;
		if (data && !isLoading && isMounted) {
			const settings = accountObj.getSettings();
			accountObj.setAccount({
				...accountObj.account,
				Settings: settings,
				Role: data.Role,
				// Rights: tweekRights(data.Rights),
				Rights: data.Rights,
				Infos: data.Infos,
			});
		}
		return () => {
			// Cleanup function: Cancel asynchronous tasks or subscriptions here
			isMounted = false;
		};
	}, [data]);

	// Return the context provider with the value
	return (
		<AccountContext.Provider
			value={{ accountObj, userOrAdmin, setUserOrAdmin }}
		>
			{children}
		</AccountContext.Provider>
	);
};

export function useAccountContext() {
	return useContext(AccountContext);
}

export { AccountContext, AccountProvider };
