import React, { useState } from "react";
import { motion } from "framer-motion";
import "./PreviewDrawer.css";
import BackButton from "components/Button/BackButton";
import PageLoad from "components/PageLoad/PageLoad";
import { t } from "i18next";

export default function PreviewDrawer(props) {
	const [isOpening, setIsOpening] = useState(true);
	const initied = React.useRef();

	React.useEffect(() => {
		if (!initied?.current) {
			setTimeout(() => {
				setIsOpening(false);
			}, 500);
			initied.current = true;
		}
	}, []);

	function handleClose() {
		if (props.onClose) props.onClose();
	}

	let childProps = props.childProps || {};

	const slideLeft = {
		hidden: {
			left: "100%",
			opacity: 1,
		},
		show: {
			left: "0%",
			opacity: 1,
			transition: {
				ease: "easeOut",
				duration: 0.5,
			},
		},
	};

	const slideRight = {
		hidden: {
			right: "-100%",
			opacity: 1,
		},
		show: {
			right: "0%",
			opacity: 1,
			transition: {
				ease: "easeOut",
				duration: 0.5,
			},
		},
	};

	return (
		<motion.div
			variants={props.slideRight ? slideRight : slideLeft}
			initial="hidden"
			animate="show"
			className={
				"preview-drawer" +
				(props.isOpen ? " open" : "") +
				(props.fullScreen ? " full-screen" : "")
			}
		>
			<BackButton
				className="close-preview-drawer-btn"
				onClick={handleClose}
				value={props.backBtnText}
			/>
			{isOpening && <PageLoad text={t("Commons.LOADING")} />}
			{!isOpening && props.isOpen && props.child && (
				<props.child onClose={props.onClose} {...childProps} />
			)}
		</motion.div>
	);
}
