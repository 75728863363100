import React from "react";
import { useQuery } from "react-query";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PostImages.css";
import documents_treat from "includes/documents_treat";
import genRequest from "includes/request";
import { getDefaultImage } from "../ImagesIncludes";
import PostImages from "./PostImages";

function AsyncPostImages(props) {

	function fetchImages() {
		if (!props.postId)
			return (Promise.reject(false));
		let req = genRequest("Documents/DocumentOfPostAndType/" + props.postId + "/3")
			.then((resp) => {
				return ( documents_treat(resp, "Name"));
			}, () => {
				let defaultImage = getDefaultImage(props.companyId, props.postTypeId);
				if (props.postTypeId !== 8 && defaultImage)
				{
					return ([{
						Url_Origin: defaultImage,
						Url_Mobile: defaultImage,
						Url_Min: defaultImage
					}]);
				}
				return ([]);
			});
		return (req);
	}

	const { isLoading, data } = useQuery(
		["Documents", "DocumentOfPostAndType", props.postId, 3],
		() => fetchImages(props.companyId, props.postTypeId, props.postId),
		{
			enabled: (props.postId ? true : false),
			retry: 0
		}
	);

	if (isLoading)
		return (
			<div className="PostImages loading">
				<div className="w-100 mb-4" style={{"height": "30vh"}}></div>
			</div>
		);

	if (!data)
	{
		if (props.forceImage === true)
			return (<div className="PostImages p-4"/>);
		else
			return (false);
	}

	return (
		<PostImages
			{...props}
			images={data}
			async={false}
		/>
	);
}

export default AsyncPostImages;
