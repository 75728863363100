import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import "./SelectionList.css";

// const initialData = [
// 	{ id: "item-1", content: "Item 1", active: false },
// 	{ id: "item-2", content: "Item 2", active: false },
// 	{ id: "item-3", content: "Item 3", active: false },
// 	{ id: "item-4", content: "Item 4", active: false },
// ];

const SelectionList = (props) => {
	const [data, setData] = useState(props.items || []);
	const [selectedItems, setSelectedItems] = useState(
		props.items.filter((a) => a.active) || []
	);

	const handleItemClick = (id, state) => {
		const newData = [...data];
		const itemIndex = newData.findIndex((item) => item.id === id);

		if (
			!newData[itemIndex]?.active &&
			props.max >= 0 &&
			selectedItems.length >= props.max
		) {
			return maxReached();
		}

		newData[itemIndex].active = state;
		setData(newData);

		const newSelected = newData.filter((item) => item.active);
		setSelectedItems(newSelected);
		if (props.onChange) props.onChange(newData);
	};

	const handleDragEnd = (result) => {
		const { destination, source, draggableId } = result;

		if (!destination) {
			return;
		}

		const newData = [...data];
		const newSelected = [...selectedItems];

		if (source.droppableId === "non-selected") {
			const draggedItem = newData.find((item) => item.id === draggableId);
			if (props.max >= 0 && newSelected.length >= props.max) {
				return maxReached();
			}
			if (
				source.droppableId === "non-selected" &&
				destination.droppableId === "non-selected"
			)
				return false;
			draggedItem.active = true;
			newSelected.splice(destination.index, 0, draggedItem);
		} else {
			const draggedItem = newSelected.find(
				(item) => item.id === draggableId
			);
			newSelected.splice(source.index, 1);

			if (
				source.droppableId === "selected" &&
				destination.droppableId === "selected"
			)
				newSelected.splice(destination.index, 0, draggedItem);
			else {
				const item = newData.find((item) => item.id === draggableId);
				item.active = false;
			}
		}
		if (props.onChange) props.onChange(newData);
		setData(newData);
		setSelectedItems(newSelected);
	};

	const maxReached = () =>
		toast.error("Nombre maximum atteint", {
			toastId: "SelectionList-maxReached",
		});

	console.table("data", data);
	console.table("selected", selectedItems);

	return (
		<div className="selection-list d-flex w-100 gap-2 radius-3">
			<DragDropContext onDragEnd={handleDragEnd}>
				<div className="selection-list__items w-50 bg-color p-2 radius-3">
					<Droppable droppableId="non-selected">
						{(provided) => (
							<>
								<span>Disponibles</span>
								<hr className="my-1 mb-3"></hr>
								<ul
									className="p-0 mb-0 d-flex flex-column gap-2 h-100"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{data
										.filter((a) => !a.active)
										.map((item, index) => {
											return (
												<Draggable
													key={item.id}
													draggableId={item.id}
													index={index}
												>
													{(provided, snapshot) => (
														<li
															ref={
																provided.innerRef
															}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={`item p-2 bg-color-secondary radius-2 ${
																snapshot.isDragging
																	? "dragging"
																	: ""
															}`}
															onClick={() =>
																handleItemClick(
																	item.id,
																	true
																)
															}
														>
															<span className="d-flex gap-2 align-items-center">
																{/* <FontAwesomeIcon
																icon={
																	faEllipsisV
																}
															/> */}
																{item.content}
															</span>
														</li>
													)}
												</Draggable>
											);
										})}
									{provided.placeholder}
								</ul>
							</>
						)}
					</Droppable>
				</div>
				<div className="selection-list__items w-50 bg-color p-2 radius-3">
					<Droppable droppableId="selected">
						{(provided) => (
							<>
								<span>Sélectionnés</span>
								<hr className="my-1 mb-3"></hr>
								<ul
									className="p-0 mb-0 d-flex flex-column gap-2 h-100"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{selectedItems.map((item, index) => (
										<Draggable
											key={"selected-" + item.id}
											draggableId={item.id}
											index={index}
										>
											{(provided, snapshot) => (
												<li
													ref={provided.innerRef}
													{...provided.draggableProps}
													className={`item p-2 bg-color-secondary radius-2 ${
														snapshot.isDragging
															? "dragging"
															: ""
													}`}
													onClick={() =>
														handleItemClick(
															item.id,
															false
														)
													}
												>
													<span
														{...provided.dragHandleProps}
														className="d-flex gap-2 align-items-center"
													>
														<FontAwesomeIcon
															icon={faEllipsisV}
														/>
														{item.content}
													</span>
												</li>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</ul>
							</>
						)}
					</Droppable>
				</div>
			</DragDropContext>
		</div>
	);
};

export default SelectionList;
