import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import "components/User/Card/UserCard.css";
import "./contacts.css";
import genRequest from "includes/request";
import { t } from "i18next";
import PreviewDrawer from "components/PreviewDrawer/PreviewDrawer";
import UserPreviewDrawer from "Pages/Contacts/components/UserPreviewDrawer/UserPreviewDrawer";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import PageLoad from "components/PageLoad/PageLoad";
import useAccount from "classes/Accounts/hooks/useAccount";
import useDisplay from "hooks/useDisplay";
import UserEditDrawer from "Pages/Contacts/components/UserEditDrawer/UserEditDrawer";
import EmployeObject from "classes/Employes/EmployeObject";
import Button from "components/Button/Button";
import ContactsBody from "./components/ContactsBody";
import UsersManager from "./components/UsersManager/UsersManager";
import noAccent from "includes/no_accents";
import useEditing from "components/EditDrawer/useEditing";

export default function ContactsPage(props) {
	const { account, isSup, isAdmin } = useAccount();
	const [CurSite, setCurSite] = useState(false);
	const [CurUser, setCurUser] = useState(false);
	const [ShowSaved, setShowSaved] = useState(false);
	const [DispState, setDipState] = useState(false);
	const [Saved, setSaved] = useState(false);
	const [DispGroups, setDispGroups] = useState([]);
	const { display, setDisplay } = useDisplay();
	const last_display = React.useRef();
	const [EditObj, setEditObj] = useState(false);
	const [Search, setSearch] = useState(false);
	const [isManagerOpen, setIsManagerOpen] = useState(false);
	const { setEditing } = useEditing();
	const QCL = useQueryClient();

	const { employeId } = Object.fromEntries(
		new URLSearchParams(window.location.search)
	);

	useEffect(() => {
		if (props.platform !== last_display.current) {
			if (props.platform === "admin" && props.display !== "table")
				setDisplay("table");
			else if (props.platform !== "admin" && props.display !== "tiles")
				setDisplay("tiles");
		}
		last_display.current = props.platform;
	}, []);

	useEffect(() => {
		const getOneEmployeFormParamUrl = async () => {
			const { employe } = await getOneEmploye(employeId);
			setCurUser(employe);
		};
		if (employeId !== void 0) {
			getOneEmployeFormParamUrl();
		}
	}, [employeId]);

	const fetchEmployes = async () => {
		const data = await fetchMinimalContactEmployesFromCompany();
		let prom = new Promise((resolve, reject) => {
			let req = fetchEmployesOfCompany;
			if (CurSite?.value) req = fetchEmployesOfSite;

			req().then((resp) => {
				fetchSavedUsers().then(
					async (saveds) => {
						let filtered = saveds
							.map((a) => {
								let item = resp.find(
									(b) => b.EmployesId === a.SavedEmployeId
								);
								if (item) return { ...a, ...item };
								return false;
							})
							.filter((a) => a);
						setSaved(filtered);
						const usersWith = resp.map((x) => {
							const selected = data.find(
								(y) => y.EmployeId === x.EmployesId
							);
							if (selected) {
								return {
									...x,
									...selected,
								};
							}
						});
						resolve(usersWith);
						return usersWith;
					},
					() => {
						setSaved([]);
						resolve(resp);
					}
				);
			}, reject);
		});
		return prom;
	};

	const fetchMinimalContactEmployesFromCompany = async () =>
		genRequest(
			`Employes/MinimalContactEmployesOfCompany/${account.CompanyId}`
		);

	const fetchEmployesOfCompany = () =>
		genRequest("Employes/EmployeOfCompany/" + account.CompanyId);

	const fetchEmployesOfSite = () =>
		genRequest("Employes/EmployeOfSite/" + CurSite.value);

	const fetchSavedUsers = () =>
		genRequest(
			"Lt_Employe_Saved_Employe/OfEmployeId/" + account.EmployesId
		);

	const getFetchRequest = () => {
		let queryKey = ["Employes", "EmployeOfCompany", account.CompanyId];

		if (CurSite?.value)
			queryKey = ["Employes", "EmployeOfSite", CurSite.value];
		if (ShowSaved) queryKey.unshift("Lt_Employe_Saved_Employe");
		return {
			queryFn: fetchEmployes,
			queryKey,
		};
	};

	const getOneEmploye = async (id) => {
		let resp = await genRequest("Employes/FullEmploye/" + id);
		return resp;
	};

	const stateFilter = (item) => {
		if (!DispState) return item;
		if (
			DispState === "actives" &&
			(item.FirstConnexion || item.LastConnexion)
		)
			return item;
		if (
			DispState === "inactives" &&
			!(item.FirstConnexion || item.LastConnexion)
		)
			return item;
		return false;
	};

	const selectGroup = (group) => {
		let groups = [...DispGroups];
		let group_id = group.id;
		let check = DispGroups.indexOf(group_id);

		if (check === -1) groups.push(group_id);
		else
			groups = groups
				.map((a) => {
					if (a === group_id) return false;
					return a;
				})
				.filter((a) => a);
		setDispGroups(groups);
	};

	const handleClick = (user) => {
		setCurUser(user);
	};

	const handleAdd = () => {
		let obj = new EmployeObject({
			CompanyId: account.CompanyId,
			SiteId: isSup("SuperAdmin") >= 0 ? false : account.SiteId,
		});
		obj.fetchForEdit().then(() => {
			setEditObj(obj);
		});
		setEditing(true);
	};

	const handleModify = (user) => {
		let obj = new EmployeObject(user);
		obj.fetchForEdit().then(() => {
			obj.groupsToLink(obj.linkedGroups());
			setEditObj(obj);
		});
		setEditing(true);
	};

	const closeEdit = (isDone) => {
		setCurUser(EditObj.employesId() ? EditObj.values() : false);
		setEditObj(false);
		setEditing(false);
		if (isDone) QCL.resetQueries(["Employes"]);
	};

	const filterList = (data_list) => {
		let list = data_list;
		if (ShowSaved) list = Saved;
		list = list.filter((a) => stateFilter(a));
		if (Search) {
			let filter_reg = new RegExp(noAccent(Search), "gi");
			list = list.filter((user) => {
				if (
					noAccent(user.FirstName).search(filter_reg) !== -1 ||
					noAccent(user.LastName).search(filter_reg) !== -1 ||
					noAccent(user.WFY).search(filter_reg) !== -1 ||
					noAccent(user.WFU).search(filter_reg) !== -1
					// || (user.Display_Mail && user.Mail && noAccent(user.Mail).search(filter_reg) !== -1)
					// || (user.Display_Phone && user.Telephone && noAccent(user.Telephone).search(filter_reg) !== -1)
				)
					return user;
				return false;
			});
		}
		return list;
	};

	const query = getFetchRequest();
	const { isLoading, data } = useQuery(query.queryKey, query.queryFn);

	if (isLoading) return <PageLoad title={t("Contacts.LOADING")} />;

	const users = filterList(data);
	return (
		<div className="ContactsPage d-flex flex-column overflow-hidden">
			<PageHeadBar
				siteSelect={setCurSite}
				curSite={CurSite}
				search={(val) => setSearch(val)}
				title={props.title}
				favState={ShowSaved}
				hideFavSites={true}
				buttons={[
					{
						title: t("Commons.FAVORITES"),
						classes:
							"btn-favs-docs btn" +
							(ShowSaved ? " btn-primary" : ""),
						onClick: () => setShowSaved(!ShowSaved),
					},
					{
						title: "Gérer les utilisateurs",
						classes: "btn" + (isManagerOpen ? " btn-primary" : ""),
						onClick: () => setIsManagerOpen(!isManagerOpen),
						enabled: isAdmin() && display === "table",
					},
				]}
				addTitle={t("Contacts.ADD_BTN")}
				handleAdd={isAdmin() ? handleAdd : false}
				isMenuOpen={props.isMenuOpen}
				setIsMenuOpen={props.setIsMenuOpen}
				categories={true}
				dispCats={DispGroups}
				selectCategory={selectGroup}
				resetCatsFilter={() => setDispGroups([])}
				catsTreat={(a) => ({
					id: a.GroupId,
					title: a.Description,
					text: a.Name,
					color: a.DefaultHexaColor,
				})}
				catsQueryKey={["Groups", "OfCompany", account.CompanyId]}
				catsQueryFn={() =>
					genRequest("Groups/OfCompany/" + account.CompanyId)
				}
				display={display}
				setDisplay={setDisplay}
				filters={true}
				filterChilds={[
					{
						enabled: isAdmin(),
						title: "Etat des utilisateurs",
						component: UsersStateFilter,
						props: {
							state: DispState,
							setState: setDipState,
						},
						filterCount: DispState !== false ? 1 : 0,
						resetFilter: () => setDipState(false),
					},
				]}
			/>
			<ContactsBody
				users={users}
				search={Search}
				curUser={CurUser}
				handleClick={handleClick}
				display={display}
				dispGroups={DispGroups}
			/>
			{CurUser && (
				<PreviewDrawer
					child={UserPreviewDrawer}
					fullScreen={true}
					onClose={() => handleClick(false)}
					isOpen={CurUser ? true : false}
					childProps={{
						user: CurUser,
						handleModify:
							isAdmin() && isSup(CurUser.Role) >= 0
								? handleModify
								: false,
					}}
				/>
			)}
			{EditObj && (
				<UserEditDrawer
					adderRights={props.rights}
					user={EditObj}
					isOpen={true}
					onClose={closeEdit}
				/>
			)}
			{isManagerOpen && (
				<UsersManager
					users={users}
					groups={DispGroups}
					site={isSup("SiteAdmin") > 0 ? CurSite : false}
					search={Search}
					showSaved={ShowSaved}
					userState={DispState}
					handleClose={() => setIsManagerOpen(false)}
				/>
			)}
		</div>
	);
}

const UsersStateFilter = (props) => {
	const { state, setState } = props;

	return (
		<div>
			<div className="d-flex gap-2">
				<Button
					className={"w-auto " + (!state ? "btn-primary" : "btn-bg")}
					onClick={() => setState(false)}
					text={"Tous"}
				/>
				<Button
					className={
						"w-auto " +
						(state === "actives" ? "btn-primary" : "btn-bg")
					}
					onClick={() => setState("actives")}
					text={"Actifs"}
				/>
				<Button
					className={
						"w-auto " +
						(state === "inactives" ? "btn-primary" : "btn-bg")
					}
					onClick={() => setState("inactives")}
					text={"Inactifs"}
				/>
			</div>
		</div>
	);
};
