import DocumentObject from "classes/Documents/DocumentObject";
import documents_treat from "includes/documents_treat";
import formatDate from "includes/formatDate";
import noAccent from "includes/no_accents";
import genRequest from "includes/request";

class EmployeObject {
	#EmployesId = null;
	#FirstName = "";
	#LastName = "";
	#Sex = null;
	#HiredDate = null;
	#Workplace = null;
	#Login = null;
	#Telephone = null;
	#Mail = null;
	#SiteId = null;
	#AspNetUserId = null;
	#CompanyId = null;
	#CompanyRankId = null;
	#WorkFamillyId = null;
	#WorkFunctionId = null;
	#LastChanged = null;
	#FirstConnexion = null;
	#LastConnexion = null;
	#Hobby = null;
	#LastChange_AuthorId = null;
	#Token_Regeneration = null;
	#Display_Phone = true;
	#Display_Mail = true;
	#ByPass_Moderation = null;
	#Role = "UserPublisher";
	#OldRole = null;
	#UserTemplateId = null;
	#LanguageId = null;
	#IsVisibleInApp = null;

	#CompanyName = null;
	#LinkedGroups = [];
	#LinkToGroups = [];
	#Documents = [];
	#Password = null;
	#IsNew = false;
	#Rights = [];
	#CurrentImages = [];
	#Image = null;

	constructor(obj) {
		this.#initValues(obj);
	}

	#initValues(obj) {
		let date = formatDate();

		this.#EmployesId = (obj?.EmployesId ? obj.EmployesId : null);
		this.#FirstName = (obj?.FirstName ? obj.FirstName : "");
		this.#LastName = (obj?.LastName ? obj.LastName : "");
		this.#Sex = (obj?.Sex ? obj.Sex : "");
		this.#HiredDate = (obj?.HiredDate ? obj.HiredDate : null);
		this.#Workplace = (obj?.Workplace ? obj.Workplace : "");
		this.#Login = (obj?.Login ? obj.Login : "");
		this.#Telephone = (obj?.Telephone ? obj.Telephone : "");
		this.#Mail = (obj?.Mail ? obj.Mail : "");
		this.#SiteId = (obj?.SiteId ? obj.SiteId : null);
		this.#AspNetUserId = (obj?.AspNetUserId ? obj.AspNetUserId : null);
		this.#CompanyId = (obj?.CompanyId ? obj.CompanyId : null);
		this.#CompanyRankId = (obj?.CompanyRankId ? obj.CompanyRankId : 1);
		this.#WorkFamillyId = (obj?.WorkFamillyId ? obj.WorkFamillyId : null);
		this.#WorkFunctionId = (obj?.WorkFunctionId ? obj.WorkFunctionId : null);
		this.#LastChanged = (obj?.LastChanged ? obj.LastChanged : date);
		this.#FirstConnexion = (obj?.FirstConnexion ? obj.FirstConnexion : null);
		this.#LastConnexion = (obj?.LastConnexion ? obj.LastConnexion : null);
		this.#Hobby = (obj?.Hobby ? obj.Hobby : "");
		this.#LastChange_AuthorId = (obj?.LastChange_AuthorId ? obj.LastChange_AuthorId : null);
		this.#Token_Regeneration = (obj?.Token_Regeneration ? obj.Token_Regeneration : false);
		this.#Display_Phone = (obj?.Display_Phone ? obj.Display_Phone : true);
		this.#Display_Mail = (obj?.Display_Mail ? obj.Display_Mail : true);
		this.#ByPass_Moderation = (obj?.ByPass_Moderation ? obj.ByPass_Moderation : false);
		this.#Role = (obj?.Role ? obj.Role : "UserPublisher");
		this.#UserTemplateId = (obj?.UserTemplateId ? obj.UserTemplateId : null);
		this.#LanguageId = (obj?.LanguageId ? obj.LanguageId : 1);
		this.#IsVisibleInApp = (obj?.IsVisibleInApp ? obj.IsVisibleInApp : true);

		this.#OldRole = obj?.OldRole ? obj.OldRole : this.#Role;

		if (!this.#EmployesId)
			this.#IsNew = true;
	}

	fetchForEdit() {
		let proms = [
			this.fetchCompany(),
			this.fetchRights(),
			this.fetchLinkedGroups(),
			this.fetchLinkedDocs(),
			this.fetchProfileImage()
		];
		return (Promise.all(proms));
	}

	fetchCompany() {
		let that = this;
		let req = genRequest("Companies/" + this.#CompanyId).then((resp) => {
			that.#CompanyName = resp.Name;
		});
		return (req);
	}

	send() {
		if (this.#Role !== this.#OldRole && this.#EmployesId)
			return (this.sendWithRole());
		return (this.sendUser());
	}

	sendUser() {
		let that = this;
		let values = this.values();

		let req = genRequest(
			"Employes" + (this.#EmployesId ? "/" + this.#EmployesId : ""),
			values,
			(this.#EmployesId ? "put" : "post")
		).then((resp) => {
			if (!that.#EmployesId)
				that.#initValues(resp);
		});
		return (req);
	}

	sendWithRole() {
		if (!this.#EmployesId)
			return (Promise.reject(new Error("No Employe Id")));

		let values = this.values();
		values.Token_Regeneration = true;

		let req = genRequest(
			"Employes/InitEmployeWithRole/" + this.#Role,
			values,
			"put"
		);
		return (req);
	}

	register (login, password, mail) {
		let that = this;
		let name = noAccent((this.#FirstName[0] + "." + this.#LastName).trim().slice(0, 10).replace(/\s/gi, "_"));
		let pass = (password?.length ? password : randomPsw(8));
		let data = {
			Mail: (mail?.length ? mail : this.#Mail),
			Password: pass,
			ConfirmPassword: pass,
			Username: (login?.length ? login : name + "_" + this.#CompanyName).toLowerCase().replace(/\s/gi, "_")
		};

		let req = genRequest("Account/RegisterWithRole/" + this.#Role, data, "post").then((resp) => {
			try {
				let resp_user = JSON.parse(resp);
				that.#AspNetUserId = resp_user.ASP;
				if (resp_user.UserName)
					that.#Login = resp_user.UserName;
				that.#Password = pass;
			} catch (error) {
				that.#AspNetUserId = false;
				that.#Login = false;
			}
			return (resp);
		});
		return (req);
	}

	sendConfirmMail() {
		let data = {
			"Email": this.#Mail,
			"CcEmail": "",
			"BccEmail": "",
			"MailSubjectOverride": "",
			"MailBodyOverride": "",
			"FirstName": this.#FirstName,
			"Lastname": this.#LastName,
			"Login": this.#Login,
			"Password": this.#Password,
			"CompanyName": this.#CompanyName,
		};
		let req = genRequest("Account/CreatePswdMailQueueFile", data, "post");
		return (req);
	}

	fetchProfileImage() {
		let that = this;

		if (!this.#EmployesId)
		 return Promise.resolve(true)

		let req = genRequest("Documents/DocumentOfEmployeAndType/" + this.#EmployesId + "/2")
				.then((images) => {
					that.#CurrentImages = (images?.length ? images : []);
					return (images);
				});
		return (req);
	}

	image(setImage) {
		if (typeof setImage !== "undefined")
			this.#Image = setImage;
		return (this.#Image);
	}

	#clearOldImages() {
		let proms = []
		if (this.#CurrentImages?.length)
		{
			proms = this.#CurrentImages.map((a) => {
				let doc = new DocumentObject(a);
				return (doc.delete(false, true))
			})
		}
		return (Promise.allSettled(proms));
	}

	sendImage() {
		if (!this.#Image)
			return (Promise.resolve(true));
		this.#Image.resetLinks(this.#CompanyId, this.#SiteId, this.#EmployesId, false);
		let proms = [
			this.#clearOldImages(),
			this.#Image.send()
		]
		return (Promise.allSettled(proms));
	}

	fetchRights() {
		let that = this;
		if (!this.#EmployesId)
			return ([]);
		let req = genRequest("UserRights/UserOrAdminRightOfEmploye/" + this.#EmployesId)
			.then(
				(resp) => that.#Rights = resp,
				() => that.#Rights = []
			);
		return (req);
	}

	sendRights() {
		if (!this.#EmployesId)
			return (Promise.reject(new Error("No EmployesId, send employe before rights link")));
		let rights = this.#Rights.map(a => ({UserRightId: a.Id, EmployeId: this.#EmployesId}));
		let that = this;
		let prom = new Promise((resolve, reject) => {
			that.deleteRights()
				.then(() => genRequest(
					"Lt_Employe_Right/MultiplePOST",
					rights,
					"post"
				))
				.then(resolve, reject);
		});
		return (prom);
	}

	deleteRights = () => genRequest("Lt_Employe_Right/EmployeId/" + this.#EmployesId, null, "delete");

	rights(only_perms) {
		if (!only_perms)
			return (this.#Rights);
		let ret = {};
		if (!this.#Rights.length)
			return (false);
		this.#Rights.forEach((a) => {
			ret[a.Name] = true;
		});
		return (ret);
	}

	setRights(values) {
		if (typeof values !== "undefined")
			this.#Rights = values;
		return (this.#Rights);
	}

	// Groups

	fetchLinkedGroups() {
		if (!this.#EmployesId)
			return ([]);
		let that = this;
		let req = genRequest("Groups/GroupOfEmploye/" + this.#EmployesId, null, "get").then((resp) => {
			that.#LinkedGroups = (resp?.length ? resp : []);
			return (that.#LinkedGroups);
		}, () => {
			that.#LinkedGroups = [];
			return ([]);
		});
		return (req);
	}

	sendGroups() {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			that.unlinkGroups()
				.then(() => that.linkToGroups())
				.then(resolve, reject);
		});
		return (prom);
	}

	linkToGroups() {
		if (!this.#LinkToGroups?.length)
			return (true);
		let proms = this.#LinkToGroups.map((a) => {
			return (this.linkToGroup(a.GroupId));
		});
		return (Promise.all(proms));
	}

	linkToGroup = (groupId) => genRequest(
		"Lt_EmployeOfGroup",
		{
			"GroupId": groupId,
			"EmployesId": this.#EmployesId
		}, "post");

	unlinkGroups(groupId) {
		let that = this;
		let url = (
			!groupId ?
				"LTEmployeOfGroup/EmployeId/" + this.#EmployesId
				:
				"LTEmployeOfGroup/EmployeIdAndGroupId/" + this.#EmployesId + "/" + groupId
		);
		let req = genRequest(url, null, "delete").then(() => {
			if (groupId) {
				for (let x in that.#LinkedGroups)
					if (that.#LinkedGroups[x].GroupId === groupId) {
						that.#LinkedGroups.splice(x, 1);
						break;
					}
			} else
				that.#LinkedGroups = [];
		});
		return (req);
	}

	linkedGroups = () => this.#LinkedGroups;

	groupsToLink(setGroups) {
		if (typeof setGroups !== "undefined")
			this.#LinkToGroups = setGroups;
		return (this.#LinkToGroups);
	}

	/*
	** -- Documents
	*/

	fetchDocs(typeId) {
		let url = "/Documents/DocumentOfEmploye/" + this.#EmployesId;
		if (typeId)
			url = "Documents/DocumentOfEmployeAndType/" + this.#EmployesId + "/" + typeId;
		let req = genRequest(url).then((resp) => {
			return (resp);
		});
		return (req);
	}

	fetchLinkedDocs() {
		if (!this.#EmployesId)
			return (Promise.resolve(true));
		let req = genRequest("Documents/DocumentOfEmployeAndType/" + this.#EmployesId + "/1").then((resp) => {
			let treated = documents_treat(resp);
			this.#Documents = treated;
			return (treated);
		});
		return (req);
	}

	sendDocs() {
		if (!this.#Documents?.length)
			return (Promise.resolve(true));
		let links = this.#Documents.map((a) => {
			let temp = a;
			a.employeId(this.#EmployesId);
			if (a.isNew())
			{
				temp.documentId(null);
				temp.urls(null);
				return (temp.send());
			}
			return (temp.linkDoc());
		});
		return (Promise.all(links));
	}

	unlinkDocs() {
		if (!this.#EmployesId)
			return (Promise.resolve(true));
		let prom = genRequest(
			"LtDocumentEmployes/EmployeId/" + this.#EmployesId,
			null,
			"delete"
		)
			.catch(() => Promise.resolve(true))
			.then(() => Promise.resolve(true));
		return (prom);
	}

	documents(setDocs) {
		if (typeof setDocs !== "undefined")
			this.#Documents = setDocs;
		return (this.#Documents);
	}

	/*
	** -- GET / SET
	*/

	isNew = () => this.#IsNew;

	employesId = () => this.#EmployesId;

	firstName(setFirstName) {
		if (typeof setFirstName !== "undefined")
			this.#FirstName = setFirstName;
		return (this.#FirstName);
	}

	lastName(setLastName) {
		if (typeof setLastName !== "undefined")
			this.#LastName = setLastName;
		return (this.#LastName);
	}

	sex(setSex) {
		if (typeof setSex !== "undefined")
			this.#Sex = setSex;
		return (this.#Sex);
	}

	hiredDate(setHiredDate) {
		if (typeof setHiredDate !== "undefined")
			this.#HiredDate = setHiredDate;
		return (this.#HiredDate);
	}

	workplace(setWorkplace) {
		if (typeof setWorkplace !== "undefined")
			this.#Workplace = setWorkplace;
		return (this.#Workplace);
	}

	login = () => this.#Login;

	telephone(setTelephone) {
		if (typeof setTelephone !== "undefined")
			this.#Telephone = setTelephone;
		return (this.#Telephone);
	}

	mail(setMail) {
		if (typeof setMail !== "undefined")
			this.#Mail = setMail;
		return (this.#Mail);
	}

	siteId(setSiteId) {
		if (typeof setSiteId !== "undefined")
			this.#SiteId = setSiteId;
		return (this.#SiteId);
	}

	aspNetUserId() {
		return (this.#AspNetUserId);
	}

	companyId(setCompanyId) {
		if (typeof setCompanyId !== "undefined")
			this.#CompanyId = setCompanyId;
		return (this.#CompanyId);
	}

	companyRankId(setCompanyRankId) {
		if (typeof setCompanyRankId !== "undefined")
			this.#CompanyRankId = setCompanyRankId;
		return (this.#CompanyRankId);
	}

	workFamilyId(setWorkFamilyId) {
		if (typeof setWorkFamilyId !== "undefined")
			this.#WorkFamillyId = setWorkFamilyId;
		return (this.#WorkFamillyId);
	}

	workFunctionId(setWorkFunctionId) {
		if (typeof setWorkFunctionId !== "undefined")
			this.#WorkFunctionId = setWorkFunctionId;
		return (this.#WorkFunctionId);
	}

	lastChanged(setLastChanged) {
		if (typeof setLastChanged !== "undefined")
			this.#LastChanged = setLastChanged;
		return (this.#LastChanged);
	}

	firstConnexion(setFirstConnexion) {
		if (typeof setFirstConnexion !== "undefined")
			this.#FirstConnexion = setFirstConnexion;
		return (this.#FirstConnexion);
	}

	lastConnexion(setLastConnexion) {
		if (typeof setLastConnexion !== "undefined")
			this.#LastConnexion = setLastConnexion;
		return (this.#LastConnexion);
	}

	hobby(setHobby) {
		if (typeof setHobby !== "undefined")
			this.#Hobby = setHobby;
		return (this.#Hobby);
	}

	lastChange_AuthorId(setLastChange_AuthorId) {
		if (typeof setLastChange_AuthorId !== "undefined")
			this.#LastChange_AuthorId = setLastChange_AuthorId;
		return (this.#LastChange_AuthorId);
	}

	token_Regeneration(setToken_Regeneration) {
		if (typeof setToken_Regeneration !== "undefined")
			this.#Token_Regeneration = setToken_Regeneration;
		return (this.#Token_Regeneration);
	}

	display_Phone(setDisplay_Phone) {
		if (typeof setDisplay_Phone !== "undefined")
			this.#Display_Phone = setDisplay_Phone;
		return (this.#Display_Phone);
	}

	display_Mail(setDisplay_Mail) {
		if (typeof setDisplay_Mail !== "undefined")
			this.#Display_Mail = setDisplay_Mail;
		return (this.#Display_Mail);
	}

	bypassModeration(setByPass_Moderation) {
		if (typeof setByPass_Moderation !== "undefined")
			this.#ByPass_Moderation = setByPass_Moderation;
		return (this.#ByPass_Moderation);
	}

	role(setRole) {
		if (typeof setRole !== "undefined")
		{
			if (typeof setRole === "string")
				this.#Role = setRole;
			else {
				this.bypassModeration(setRole.bypassModeration);
				this.#Role = setRole.value
			}
		}
		return (this.#Role);
	}

	userTemplateId(setUserTemplateId) {
		if (typeof setUserTemplateId !== "undefined")
			this.#UserTemplateId = setUserTemplateId;
		return (this.#UserTemplateId);
	}

	languageId(setLanguageId) {
		if (typeof setLanguageId !== "undefined")
			this.#LanguageId = setLanguageId;
		return (this.#LanguageId);
	}

	isVisibleInApp(setIsVisibleInApp) {
		if (typeof setIsVisibleInApp !== "undefined")
			this.#IsVisibleInApp = setIsVisibleInApp;
		return (this.#IsVisibleInApp);
	}

	values() {
		let vals = {
			EmployesId: this.#EmployesId,
			FirstName: this.#FirstName,
			LastName: this.#LastName,
			Sex: this.#Sex,
			HiredDate: this.#HiredDate,
			Workplace: this.#Workplace,
			Login: this.#Login,
			Telephone: this.#Telephone,
			Mail: this.#Mail,
			SiteId: this.#SiteId,
			AspNetUserId: this.#AspNetUserId,
			CompanyId: this.#CompanyId,
			CompanyRankId: this.#CompanyRankId,
			WorkFamillyId: this.#WorkFamillyId,
			WorkFunctionId: this.#WorkFunctionId,
			LastChanged: this.#LastChanged,
			FirstConnexion: this.#FirstConnexion,
			LastConnexion: this.#LastConnexion,
			Hobby: this.#Hobby,
			LastChange_AuthorId: this.#LastChange_AuthorId,
			Token_Regeneration: this.#Token_Regeneration,
			Display_Phone: this.#Display_Phone,
			Display_Mail: this.#Display_Mail,
			ByPass_Moderation: this.#ByPass_Moderation,
			Role: this.#Role,
			UserTemplateId: this.#UserTemplateId,
			LanguageId: this.#LanguageId,
			IsVisibleInApp: this.#IsVisibleInApp,
		};

		if (!vals.EmployesId)
			delete vals.EmployesId;
		return (vals);
	}
}

function randomPsw(length) {
	let sample = ["123456789", "ABCDEFGHJKMNPQRSTUVWXYZ", "abcdefghjkmnopqrstuvwxyz", "!._-"];
	let check = [0, 0, 0, 0];
	let wrd = "";
	const reducer = function (accumulator, currentValue) {
		return (accumulator + currentValue);
	};
	while (check.reduce(reducer) < sample.length) {
		check = [0, 0, 0, 0];
		wrd = "";
		for (let x = 0; x < length; x++) {
			let randCat = parseInt(Math.random() * sample.length);
			if (randCat !== 3 || (check[3] !== 1 && x > 1)) {
				check[randCat] = 1;
				wrd = wrd + sample[randCat][parseInt(Math.random() * sample[randCat].length)];
			} else
				x--;
		}
	}
	return (wrd);
}

export default EmployeObject;
