import React, { Component } from "react";
import noAccent from "includes/no_accents";
import UsersTable from "./UsersTable/UsersTable";
import UsersTiles from "./UsersTiles/UsersTiles";

export default class ContactsBody extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Users: props.users ? props.users : [],
			Displayed: [],
			DispIndex: 0,
			DispStep: props.users ? props.users.length : 1000,
			HasMore: true,
			DispFavs: false,
			DispCats: [],
			DispGroups: [],
		};

		this.getNextsUsers = this.getNextsUsers.bind(this);
		this.sortUsers = this.sortUsers.bind(this);
		this.getUsers = this.getUsers.bind(this);
	}

	componentDidMount() {
		this.sortUsers();
		if (this.props.display !== "table") this.getNextsUsers();
	}

	filterByGroupIds(employees, groupIds) {
		if (!groupIds.length) return employees;
		return employees.filter((employee) => {
			// If Groups is undefined, treat it as an empty array
			let groups = employee.Groups || [];
			return groups.some((group) => groupIds.includes(group.GroupId));
		});
	}

	componentDidUpdate(old_props) {
		let old_users = old_props.users;
		let new_users = this.props.users;
		let addeds = new_users
			? new_users?.filter(
					(o1) =>
						!old_users.some((o2) => o1.EmployesId === o2.EmployesId)
			  )
			: [];
		let removed = old_users
			? old_users?.filter(
					(o1) =>
						!new_users.some((o2) => o1.EmployesId === o2.EmployesId)
			  )
			: [];
		if (
			old_props.state !== this.props.state ||
			addeds.length ||
			removed.length ||
			old_users?.length !== new_users?.length ||
			old_props.search !== this.props.search
		) {
			this.setState(
				{
					Users: this.props.users,
					Displayed: [],
					DispIndex: 0,
					DispStep: 20,
					HasMore: true,
					CurPost: false,
				},
				() => {
					this.sortUsers(false, false, () => {
						if (this.props.display !== "table")
							this.getNextsUsers();
					});
				}
			);
		}
		if (old_props.dispGroups?.length !== this.props.dispGroups?.length) {
			const usersFiltered = this.filterByGroupIds(
				this.props.users,
				this.props.dispGroups
			);
			this.setState({
				Users: usersFiltered,
			});
		}
	}

	sortUsers(sort_by, rev_order, then) {
		if (!sort_by) sort_by = "FirstName";
		let users = this.state.Users.sort((a, b) => {
			if (
				typeof a[sort_by] === "string" &&
				typeof b[sort_by] === "string"
			) {
				let txt_a = noAccent(a[sort_by]).toLowerCase();
				let txt_b = noAccent(b[sort_by]).toLowerCase();
				if (txt_a === txt_b)
					if (a.EmployesId < b.EmployesId) return rev_order ? 1 : -1;
				if (txt_a < txt_b) return rev_order ? 1 : -1;
				return rev_order ? -1 : 1;
			}
			if (a[sort_by] < b[sort_by]) return rev_order ? 1 : -1;
			return rev_order ? -1 : 1;
		});
		this.setState(
			{
				Users: users,
			},
			then
		);
	}

	checkSite(user) {
		let siteId = this.state.CurSite?.value;
		if (siteId && user.SiteId !== siteId) return false;
		return true;
	}

	getUsers(name_filter) {
		let filter_reg = new RegExp(noAccent(name_filter), "gi");
		let ret = this.state.Users.filter((user) => {
			if (
				!name_filter ||
				noAccent(user.FirstName).search(filter_reg) !== -1 ||
				noAccent(user.LastName).search(filter_reg) !== -1 ||
				noAccent(user.WFY).search(filter_reg) !== -1 ||
				noAccent(user.WFU).search(filter_reg) !== -1
				// || (user.Display_Mail && user.Mail && noAccent(user.Mail).search(filter_reg) !== -1)
				// || (user.Display_Phone && user.Telephone && noAccent(user.Telephone).search(filter_reg) !== -1)
			)
				return user;
			return false;
		});
		return ret;
	}

	getNextsUsers() {
		let users = this.state.Users;
		let disp_count = 0;
		let items = [];

		for (
			let x = this.state.DispIndex;
			disp_count < this.state.DispStep && x < users.length;
			x++
		)
			if (this.checkSite(users[x])) {
				items.push(users[x]);
				disp_count++;
			}
		this.setState({
			Displayed: this.state.Displayed.concat(items),
			DispIndex: this.state.DispIndex + disp_count,
			HasMore: disp_count < this.state.DispStep ? false : true,
		});
		return items;
	}

	render() {
		let childProps = {
			curUser: this.props.curUser,
			items: this.state.Users,
			displayed: this.state.Displayed,
			hasMore: this.state.HasMore,
			handleClick: this.props.handleClick,
			getNextItems: this.getNextsUsers,
			groupsFilter: this.props.dispGroups,
			search: this.props.search,
		};

		return (
			<div className={"ContactsBody d-flex overflow-hidden h-100"}>
				{this.props.display === "table" ? (
					<UsersTable
						itemsLength={this.state.Displayed?.length}
						{...childProps}
					/>
				) : (
					<UsersTiles
						itemsLength={this.state.Displayed?.length}
						{...childProps}
					/>
				)}
			</div>
		);
	}
}
