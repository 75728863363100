import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import useAccount from "classes/Accounts/hooks/useAccount";
import { AsyncScrollTable } from "components/ScrollTable/ScrollTable";
import { t } from "i18next";
import Tabs from "components/Tabs/Tabs";
import "./Professions.css";
import FormInput from "components/Inputs/FormInput";
import useSelect from "hooks/useSelect";
import WorkFamillyObject from "classes/Professions/WorkFamillyObject";
import genRequest from "includes/request";
import WorkFunctionObject from "classes/Professions/WorkFunctionObject";
import generateUUID from "includes/UUID";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import Button from "components/Button/Button";
import promiseToast from "components/Toasts/PromiseToast";
import { returnRightsFromName } from "includes/rightType";

function ProfessionsPage(props) {
	const { account } = useAccount();
	const [search, setSearch] = useState();
	const { isMenuOpen, setIsMenuOpen } = props;
	const [tabs, setTabs] = useState([
		{
			title: t("WorkFamillies.WORK_FAMILLIES"),
			child: ProfessionsTab,
			keyRight: "AdminWFY",
			childProps: {
				type: "WorkFamillies",
				colName: "WorkFamillyName",
				itemId: "WorkFamillyId",
				queryFn: () =>
					genRequest(
						"WorkFamillies/WorkFamillyOfCompany/" +
							account.CompanyId
					),
				addTitle: t("WorkFamillies.ADD_FAM"),
				modifyTitle: t("WorkFamillies.MODIFY_FAM"),
				index: generateUUID(),
			},
			isVisible: true,
		},
		{
			title: t("WorkFunctions.WORK_FUNCTIONS"),
			child: ProfessionsTab,
			keyRight: "AdminWFU",
			childProps: {
				type: "WorkFunctions",
				colName: "WorkFunctionName",
				itemId: "WorkFunctionId",
				queryFn: () =>
					genRequest(
						"WorkFunctions/WorkFunctionOfCompany/" +
							account.CompanyId
					),
				addTitle: t("WorkFunctions.ADD_FUNC"),
				modifyTitle: t("WorkFunctions.MODIFY_FUNC"),
				index: generateUUID(),
			},
			isVisible: true,
		},
	]);
	useEffect(() => {
		if (account.Rights && account.Rights.length > 0) {
			const newTabs = tabs.map((tab) => {
				return {
					...tab,
					isVisible: returnRightsFromName(
						account.Rights,
						tab.keyRight
					),
				};
			});
			setTabs(newTabs);
		}
	}, [account]);

	function handleSearch(resp) {
		setSearch(resp);
	}

	return (
		<div className="d-flex flex-column h-100">
			<PageHeadBar
				title={t("Pages.PROFESSIONS")}
				siteSelect={false}
				search={handleSearch}
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
			/>
			<Tabs items={tabs} passProps={{ handleSearch, search }} />
		</div>
	);
}

function ProfessionsTab(props) {
	const { account } = useAccount();
	const queryClient = useQueryClient();
	const [editing, setEditing] = useState(false);
	const [sending, setSending] = useState(false);
	const [selecteds, setSelected] = useState([]);
	const [addError, setAddError] = useState(false);
	const { confirmDelete } = useSelect();

	function handleAdd(toModify) {
		let obj;
		if (props.type === "WorkFamillies")
			obj = new WorkFamillyObject({
				CompanyId: account.CompanyId,
				...toModify,
			});
		else
			obj = new WorkFunctionObject({
				CompanyId: account.CompanyId,
				...toModify,
			});
		setEditing(obj);
		setAddError(false);
	}

	function cancelEdit() {
		setEditing(false);
		setSending(false);
	}

	function handleSelect(resp) {
		setSelected(resp);
	}

	function sendFam() {
		if (!editing.name()) return setAddError(t("Commons.NAME_ERROR"));
		setSending(true);
		let prom = editing.send().then(
			() => {
				queryClient.resetQueries([props.type]);
				setEditing(false);
				setSelected([]);
				setSending(false);
			},
			() => {
				setSending(false);
			}
		);
		promiseToast(prom);
	}

	function handleDelete() {
		if (!selecteds?.length || sending) return false;
		let key =
			props.type === "WorkFamillies"
				? "WorkFamillyName"
				: "WorkFunctionName";

		let title = t("Commons.ASKDELETE", {
			count: selecteds?.length,
			name: selecteds[0][key],
		});
		confirmDelete(delSelecteds, null, title);
	}

	function delSelecteds() {
		let proms = selecteds.map((item) =>
			genRequest(props.type + "/" + item[props.itemId], null, "delete")
		);

		let prom = Promise.allSettled(proms).then(() => {
			queryClient.resetQueries([props.type]);
			setSelected([]);
		});

		promiseToast(prom, {
			pending: t("Commons.DELETING", { count: selecteds?.length }),
		});

		return prom;
	}

	return (
		<div className="d-flex flex-column overflow-hidden position-relative">
			<div className="d-flex w-100 justify-content-between mb-2 px-2">
				<div className="me-1">
					<Button className="btn-primary" onClick={handleAdd}>
						{props.addTitle || t("Commons.ADD")}
					</Button>
				</div>
				<div>
					{selecteds.length > 0 && (
						<Button className="btn btn-red" onClick={handleDelete}>
							{t("Commons.DELETE") +
								" " +
								t("Commons.ITEMS", { count: selecteds.length })}
						</Button>
					)}
				</div>
			</div>
			<AsyncScrollTable
				companyId={account.CompanyId}
				itemId={props.itemId}
				queryKey={[props.type]}
				queryFn={props.queryFn}
				key={props.index}
				checkbox={true}
				onItemClick={handleAdd}
				cols={[
					{
						name: props.colName,
						title:
							props.type === "WorkFamillies"
								? t("WorkFamillies.WORK_FAMILLIES")
								: t("WorkFunctions.WORK_FUNCTIONS"),
					},
				]}
				selected={selecteds}
				setSelected={handleSelect}
				search={props.search}
				revSort={false}
				sortBy={props.colName}
			/>
			{editing && (
				<div className="edit-cont d-flex w-100 mb-2">
					<div className="edit-title mb-3 text-uppercase">
						{editing.id() ? props.modifyTitle : props.addTitle}
					</div>
					{addError && (
						<ErrorsCont
							className="mb-2"
							errors={addError}
							setErrors={setAddError}
						/>
					)}
					<div>
						<FormInput
							className="mb-2 w-100"
							title={t("Table.NAME")}
							value={editing.name()}
							onChange={(val) => editing.name(val)}
						/>
					</div>
					<div className="d-flex mt-4 justify-content-between">
						<div className="w-50 me-2">
							<Button
								className={
									"btn btn-red" +
									(sending ? " disabled loading" : "")
								}
								onClick={sending ? false : cancelEdit}
							>
								{t("Commons.CANCEL")}
							</Button>
						</div>
						<div className="w-50">
							<Button
								className={
									"btn btn-blue" +
									(sending ? " disabled loading" : "")
								}
								onClick={sending ? false : sendFam}
							>
								{t("Commons.SAVE")}
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default ProfessionsPage;
