import React from "react";
import useSelect from "hooks/useSelect";
import genRequest from "includes/request";
import { useQueryClient } from "react-query";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import UsersExporter from "../../UsersManager/components/UsersExporter/UsersExporter";
import { t } from "i18next";
import promiseToast from "components/Toasts/PromiseToast";
import TableToolbar from "components/Table/TableToolbar/TableToolbar";
import useAccount from "classes/Accounts/hooks/useAccount";

export default function UsersTableToolbar(props) {
	const { account } = useAccount();
	const { selectedRows, clearSelection } = props;
	const { confirmDelete } = useSelect();
	const QCL = useQueryClient();

	function handleDelete() {
		if (!selectedRows?.length) return false;
		let title = t("Contacts.ASKDELETE", {
			count: selectedRows?.length,
			name: selectedRows[0].FirstName + " " + selectedRows[0].LastName,
		});
		confirmDelete(
			() => {
				delSelecteds();
			},
			null,
			title
		);
	}

	function delSelecteds() {
		let proms = selectedRows.map((user, x) => {
			let prom = false;
			if (user?.EmployesId && user.EmployesId !== account.EmployesId)
				prom = new Promise((resolve, reject) => {
					setTimeout(() => {
						genRequest(
							`Employes/EmployeId=${
								user.EmployesId
							}AndDeleteComment=${true}AndDeleteMessage=${true}AndDeletePost=${false}AndDeleteParticipationAnimation=${true}AndDeleteDocument=${true}AndDeleteExpensesReport${true}`,
							null,
							"delete"
						).then((resp) => {
							resolve(resp);
						}, reject);
					}, 500 * x);
				});
			return prom;
		});

		let prom = Promise.allSettled(proms).then(() => {
			QCL.resetQueries(["Employes"]);
			clearSelection();
		});

		promiseToast(prom, {
			pending: t("Contacts.DELETING", { count: selectedRows?.length }),
		});
		return prom;
	}

	return (
		<TableToolbar
			{...props}
			handleDelete={handleDelete}
			buttons={[
				{
					title: "delete",
					onClick: handleDelete,
				},
				{
					title: t("Contacts.EXPORT_TITLE"),
					icon: faUpload,
					value: t("Contacts.EXPORT"),
					popper: UsersExporter,
					className: "light-bg",
				},
			]}
		/>
	);
}
