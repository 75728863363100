import noAccent from "includes/no_accents";
import React from "react";
import { useQuery } from "react-query";
import TagLabel from "../Label/TagLabel";

export default function TagsList(props)
{
	const {items, onItemClick, title, color, selected, className, unstyled, reset} = props;
	const {sortBy, revSort} = props;

	if (!items?.length)
		return (false);

	function handleClick(item) {
		if (onItemClick)
			onItemClick(item);
	}

	function getList() {
		let ret = items;

		if (sortBy)
			ret = ret.sort((a, b) => {
				let a_el = a[sortBy];
				let b_el = b[sortBy];

				if (typeof a_el === "number" && typeof b_el === "number")
				{
					if (a_el < b_el)
						return ((revSort ? 1 : -1));
					return ((revSort ? -1 : 1));
				}
				if (noAccent(a[sortBy]).toLocaleLowerCase() < noAccent(b[sortBy]).toLocaleLowerCase())
					return ((revSort ? 1 : -1));
				return ((revSort ? -1 : 1));
			});
		return (ret);
	}

	const list = getList();
	return (
		<div
			className={"TagsList d-flex flex-wrap gap-1" + (className ? " " + className : "")}
		>
			{title &&
			<div className="w-100">{title}</div>}
			{
				onItemClick &&
					<TagLabel
						key={0}
						text="Tout"
						title="Tout afficher"
						selected={!selected?.length}
						color={(!selected?.length || selected.length === list.length) ? color : "#F2F2F4"}
						onClick={reset}
						unstyled={unstyled}
					/>
			}
			{
				list.map((item, index) => {
					let isSelected = true;
					if (selected && selected.length && selected.indexOf(item.id) === -1)
						isSelected = false;
					return (
						<TagLabel
							unstyled={unstyled}
							key={item.id + "-" + index}
							{...item}
							noClick={(onItemClick ? false : true)}
							selected={isSelected}
							color={(selected?.length && isSelected) || !selected?.length ? (color || item.color) : "#F2F2F4"}
							onClick={() => handleClick(item)}
						/>
					);
				})
			}
		</div>
	);
}

export function QueryTagsList(props) {
	const {isLoading, data, error} = useQuery(
		props.queryKey,
		props.queryFn,
		props.queryOptions || { retry: 0}
	);

	if (isLoading)
		return (
			<div className="loading">
				<div className="w-100"></div>
			</div>
		);
	if (error)
		return (false);

	let items = data;

	if (data?.length)
	{
		if (props.treat)
			items = data.map(a => props.treat(a)).filter(a => a);
		else
			items = data.map((a) => ({id: a.CategoryId, title: a.Description, text: a.Name, color: a.DefaultHexaColor}));
		items = items.sort((a, b) => (noAccent(a.text.toLowerCase()) < (noAccent(b.text.toLowerCase())) ? -1 : 1));
	}
	return ( <TagsList {...props} items={items}/> );
}
