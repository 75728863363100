import { dataURLtoFile } from "includes/documents_treat";
import formatDate from "includes/formatDate";
import genRequest from "includes/request";
import generateUUID from "includes/UUID";

class DocumentObject {
	#DocumentId = null;
	#Name = "TempDoc-" + generateUUID();
	#DocTypeId = null;
	#PublishedDate = formatDate();
	#LastChanged = formatDate();
	#LastChange_AuthorId = null;
	#DocumentRes = null;
	#ViewCount = 0;
	#Url_Min = null;
	#Url_Mobile = null;
	#Url_Origin = null;
	#IsTargetDoc = false;
	#IsShareable = true;

	#FileDocString = null;
	#File = null;
	#IsNew = null;
	#HasChanged = null;
	#CompanyId = null;
	#SiteId = null;
	#GroupId = null;
	#EmployeId = null;
	#PostId = null;
	#Notify = true;
	#LinkType = "sites";
	#LinkTo = [];
	#LinkedTo = [];
	#LinkedSites = [];
	#LinkedGroups = [];
	#Categories = [];
	#Account = false;

	#AddToDocsUtiles = false;

	constructor(props) {
		this.#IsNew = props.IsNew || false;
		this.#HasChanged = props.HasChanged || false;
		this.#DocumentId = props.DocumentId;
		this.#Name = props.Name ? props.Name : "";
		this.#DocTypeId = props.DocType || props.DocTypeId;
		this.#PublishedDate = props.PublishedDate || formatDate();
		this.#LastChanged = props.LastChanged || formatDate();
		this.#LastChange_AuthorId = props.LastChange_AuthorId;
		this.#DocumentRes = props.DocumentRes;
		this.#ViewCount = props.ViewCount ? props.viewCount : 0;
		this.#Url_Min = props.Url_Min;
		this.#Url_Mobile = props.Url_Mobile;
		this.#Url_Origin = props.Url_Origin;
		this.#IsTargetDoc = props.IsTargetDoc ? props.IsTargetDoc : false;
		this.#IsShareable =
			typeof props.IsShareable !== "undefined" ? props.IsShareable : true;
		this.#Categories = props.Categories;

		this.#LinkType = "sites";
		this.#CompanyId = props.CompanyId;
		this.#SiteId = props.SiteId;
		this.#GroupId = props.GroupId;
		this.#EmployeId = props.EmployeId;
		this.#PostId = props.PostId;
		this.#FileDocString = props.DocumentFile || props.FileDocString;
		this.#File = props.File;

		this.#AddToDocsUtiles = props.AddToDocsUtiles;

		if (!this.#DocumentId) this.TempId = generateUUID();
		this.#Notify = props.DocumentId ? false : true;
		this.#Account = props.account || false;
	}

	notify() {
		if (!this.#Notify) return Promise.resolve(true);
		let url = "PushNotification/DocumentNotify/" + this.#DocumentId;
		let data = null;
		if (this.#LinkType === "groups" && this.#LinkTo?.length) {
			url = "PushNotification/DocumentNotifyGroup";
			data = {
				Message: this.#Name,
				ListGroupId: this.#LinkTo,
				Author: this.#Account,
				NewsId: this.#DocumentId
			};
		}
		let req = genRequest(url, data, "post");
		return req;
	}

	fetchForEdit(file_content_type) {
		if (!this.#DocumentId) return Promise.resolve(true);
		let prom = Promise.allSettled([
			this.fetchFile(file_content_type),
			this.fetchLinkedGroups(),
			this.fetchLinkedSites(),
			this.fetchCategories(),
		]);
		return prom;
	}

	fetchDocUrl() {
		let req = genRequest("Documents/" + this.#DocumentId).then((resp) => {
			this.#Url_Min = resp.Url_Min;
			this.#Url_Mobile = resp.Url_Mobile;
			this.#Url_Origin = resp.Url_Origin;
		});
		return req;
	}

	fetchFile(fileType) {
		let that = this;
		let req = genRequest("Documents/DocFileOfDoc/" + this.#DocumentId).then(
			(resp) => {
				if (resp) {
					let file = dataURLtoFile(fileType + resp);
					that.#File = {
						data: that.#Url_Origin,
						name: that.#Name,
						size: file.size,
					};
				}
			}
		);
		return req;
	}

	send(index, onProgress) {
		let that = this;
		let prom = new Promise((resolve, reject) => {
			let xhr = that
				.delete()
				.then(() => {
					return that.sendDoc(index, onProgress);
				}, reject)
				.then(() => {
					return that.unlinkDoc();
				}, reject)
				.then(() => {
					if (that.#PostId) return that.linkToPost();
					return Promise.resolve(true);
				}, reject)
				.then(() => {
					return that.linkDoc();
				}, reject)
				.then(() => {
					return that.linkCategories();
				}, reject)
				.then(() => {
					resolve(that.values());
					return true;
				}, reject);
			return xhr;
		});
		return prom;
	}

	sendDoc(index, onProgress) {
		let that = this;
		let datas = this.values();
		if (!datas.Name || index)
			datas.Name =
				(typeof index !== "undefined" ? "pos:" + index + "_" : "") +
				this.#Name.replace(/[\W]{1,}/gi, "_") +
				"_" +
				formatDate(Date.now());
		if (datas.FileDocString && datas.FileDocString?.indexOf("data:") !== -1)
			datas.FileDocString = datas.FileDocString.split(",")[1];
		datas.DocumentTypeId = this.#DocTypeId;
		datas.Url_Min = null;
		datas.Url_Mobile = null;
		datas.Url_Origin = null;
		datas.PublishedDate = this.#PublishedDate;
		datas.LastChanged = formatDate();

		let url = this.#DocumentId
			? `Documents/${this.#DocumentId}`
			: "Documents/DocumentFileFromString";
		let req = genRequest(url, datas, this.#DocumentId ? "put" : "post", {
			onUploadProgress: onProgress,
		}).then((resp) => {
			if (!this.#DocumentId) that.#DocumentId = resp;
		});
		return req;
	}

	unlinkDoc() {
		let proms = [
			this.unlinkCompany(),
			this.unlinkGroups(),
			this.unlinkSites(),
		];
		return Promise.all(proms);
	}

	linkDoc() {
		if (this.#EmployeId) return this.linkToEmploye();
		if (this.#GroupId) return this.linkToGroup();
		if (this.#LinkType === "groups" && this.#LinkTo?.length)
			return this.linkToGroups();
		if (this.#LinkType === "sites" && this.#LinkTo?.length)
			return this.linkToSites();
		if (this.#PostId) return this.linkToPost();
		if (this.#SiteId) return this.linkToSite();
		return Promise.resolve();
	}

	fetchCategories() {
		if (!this.#DocumentId) return Promise.resolve([]);
		let that = this;
		let req = genRequest("Categories/Document/" + this.#DocumentId).then(
			(resp) => {
				if (resp?.length)
					that.#Categories = resp.map((a) => ({
						...a,
						label: a.Name,
						value: a.CategoryId,
					}));
				else that.#Categories = [];
				return resp;
			},
			() => {
				that.#Categories = [];
				return [];
			}
		);
		return req;
	}

	linkCategories() {
		let prom = new Promise((resolve, reject) => {
			this.unlinkCategories()
				.then(() => {
					if (!this.#Categories?.length) {
						resolve(true);
						return true;
					}
					let proms = this.#Categories.map((a) => {
						return this.linkCategory(a.CategoryId);
					});
					return Promise.all(proms);
				}, reject)
				.then(() => {
					resolve(true);
				}, reject);
		});
		return prom;
	}

	linkCategory(catId) {
		if (!catId) return Promise.reject(false);
		return genRequest("LtDocumentCategories", {
			CategoryId: catId,
			DocumentId: this.#DocumentId,
		});
	}

	unlinkCategories() {
		if (!this.#DocumentId) return Promise.resolve(true);
		let req = genRequest(
			"LtDocumentCategories/DocumentId/" + this.#DocumentId,
			null,
			"delete"
		);
		return req;
	}

	linkType(set_type) {
		if (set_type && set_type !== this.#LinkType) this.#LinkType = set_type;
		return this.#LinkType;
	}

	setLinkTo(items_list, link_type, default_site_id) {
		if (link_type) this.linkType(link_type);
		if (this.#LinkType === "sites") {
			this.#IsTargetDoc = false;
			if (default_site_id) this.#SiteId = default_site_id;
			this.#LinkTo = items_list;
		} else {
			this.#LinkTo = items_list;
			this.#IsTargetDoc = true;
		}
		return this.#LinkTo;
	}

	getLinksTo() {
		if (!(this.#LinkType && this.#LinkTo)) return false;
		return {
			type: this.#LinkType,
			links: this.#LinkTo,
			defaultSiteId: this.#SiteId,
		};
	}

	linkedTo = () => this.#LinkedTo;

	links() {
		if (this.#LinkedSites?.length && !this.#IsTargetDoc)
			return {
				type: "sites",
				links: this.#LinkedSites.map((a) => a.SiteId),
			};
		if (this.#LinkedGroups?.length)
			return {
				type: "groups",
				links: this.#LinkedGroups.map((a) => a.GroupId),
			};
		return { type: "sites", links: [] };
	}

	resetLinks(companyId, siteId, employeId, postId) {
		this.#CompanyId = companyId ? companyId : null;
		this.#SiteId = siteId ? siteId : null;
		this.#EmployeId = employeId ? employeId : null;
		this.#PostId = postId ? postId : null;
	}

	unlinkCompany() {
		if (!this.#DocumentId) return Promise.resolve(true);
		let req = genRequest(
			"LtDocumentCompanies/DocumentId/" + this.#DocumentId,
			null,
			"delete"
		);
		return req;
	}

	linkToCompany(companyId) {
		let id = this.#CompanyId;
		if (companyId) id = companyId;
		let xhr = genRequest(
			"LtDocumentCompanies",
			{
				DocumentId: this.#DocumentId,
				CompanyId: id,
			},
			"post"
		);
		return xhr;
	}

	fetchLinkedSites() {
		if (!this.#DocumentId) return Promise.resolve(true);
		let req = genRequest(
			"LtDocumentSite/DocumentId/" + this.#DocumentId
		).then(
			(resp) => {
				this.#LinkedSites = resp?.length ? resp : [];
				if (resp?.length) this.#LinkType = "sites";
			},
			() => {
				this.#LinkedSites = [];
			}
		);
		return req;
	}

	linkToSites() {
		if (!this.#LinkTo?.length) return Promise.resolve(true);
		let links = this.#LinkTo.map((a) => ({
			SiteId: a,
			DocumentId: this.#DocumentId,
		}));
		let req = genRequest("LtDocumentSite/MultiplePost", links, "post");
		return req;
	}

	linkToSite(siteId) {
		let id = this.#SiteId;
		if (siteId) id = siteId;
		let xhr = genRequest(
			"LtDocumentSite",
			{
				DocumentId: this.#DocumentId,
				SiteId: id,
			},
			"post"
		);
		return xhr;
	}

	unlinkSites() {
		if (!this.#DocumentId) return Promise.resolve(true);
		let req = genRequest(
			"LtDocumentSite/DocumentId/" + this.#DocumentId,
			null,
			"delete"
		);
		return req;
	}

	linkedSites = () => this.#LinkedSites;

	linkedGroups = () => this.#LinkedGroups;

	fetchLinkedGroups() {
		/* eslint-disable */
		if (!this.#DocumentId) return Promise.resolve(true);
		let url = "Groups/GroupOfDoc/" + this.#DocumentId;
		// "Lt_DocumentGroup/DocumentId/" + this.#DocumentId -> NEED API REQUEST
		let req = genRequest(url).then(
			(resp) => {
				this.#LinkedGroups = resp?.length ? resp : [];
				if (resp?.length) this.#LinkType = "groups";
			},
			() => {
				this.#LinkedGroups = [];
			}
		);
		return req;
		/* eslint-enable */
	}

	linkToGroups() {
		if (!this.#LinkTo?.length) return Promise.resolve(true);
		let links = this.#LinkTo.map((a) => ({
			GroupId: a,
			DocumentId: this.#DocumentId,
		}));
		let req = genRequest(
			"Lt_DocTargetedToGroup/MultiplePost",
			links,
			"post"
		);
		return req;
	}

	unlinkGroups() {
		if (!this.#DocumentId) return Promise.resolve(true);
		let req = genRequest(
			"Lt_DocTargetedToGroup/docId/" + this.#DocumentId,
			null,
			"delete"
		);
		return req;
	}

	linkToGroup(groupId) {
		let id = this.#GroupId;
		if (groupId) id = groupId;
		let xhr = genRequest(
			"Lt_DocumentGroup",
			{
				DocumentId: this.#DocumentId,
				GroupId: id,
			},
			"post"
		);
		return xhr;
	}

	linkToEmploye(employeId) {
		let id = this.#EmployeId;
		if (employeId) id = employeId;
		let xhr = genRequest(
			"LtDocumentEmployes",
			{
				DocumentId: this.#DocumentId,
				EmployeId: id,
			},
			"post"
		);
		return xhr;
	}

	linkToPost(postId) {
		let id = this.#PostId;
		if (postId) id = postId;
		let xhr = genRequest(
			"LtDocumentPosts",
			{
				DocumentId: this.#DocumentId,
				PostId: id,
			},
			"post"
		);
		return xhr;
	}

	delete(documentId, force) {
		let that = this;
		let id = documentId;
		if (!documentId) id = this.#DocumentId;
		if (
			!id ||
			(this.#DocumentId && !this.#IsNew && !this.#HasChanged && !force)
		)
			return Promise.resolve(true);
		let xhr = genRequest(
			"Documents/DocumentFileId=" + id + "AnddeleteExpenses=true",
			null,
			"delete"
		).then(() => {
			that.#DocumentId = null;
		});
		return xhr;
	}

	notification(setNotif) {
		if (typeof setNotif !== "undefined") this.#Notify = setNotif;
		return this.#Notify;
	}

	categories(setCategories) {
		if (typeof setCategories !== "undefined")
			this.#Categories = setCategories;
		return this.#Categories;
	}

	documentId(setDocId) {
		if (typeof setDocId !== "undefined") this.#DocumentId = setDocId;
		return this.#DocumentId;
	}

	documentFile(setFile) {
		if (typeof setFile !== "undefined") {
			this.#FileDocString = setFile;
			this.#HasChanged = true;
		}
		return this.#FileDocString;
	}

	file(setFile) {
		if (typeof setFile !== "undefined") this.#File = setFile;
		return this.#File;
	}

	name(setName) {
		if (typeof setName !== "undefined") this.#Name = setName;
		return this.#Name;
	}

	docType(setDocType) {
		if (typeof setDocType !== "undefined") this.#DocTypeId = setDocType;
		return this.#DocTypeId;
	}

	publishedDate(setPublishedDate) {
		if (typeof setPublishedDate !== "undefined")
			this.#PublishedDate = setPublishedDate;
		return this.#PublishedDate;
	}

	isDiffered = () => new Date() < new Date(this.#PublishedDate);

	isShareable = (setValue) => {
		if (typeof setValue !== "undefined") this.#IsShareable = setValue;
		return this.#IsShareable;
	};

	lastChanged(setLastChanged) {
		if (typeof setLastChanged !== "undefined")
			this.#LastChanged = setLastChanged;
		return this.#LastChanged;
	}

	lastChange_AuthorId(setLastChange_AuthorId) {
		if (typeof LastChange_AuthorId !== "undefined")
			this.#LastChange_AuthorId = setLastChange_AuthorId;
		return this.#LastChange_AuthorId;
	}

	documentRes(setDocumentRes) {
		if (typeof setDocumentRes !== "undefined")
			this.#DocumentRes = setDocumentRes;
		return this.#DocumentRes;
	}

	viewCount(setViewCount) {
		if (typeof setViewCount !== "undefined") this.#ViewCount = setViewCount;
		return this.#ViewCount;
	}

	urls(setUrls) {
		if (typeof setUrls !== "undefined") {
			this.#Url_Min = setUrls;
			this.#Url_Mobile = setUrls;
			this.#Url_Origin = setUrls;
		}
		return {
			Url_Min: this.#Url_Min,
			Url_Mobile: this.#Url_Mobile,
			Url_Origin: this.#Url_Origin,
		};
	}

	urlMin(setUrl_Min) {
		if (typeof setUrl_Min !== "undefined") this.#Url_Min = setUrl_Min;
		return this.#Url_Min;
	}

	urlMobile(setUrl_Mobile) {
		if (typeof setUrl_Mobile !== "undefined")
			this.#Url_Mobile = setUrl_Mobile;
		return this.#Url_Mobile;
	}

	urlOrigin(setUrl_Origin) {
		if (typeof setUrl_Origin !== "undefined")
			this.#Url_Origin = setUrl_Origin;
		return this.#Url_Origin;
	}

	isTargetDoc(setIsTargetDoc) {
		if (typeof setIsTargetDoc !== "undefined")
			this.#IsTargetDoc = setIsTargetDoc;
		return this.#IsTargetDoc;
	}

	companyId(setCompanyId) {
		if (typeof setCompanyId !== "undefined") this.#CompanyId = setCompanyId;
		return this.#CompanyId;
	}

	siteId(setSiteId) {
		if (typeof setSiteId !== "undefined") this.#SiteId = setSiteId;
		return this.#SiteId;
	}

	employeId(setEmployeId) {
		if (typeof setEmployeId !== "undefined") this.#EmployeId = setEmployeId;
		return this.#EmployeId;
	}

	postId(setPostId) {
		if (typeof setPostId !== "undefined") this.#PostId = setPostId;
		return this.#PostId;
	}

	isNew() {
		return this.#IsNew;
	}

	addToDocsUtiles = (setVal) => {
		if (typeof setVal !== "undefined") this.#AddToDocsUtiles = setVal;
		return this.#AddToDocsUtiles;
	};

	values = (no_temp) => ({
		DocumentId: no_temp
			? this.#DocumentId
			: this.#DocumentId || this.TempId,
		TempId: this.TempId,
		Name: this.#Name,
		DocTypeId: this.#DocTypeId,
		PublishedDate: this.#PublishedDate,
		LastChanged: this.#LastChanged,
		LastChange_AuthorId: this.#LastChange_AuthorId,
		DocumentRes: this.#DocumentRes,
		ViewCount: this.#ViewCount,
		Url_Min: this.#Url_Min,
		Url_Mobile: this.#Url_Mobile,
		Url_Origin: this.#Url_Origin,
		IsTargetDoc: this.#IsTargetDoc,
		IsShareable: this.#IsShareable,
		FileDocString: this.#FileDocString,
		CompanyId: this.#CompanyId,
		SiteId: this.#SiteId,
		EmployeId: this.#EmployeId,
		PostId: this.#PostId,
		IsNew: this.#IsNew,
		Categories: this.#Categories,
		AddToDocsUtiles: this.#AddToDocsUtiles,
	});

	typeof = () => "DocumentObject";
}

export default DocumentObject;
