import {
	FILES_URL
} from "./paths";

export default function documents_treat(docs, sortedBy, reverse_sort, props) {
	let encodeds = [];
	if (docs?.length) {
		encodeds = docs.map((a) => treat_document(a, props));
		if (sortedBy)
			encodeds = encodeds.sort((a, b) => {
				if (a[sortedBy] < b[sortedBy])
					return ((reverse_sort ? 1 : -1));
				return ((reverse_sort ? -1 : 1));
			});
	}
	return (encodeds);
}

export function treat_document(doc, props) {
	let ret = {
		...doc,
		Url_Min: (doc.Url_Min ? (FILES_URL + fixedEncodeURIComponent(doc.Url_Min)) : null),
		Url_Mobile: (doc.Url_Mobile ? (FILES_URL + fixedEncodeURIComponent(doc.Url_Mobile)) : null),
		Url_Origin: (doc.Url_Origin ? (FILES_URL + fixedEncodeURIComponent(doc.Url_Origin)) : null),
		...props
	};

	if (!doc.Url_Min)
		ret.Url_Min = (ret.Url_Mobile ? ret.Url_Mobile : ret.Url_Origin);
	if (!doc.Url_Mobile)
		ret.Url_Mobile = ret.Url_Origin;
	return (ret);
}

function fixedEncodeURIComponent(str) {
	return (
		encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
			return ("%" + c.charCodeAt(0).toString(16));
		})
	);
}

export function dataURLtoFile(dataurl, filename) {
	if (!dataurl)
		return (false);
	var arr = dataurl.split(",");
	var mime = arr[0].match(/:(.*?);/)[1];
	var bstr = atob(arr[1]);
	var n = bstr.length;
	var u8arr = new Uint8Array(n);

	while (n--)
		u8arr[n] = bstr.charCodeAt(n);
	return new File([u8arr], filename, {
		type: mime
	});
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh)
		return (bytes + " B");

	const units = si ?
		["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"] :
		["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
	return (bytes.toFixed(dp) + " " + units[u]);
}
