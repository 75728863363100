export const TypeRights = {
	AdminWFU: "AdminWFU",
	AdminWFY: "AdminWFY",
	AdminAppearance: "AdminAppearance",
	AdminCategories: "AdminCategories",
	AdminGroups: "AdminGroups",
	AdminSites: "AdminSites",
	AdminChart: "AdminChart",
	AdminDocument: "AdminDocument",
	AdminForms: "AdminForms",
	AdminArticle: "AdminArticle",
	AdminAlert: "AdminAlert",
	AdminAppFunctions: "AdminAppFunctions",
	AdminEvent: "AdminEvent",
	AdminNews: "AdminNews",
	AdminAds: "AdminAds",
	AdminProAds: "AdminProAds",
};

export const returnRightsFromName = (rights, name) =>
	rights && rights.filter((right) => right.Name === name).length > 0;
