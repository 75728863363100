import React, { useRef, useState } from "react";
import {useQueryClient} from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV} from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line
import { faFlag, faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import useAccount from "classes/Accounts/hooks/useAccount";
import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import genRequest from "includes/request";
import { t } from "i18next";
import Markdown from "markdown-to-jsx";
import Moment from "react-moment";
import "moment/locale/fr";
import "./Comments.css";
import LoadCont from "components/LoadCont/LoadCont";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import Button from "components/Button/Button";

export default function Comments(props) {
	const coms_cont_ref = useRef();
	const {items} = props;
	const [isEditing, setIsEditing] = useState(false);

	const handleComment = () => {
		if (props.onComment && props.showComments !== false)
		{
			if (props.onComment)
			{
				let req = props.onComment();
				if (req.then)
					req.then(scrollCont);
			}
			scrollCont();
		}
	};

	const handleModify = (com) => setIsEditing(com);

	const scrollCont = () => {
		if (coms_cont_ref?.current)
			coms_cont_ref.current.scrollTop = 0;
	};

	return (
		<div className={props.className}>
			{
				props.showComments &&
				<div className="comments-cont" ref={coms_cont_ref}>
					{
						(items?.length) ?
							items.map((com) => {
								return (<Comment key={com.CommentId} comment={com} {...com} handleModify={() => handleModify(com)}/>);
							})
							:
							<div>
								{ t("Posts.NO_COMMENT") }
							</div>
					}
				</div>
			}
			{
				props.canComment !== false &&
					<CommentSender
						{...props}
						isEditing={isEditing || false}
						closeEdit={() => setIsEditing(false)}
						comment={isEditing}
						onComment={handleComment}
					/>
			}
		</div>
	);
}

function Comment(props) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [tempDom, setTempDom] = useState(false);
	const [hasError, setHasError] = useState(false);

	return (
		<div
			className="comment"
			onMouseLeave={() => {
				if (isMenuOpen && !hasError)
					setIsMenuOpen(false);
			}}
		>
			{
				tempDom ?
					tempDom
					:
					<>
						<CommentHead menu={props.menu} comment={props.comment} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
						{
							(isMenuOpen && props.menu !== false) &&
								<CommentMenu comment={props.comment} handleModify={props.handleModify} setTempDom={setTempDom} setHasError={setHasError}/>
						}
						<Markdown>
							{props.CommentText.replace(/\n{1}/gi, "\n\n")}
						</Markdown>
					</>
			}

		</div>
	);
}

function CommentHead(props) {
	const {account, isAdmin} = useAccount();
	const {comment, setIsMenuOpen, isMenuOpen} = props;

	const isModified = () => {
		let pub = comment.PublishedDate.split(".")[0];
		let chng = comment.LastChanged.split(".")[0];
		return (chng > pub);
	};

	if (!comment)
		return (false);
	return (
		<div className="d-flex w-100 justify-content-between">
			<AuthorPreview
				employeId={comment.EmployeId}
				className="comment-list-author-preview mb-2"
				format={
					[
						"EmployeFirstName EmployeLastName",
						"WFU"
					]
				}
				modifiers={{
					EmployeLastName: (item) => item.toUpperCase()
				}}
			/>
			<div className="me-1 comment-date d-flex">
				{
					isModified()
					&&
					<div className="text-grey me-1">
						({t("Comments.MODIFIED")})
					</div>
				}
				<Moment
					locale={t("Code")}
					titleFormat="D MMM YYYY HH:mm"
					withTitle
					fromNow
				>
					{comment.LastChanged}
				</Moment>
				{
					props.menu !== false && (comment.EmployeId === account.EmployesId || isAdmin()) &&
						<div className="ms-1 cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)}>
							<FontAwesomeIcon icon={faEllipsisV}/>
						</div>
				}
			</div>
		</div>
	);
}

function CommentMenu(props) {
	const {account, isAdmin} = useAccount();
	const {comment, setHasError} = props;
	const [tempDom, setTempDom] = useState(false);
	const [errors, setErrors] = useState(false);
	const QCL = useQueryClient();

	const handleRemove = () => {
		setTempDom(<LoadCont text={t("Commons.DELETING")}/>);
		let req = genRequest("Comments/" + comment.CommentId, null, "delete").then(() => {
			QCL.refetchQueries(["Comments", "CommentOfPost", comment.PostId]);
		}, () => {
			setTempDom(false);
			setHasError(true);
			setErrors(t("Comments.DELETE_ERROR"));
		});
		return (req);
	};

	return (
		<div className="CommentMenu mb-2">
			{
				tempDom ?
					tempDom
					:
					<>
						<ErrorsCont
							errors={errors}
							className="mb-2"
							setErrors={() => {
								setHasError(false);
								setErrors(false);
							}}
						/>
						<div className="comment-menu-items">
							{
								comment.EmployeId === account.EmployesId &&
									<div onClick={props.handleModify} title={t("Comments.REPORT_COMMENT")}>
										<FontAwesomeIcon color={"#00AAAA"} className="me-1" icon={faEdit}/>
										{t("Commons.MODIFY")}
									</div>
							}
							{/* <div className="disabled" title={t("Comments.REPORT_COMMENT")}>
								<FontAwesomeIcon color={"#00AAAA"} className="me-1" icon={faFlag}/>
								{t("Commons.REPORT")}
							</div> */}
							{
								(comment.EmployeId === account.EmployesId || isAdmin()) &&
									<div onClick={handleRemove} title={t("Comments.DELETE_COMMENT")}>
										<FontAwesomeIcon color={"#DD0000"} className="me-1" icon={faTrashAlt}/>
										{t("Commons.DELETE")}
									</div>
							}
						</div>
					</>
			}
		</div>
	);
}

function CommentSender(props) {
	const {comment} = props;
	const input_ref = React.useRef();
	const [isSended, setIsSended] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [value, setValue] = useState(comment?.CommentText || "");
	const {account} = useAccount();
	const isEditing = React.useRef(false);

	React.useEffect(() => {
		if (isEditing?.current !== props.comment)
		{
			isEditing.current = props.comment;
			if (props.isEditing && props.comment)
			{
				setValue(props.comment?.CommentText);
				setIsSending(false);
				setIsSended(false);
			}
		}
	}, [isEditing, setValue, props]);

	function sendComment (e) {
		e.preventDefault();
		if (isSending || !value.length)
			return (false);
		setIsSending(true);
		let data = {
			EmployeId: account.EmployesId,
			PostId: props.postId,
			CommentTitle: "Post comment",
			CommentText: value
		};
		if (props.comment?.CommentId)
			data = {...props.comment, ...data};
		let req = genRequest(
			"Comments" + (data.CommentId ? "/" + data.CommentId : ""),
			data,
			(data.CommentId ? "put" : "post")
		).then((resp) => {
			setIsSended(resp);
			resetForm();
			if (props.onComment)
				props.onComment();
		}, () => {
			setIsSending(false);
			setIsSended(false);
		});
		return (req);
	}

	function resetForm() {
		setIsSending(false);
		setValue("");
		cancelEdit();
		if (input_ref?.current)
			input_ref.current.style.height = null;
	}

	function submitOnEnter(event){
		if (event.which === 13 && !event.shiftKey && props.submitOnEnter)
			sendComment(event);
	}

	function auto_grow(e) {
		let elem = e.target;

		elem.style.height = "35px";
		elem.style.height = elem.scrollHeight + "px";
	}

	function cancelEdit() {
		setValue("");
		if (props.closeEdit())
			props.closeEdit();
	}

	if (isSended && (!props.onComment || !props.showComments))
	{
		return (
			<div>
				<div className="mb-1">{t("Posts.RESP_SENDED")}</div>
				<Comment {...isSended}/>
			</div>
		);
	}

	let txt = props.commentBtnTxt ? props.commentBtnTxt : t("Posts.RESP_SEND");
	if (isSending)
		txt = t("Commons.SENDING");

	return (
		<div className="write-comment-cont pt-4">
			<div className="w-100">
				{
					(props.isEditing && props.comment) ?
						<div className="d-flex justify-content-between mb-1">
							<div>
								{t("Commons.MODIFY")}
							</div>
							<Button onClick={cancelEdit} className="w-auto btn-grey">{t("Commons.CANCEL")}</Button>
						</div>
						: t("Posts.REPLY")
				}
			</div>
			{
				props.isEditing && props.comment &&
					<Comment menu={false} comment={props.comment} {...props.comment}/>
			}
			<form className="d-flex align-items-start w-100 gap-2" onSubmit={sendComment}>
				<div className="w-100">
					<textarea
						ref={input_ref}
						disabled={isSending ? true : false}
						value={value}
						onChange={(e) => setValue(e.target.value)}
						className="w-100"
						placeholder={t("Posts.COMMENT_PH")}
						onKeyPressCapture={submitOnEnter}
						onChangeCapture={auto_grow}
					/>
				</div>
				<div>
					<Button type="submit" className="btn-primary send-btn" onClick={sendComment}>{txt}</Button>
				</div>
			</form>
		</div>
	);
}
