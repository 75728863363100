import React from "react";
import Moment from "react-moment";
import useAccount from "classes/Accounts/hooks/useAccount";
import formatDate from "includes/formatDate";
import genRequest from "includes/request";
import { t } from "i18next";
import FormsTableToolbar from "./FormsTableToolbar";
import { AsyncTable } from "components/Table/Table";

export default function FormsTable(props) {
	const { account, isAdmin } = useAccount();
	const {search, onClick, className} = props;
	let date = formatDate();
	let drafts = props.showDrafts || false;

	return (
		<div className="FormsTable">
			<AsyncTable
				companyId={account.CompanyId}
				rowIdKey={"FormId"}
				queryKey={["Forms", "FormAffectedToCompany", account.CompanyId, "Drafts=" + drafts]}
				queryFn={() => genRequest("Forms/FormAffectedToCompany/" + account.CompanyId)}
				options={{
					selection: isAdmin()
				}}
				search={search}
				onRowClick={(e, row) => onClick(row)}
				className={className}
				columns={[
					{
						field: "Title",
						title: t("Table.TITLE")
					},
					{
						field: "Txt",
						title: t("Table.DESC"),
						className: "form-desc-cell"
					},
					{
						title: t("Table.PUBLISHED_DATE"),
						field: "PublishedDate",
						render: (data) => <DateCell item={data} publishedDate={true}/>,
						defaultSort: "desc"
					},
					{
						title: t("Table.AVAILABLE_TO_DATE"),
						field: "AvailableTo",
						render: (data) => <DateCell item={data} availableTo={true}/>
					}
				]}
				overloads={{
					Toolbar: props.toolbar !== false ?
						(cprops, options) => (
							<FormsTableToolbar
								canDelete={props.canDelete}
								{...cprops}
								{...options}
							/>
						)
						: () => false
				}}
				filter={(a) => {
					if (props.hideSelected && props.selected)
					{
						if (props.selected.findIndex((f) => f.FormId === a.FormId) > -1)
							return (false);
					}
					if (drafts !== a.IsDraft)
						return (false);
					if (
						((!a.AvailableToDate || a.AvailableToDate >= date) && (a.PublishedDate <= date))
						|| isAdmin()
					)
						return (a);
					return (false);
				}}
				onSelectionChange={props.onSelectionChange}
			/>
		</div>
	);
}

function DateCell(props) {
	const {item} = props;
	let date = formatDate();

	if (props.availableTo && !item.AvailableToDate)
		return (false);
	return (
		<div className="d-flex align-items-center">
			{
				item.PriorityTo >= date &&
					<div className="me-1" title={t("Forms.AVAILABLE_TO_DATE", {date: formatDate(item.AvailableToDate, t("Commons.CUSTOM_FORMAT_DATE"))})}>
						<div className="dot priority-dot"/>
					</div>
			}
			<Moment format={t("Events.TILE_DATE_FORMAT")}>
				{props.publishedDate ? item.PublishedDate : item.AvailableToDate}
			</Moment>
		</div>
	);
}
