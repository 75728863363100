import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import { t } from "i18next";
import genRequest from "includes/request";
import "./Targets.css";
import TagLabel from "components/Tags/Label/TagLabel";
import noAccent from "includes/no_accents";
import useAccount from "classes/Accounts/hooks/useAccount";

const AlertTargetsCell = React.memo(({ item }) => {
	return (
		<InfosFetcher
			queryKey={["LtEmployeAlerts", "PostId", item.PostId]}
			queryFn={() => genRequest("LtEmployeAlerts/PostId/" + item.PostId)}
			treat={(linkeds) => {
				return t("Commons.TARGETS", {
					count: linkeds.length,
				}).toLowerCase();
			}}
		/>
	);
});
AlertTargetsCell.displayName = "AlertTargetsCell";

const TargetsCell = React.memo(({ item }) => {
	const { account } = useAccount();
	const { PostId, SiteId, IsTargetPost } = item;
	const [LtSites, setLtSites] = useState([]);
	const [LtGroups, setLtGroups] = useState([]);
	// Popper
	const [TM, setTM] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [referenceElement, setReferenceElement] = useState(null);
	const [SitesState, setSitesState] = useState({
		fetching: true,
		fetchingLt: true,
	});

	useEffect(() => {
		let isMounted = true;
		getDatas().then((datas) => {
			if (!isMounted) return;
			setLtSites(datas.post_sites);
			setLtGroups(datas.post_groups);
			setSitesState({
				fetching: false,
				fetchingLt: false,
			});
			setGroupsState({
				fetching: false,
				fetchingLt: false,
			});
		});
		return () => {
			isMounted = false;
			resetTm();
		};
	}, []);

	const [GroupsState, setGroupsState] = useState({
		fetching: true,
		fetchingLt: true,
	});

	const siteFormatText = (count, max) => {
		return t("Targets.published", {
			context: count === max ? "sites_all" : "sites",
			count,
		});
	};

	const groupFormatText = (count, max) => {
		return t("Targets.published", {
			context: count === max ? "groups_all" : "groups",
			count,
		});
	};

	const handleShowPopper = () => {
		resetTm(
			setTimeout(() => {
				setIsOpen(true);
			}, 200)
		);
	};

	const resetTm = (new_tm) => {
		clearTimeout(TM);
		setTM(new_tm ? new_tm : false);
	};

	const closePopper = () => {
		setIsOpen(false);
		resetTm();
	};

	const fetchSites = () =>
		genRequest("Sites/SitesOfCompany/" + account.CompanyId);

	const fetchPostSites = (sites_list) => {
		let req = genRequest("Sites/AllSiteOfPost/" + PostId).then(
			(resp) => {
				let ret = [];
				if (resp?.length) {
					ret = resp
						.map((a) => {
							if (sites_list?.length) {
								let site = sites_list.find(
									(s_a) => s_a.SiteId === a.SiteId
								);
								if (site !== -1) return site;
							}
							return false;
						})
						.filter((a) => a);
				}
				return ret;
			},
			() => {
				setLtSites(false);
				return false;
			}
		);
		return req;
	};

	const fetchGroups = () =>
		genRequest("Groups/OfCompany/" + account.CompanyId);

	const fetchDocGroups = () => {
		let req = genRequest("Groups/GroupOfPost/" + PostId).then(
			(resp) => {
				let ret = resp?.length ? resp : [];
				return ret;
			},
			() => {
				setLtGroups(false);
				return false;
			}
		);
		return req;
	};

	const getDatas = async () => {
		let sites = await fetchSites();
		let groups = await fetchGroups();
		let post_sites = await fetchPostSites(sites);
		let post_groups = await fetchDocGroups();
		return {
			sites,
			groups,
			post_sites,
			post_groups,
		};
	};

	return item.PostTypeId === 13 ? (
		<AlertTargetsCell item={item} />
	) : (
		<div ref={setReferenceElement}>
			<InfosFetcher
				queryFn={fetchSites}
				queryKey={["Sites", "SitesOfCompany", account.CompanyId]}
				options={{
					retry: 0,
					onSettled: () =>
						setSitesState({
							fetchingLt: !IsTargetPost,
							fetching: false,
						}),
				}}
				treat={(sites) => {
					return (
						<InfosFetcher
							queryFn={() => fetchPostSites(sites)}
							queryKey={["Sites", "AllSiteOfPost", PostId]}
							options={{
								retry: 0,
								enabled: !IsTargetPost,
								onSettled: () =>
									setSitesState({
										fetching: false,
										fetchingLt: false,
									}),
							}}
							treat={(resp) => {
								if (!resp?.length || IsTargetPost) return false;
								return (
									<div
										className="d-flex align-items-center"
										onMouseEnter={handleShowPopper}
										onMouseLeave={closePopper}
									>
										<div>
											{siteFormatText(
												resp?.length,
												sites.length
											)}
										</div>
										<TargetsPopper
											referenceElement={referenceElement}
											postId={PostId}
											type="sites"
											items={resp}
											isOpen={isOpen}
											closePopper={closePopper}
										/>
									</div>
								);
							}}
						/>
					);
				}}
			/>
			<InfosFetcher
				queryFn={fetchGroups}
				queryKey={["Groups", "OfCompany", account.CompanyId]}
				options={{
					retry: 0,
					onSettled: () =>
						setGroupsState({
							fetchingLt: IsTargetPost,
							fetching: false,
						}),
				}}
				treat={(groups) => {
					return (
						<InfosFetcher
							queryFn={fetchDocGroups}
							queryKey={["Groups", "FullGroupOfPost", PostId]}
							options={{
								retry: 0,
								onSettled: () =>
									setGroupsState({
										fetchingLt: false,
										fetching: false,
									}),
							}}
							treat={(resp) => {
								if (!resp?.length) return false;
								return (
									<div
										className="d-flex align-items-center"
										onMouseEnter={handleShowPopper}
										onMouseLeave={closePopper}
										ref={setReferenceElement}
									>
										<div>
											{groupFormatText(
												resp?.length,
												groups?.length
											)}
										</div>
										<TargetsPopper
											referenceElement={referenceElement}
											postId={PostId}
											type="groups"
											items={resp}
											isOpen={isOpen}
											closePopper={closePopper}
										/>
									</div>
								);
							}}
						/>
					);
				}}
			/>
			{!!GroupsState.fetching &&
				!GroupsState.fetchingLt &&
				!LtGroups?.length &&
				!SitesState.fetching &&
				!SitesState.fetchingLt &&
				!LtSites?.length && (
					<InfosFetcher
						queryFn={() => genRequest("Sites/" + SiteId)}
						queryKey={["Sites", SiteId]}
						treat={(resp) => {
							if (IsTargetPost) return "Aucune cible";
							return (
								resp.Name + (resp.Town ? " - " + resp.Town : "")
							);
						}}
					/>
				)}
		</div>
	);
});
TargetsCell.displayName = "TargetsCell";

function TargetsPopper(props) {
	const { type, isOpen } = props;
	const { items } = props;
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(
		props.referenceElement,
		popperElement,
		{
			placement: "right",
		}
	);

	const genDom = (data) => {
		if (type === "groups")
			return data
				.sort((a, b) => (a.Name < b.Name ? -1 : 1))
				.map((a) => (
					<TagLabel
						key={a.GroupId}
						text={a.Name}
						color={a.DefaultHexaColor}
					/>
				));
		if (data && !data?.length) data = [data];
		let dom = data
			.sort((a, b) => {
				let a_name = noAccent(
					(
						a.Name + (a.Town ? " - " + a.Town : "")
					).toLocaleLowerCase()
				);
				let b_name = noAccent(
					(
						b.Name + (b.Town ? " - " + b.Town : "")
					).toLocaleLowerCase()
				);
				return a_name < b_name ? -1 : 1;
			})
			.map((a) => {
				let name = a.Name + (a.Town ? " - " + a.Town : "");
				return <TagLabel key={a.SiteId} text={name} />;
			});
		return dom;
	};

	return (
		<>
			{isOpen && (
				<div
					className="targets-popper"
					ref={setPopperElement}
					onMouseLeave={props.closePopper}
					style={styles.popper}
					{...attributes.popper}
				>
					<div className="popper d-flex flex-wrap flex-row gap-1">
						{genDom(items)}
					</div>
				</div>
			)}
		</>
	);
}

export default TargetsCell;
