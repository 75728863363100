const env = "prod";
const SERV_URL =
	env !== "debug"
		? "https://debugjelloapi.eloapp.net/"
		: "https://debugjelloapi.eloapp.net/";

const FILES_URL = "https://stagingfiles.onlyall.fr/";
module.exports = {
	SERV_URL: SERV_URL,
	API_URL: SERV_URL + "api/",
	FILES_URL: FILES_URL,
	PICTOS_URL: FILES_URL + "Pictos/",
	PING_URL: FILES_URL + "ping.png",
};
