import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import Tabs from "components/Tabs/Tabs";
import { t } from "i18next";
import genRequest from "includes/request";
import AppTemplate from "classes/AppTemplate/AppTemplate";
import IdentityTab from "./components/IdentityTab/IdentityTab";
import LoadingImagesTab from "./components/LoadingImagesTab";
import DefaultImagesTab from "./components/DefaultImages";
import useAccount from "classes/Accounts/hooks/useAccount";
import useTemplate from "hooks/useTemplate";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import GalleryTab from "./components/GalleryTab/GalleryTab";
import "./AppearancePage.css";
import CustomPagesNames from "./components/CustomPagesNames/CustomPagesNames";
import AppFunctionsManager from "./components/AppFunctions/AppFunctionsManager";
import { isVisible } from "@syncfusion/ej2-base";
import { TypeRights, returnRightsFromName } from "includes/rightType";

export default function AppearancePage(props) {
	const { account, getSettings } = useAccount();
	const { template, setTemplate } = useTemplate();
	const [Templates, setTemplates] = useState([]);
	const qClient = useQueryClient();
	const { isMenuOpen, setIsMenuOpen } = props;
	const params = useLocation();
	const [tabs, setTabs] = useState([]);

	useEffect(() => {
		setTabs([
			{
				title: t("Appearance.LOGO_COLORS"),
				hash: "identity",
				child: IdentityTab,
				isVisible: true,
			},
			{
				title: t("Appearance.LOAD_IMAGES"),
				hash: "loadImages",
				child: LoadingImagesTab,
				isVisible: true,
			},
			{
				title: t("Appearance.GALLERY"),
				hash: "gallery",
				child: GalleryTab,
				isVisible: true,
			},
			{
				title: t("Appearance.DEFAULT_IMAGES"),
				hash: "defaultImages",
				child: DefaultImagesTab,
				isVisible: true,
			},
			{
				title: t("Appearance.APP_FUNCTIONS"),
				hash: "appFunctions",
				child: AppFunctionsManager,
				isVisible: returnRightsFromName(
					account.Rights,
					TypeRights.AdminAppFunctions
				),
			},
			{
				title: t("Appearance.MENUS"),
				hash: "menus",
				child: CustomPagesNames,
				isVisible: true,
			},
		]);
	}, [account]);

	const fetchSites = async () =>
		await genRequest("Sites/SitesOfCompany/" + account.CompanyId);

	function fetchTemplates() {
		let prom = new Promise((resolve, reject) => {
			fetchSites()
				.then((resp) => {
					let proms = resp.map((a) => retrieveTemplate(a.SiteId));
					return Promise.all(proms);
				}, reject)
				.then((resp) => {
					resolve(resp);
					return resp;
				}, reject);
		});
		return prom;
	}

	const forceRefresh = () => qClient.resetQueries(["Template"]);

	const retrieveTemplate = (siteId) =>
		genRequest("Apptemplates/TemplateOfSite/" + siteId);

	const getCurTabFromUrl = () => {
		let hash = params.hash.slice(1, params.hash.length);
		let index = tabs.findIndex((a) => a.hash.indexOf(hash) > -1);
		return index;
	};

	const { isLoading } = useQuery(["Templates"], fetchTemplates, {
		onSuccess: (resp) => {
			let templates = resp?.map((a) => new AppTemplate(a));
			setTemplates(templates);
		},
	});

	if (isLoading) return false;

	return (
		<div className="AppearancePage">
			<PageHeadBar
				siteSelect={false}
				search={false}
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
				title={props.title}
			/>
			<Tabs
				items={tabs}
				passProps={{
					template: template,
					setTemplate,
					forceRefresh,
					Templates,
					companyId: account.CompanyId,
					getSettings,
				}}
				activeIndex={getCurTabFromUrl()}
			/>
		</div>
	);
}
