import documents_treat, { dataURLtoFile } from "includes/documents_treat";
import genRequest from "includes/request";
import React, { useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { DragDropContext } from "react-beautiful-dnd";
import ImageEditor from "components/ImageEditor/ImageEditorComponent";
import "./ImagesSender.css";
import generateUUID from "includes/UUID";
import ImagesRow from "./components/ImagesRow/ImagesRow";
import Gallery from "./components/Gallery/Gallery";
import { t } from "i18next";
import useAccount from "classes/Accounts/hooks/useAccount";

export function ImageObject(img, noDocId) {
	let object = {};

	if ((!img.DocumentId || noDocId) && !img.TempId)
		object.TempId = generateUUID();
	Object.assign(object, img);
	if (noDocId) object.DocumentId = null;
	return object;
}

export function retrieveImageFile(documentId) {
	let prom = genRequest("Documents/DocFileOfDoc/" + documentId).then(
		(documentFile) => {
			return documentFile;
		}
	);
	return prom;
}

const move = (source, destination, droppableSource, droppableDestination) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);
	const result = {};

	removed.FromGallery = true;
	removed.IsNew = true;
	removed.TempId = generateUUID();
	destClone.splice(droppableDestination.index, 0, removed);
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;
	result["moved"] = removed;
	return result;
};

export default function ImagesSender(props) {
	const [Images, setSenderImages] = useState(props.images);
	const [CurImage, setCurImage] = useState();
	const [GalleryImages, setGalleryImages] = useState([]);
	const { account } = useAccount();
	const queryClient = useQueryClient();
	const cont_ref = useRef();
	const QCL = useQueryClient();

	function setImages(images) {
		if (props.setPostImages) props.setPostImages(images);
		setSenderImages(images);
	}

	function handleItemClick(item) {
		retrieveImageFile(item.DocumentId).then((documentFile) => {
			let res = "data:image/png;base64," + documentFile;
			let file_img = dataURLtoFile(res);
			let img = new ImageObject(
				{
					file: file_img,
					DocumentFile: res,
					...item,
					FromGallery: true,
					IsNew: true,
				},
				true
			);
			setCurImage(img);
			// setImages([...Images, img]);
		});
	}

	function onDragEnd(result) {
		const { source, destination } = result;

		if (!destination) return;

		if (source.droppableId === destination.droppableId) {
			let result = Array.from(Images);
			const [removed] = result.splice(source.index, 1);
			result.splice(destination.index, 0, removed);
			result = result.map((a, index) => {
				a.NewIndex = index;
				return a;
			});
			setImages(result);
		} else {
			let res = move(GalleryImages, Images, source, destination);
			let tmp_res = res["moved"];
			if (!getImage(tmp_res)) {
				// setImages(res["images-row"]);
				setGalleryImages(res["gallery-cont"]);
				QCL.refetchQueries([
					"Documents",
					"DocumentOfCompanyAndType",
					account.CompanyId,
					13,
				]);
				retrieveImageFile(res["moved"].DocumentId).then(
					(documentFile) => {
						let data_resp = "data:image/png;base64," + documentFile;
						let file_img = dataURLtoFile(data_resp);
						let img_obj = new ImageObject(
							{
								file: file_img,
								...tmp_res,
								DocumentFile: data_resp,
							},
							true
						);
						setCurImage(img_obj);
					}
				);
			}
		}
	}

	function getImage(img) {
		let ret = Images.find(
			(a) =>
				(a.DocumentId &&
					img.DocumentId &&
					a.DocumentId === img.DocumentId) ||
				(a.TempId && img.TempId && a.TempId === img.TempId)
		);
		return ret ? ret : false;
	}

	function handleEditorSave(img) {
		let images = Array.from(Images);
		if (!getImage(CurImage)) {
			let new_image = CurImage;
			new_image.DocumentFile = img;
			if (!CurImage.TempId) new_image.TempId = generateUUID();
			images.push(new_image);
			setImages(images);
			return true;
		}

		for (let x in images) {
			let tmp_img = images[x];
			if (
				(tmp_img.DocumentId &&
					CurImage.DocumentId &&
					tmp_img.DocumentId === CurImage.DocumentId) ||
				(tmp_img.TempId &&
					CurImage.TempId &&
					tmp_img.TempId === CurImage.TempId)
			) {
				if (!tmp_img.TempId) images[x].TempId = generateUUID();
				images[x].DocumentId = tmp_img.TempId;
				images[x].DocumentFile = img;
				images[x].HasChanged = true;
				break;
			}
		}
		setImages(images);
		if (props.onEditorClose) props.onEditorClose();
	}

	function handleDelete(img) {
		for (let x in Images) {
			let tmp_img = Images[x];
			if (
				(tmp_img.DocumentId &&
					img.DocumentId &&
					tmp_img.DocumentId === img.DocumentId) ||
				(tmp_img.TempId && img.TempId && tmp_img.TempId === img.TempId)
			) {
				let images = Array.from(Images);
				if (images[x].OriginalDocumentId) images[x].IsDeleted = true;
				else images.splice(x, 1);
				setImages(images);
				return true;
			}
		}
		return false;
	}

	const handleGalleryDelete = () =>
		queryClient.invalidateQueries([
			"Documents/DocumentOfCompanyAndType",
			account.CompanyId,
			13,
		]);

	const retrieveGalleryImages = async () =>
		genRequest(
			"Documents/DocumentOfCompanyAndType/" + account.CompanyId + "/13"
		).then((resp) => {
			let images = documents_treat(resp);
			setGalleryImages(images);
			return images;
		});

	const { isLoading, error } = useQuery(
		["Documents", "DocumentOfCompanyAndType", account.CompanyId, 13],
		retrieveGalleryImages
	);

	return (
		<div ref={cont_ref} className="h-100">

			<DragDropContext onDragEnd={onDragEnd} className="h-100">
				<div className="images-sender-cont">
					<div>{t("Gallery.IMAGES_ROW_TITLE")}</div>
					<ImagesRow
						rssImages={props.rssImages}
						images={Images}
						removeRssImage={props.removeRssImage}
						handleDelete={handleDelete}
						setCurImage={setCurImage}
					/>
					<div className="mt-2">{t("Gallery.GALLERY_TITLE")}</div>
					<Gallery
						req={{
							isLoading: isLoading,
							data: GalleryImages,
							error: error,
							queryKey: [
								"Documents",
								"DocumentOfCompanyAndType",
								account.CompanyId,
								13,
							],
						}}
						handleDelete={handleGalleryDelete}
						retrieve={retrieveGalleryImages}
						onItemClick={handleItemClick}
					/>
				</div>
			</DragDropContext>
			{CurImage && (
				<ImageEditor
					onMount={props.onEditorOpen}
					onUnmount={props.onEditorClose}
					ratio={14 / 11}
					onSave={handleEditorSave}
					onCancel={() => {
						setCurImage(false);
					}}
					contRef={cont_ref}
					file={CurImage.file}
					setCurFile={setCurImage}
					className={CurImage ? "d-block" : "d-none"}
				/>
			)}
		</div>
	);
}
