import genRequest from "includes/request";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { t } from "i18next";
import promiseToast from "components/Toasts/PromiseToast";
import Tabs from "components/Tabs/Tabs";
import useAccount from "classes/Accounts/hooks/useAccount";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import Button from "components/Button/Button";
import PostObject from "classes/Posts/PostObject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export default function TargetsPopper(props) {
	const { account } = useAccount();
	const { selected } = props;
	const QCL = useQueryClient();
	const [tabs, setTabs] = useState([]);

	const [isSending, setIsSending] = useState(false);
	const [Targets, setTargets] = useState({
		type: "sites",
		links: [],
	});

	const changeValue = (type, links) => {
		setTargets({
			type,
			links,
		});
	};

	useEffect(() => {
		setTabs([
			{
				title: t("Commons.PUBLISH_BY_SITES"),
				child: () => (
					<PublishBySites
						onSelect={(links) => changeValue("sites", links)}
					/>
				),
				isVisible: true,
			},
			{
				title: t("Groups.PUBLISH_BY_GROUPS"),
				child: () => (
					<PublishByGroups
						onSelect={(links) => changeValue("groups", links)}
					/>
				),
				isVisible: true,
			},
		]);
	}, [account]);

	const treat = () => {
		setIsSending(true);
		let proms = selected.map((post, index) => {
			let postObject = new PostObject(post);
			postObject.setLinkTo(
				Targets.links,
				Targets.type,
				Targets.links.indexOf(account.SiteId) !== -1
					? account.SiteId
					: false
			);

			let prom = new Promise((resolve, reject) => {
				setTimeout(() => {
					Promise.allSettled([
						postObject.unlinkGroups(),
						postObject.unlinkSites(),
					])
						.then(() => {
							return postObject.sendPost();
						})
						.then(() => {
							if (Targets.type === "sites")
								return postObject.duplicateOnSites();
							return postObject.duplicateOnGroups();
						})
						.then(resolve, reject);
				}, index * 500);
			});
			return prom;
		});
		let mainProm = Promise.allSettled(proms).then((resp) => {
			setTimeout(() => {
				setIsSending(false);
				QCL.resetQueries(["RetrievePosts"]);
				props.handleClose();
			}, 1000);
		});
		promiseToast(mainProm);
	};

	return (
		<div>
			<div className="bg-color p-2 radius-2 mb-4">
				<div
					className="d-flex gap-2 align-items-center"
					style={{ fontSize: "2rem" }}
				>
					<FontAwesomeIcon icon={faWarning} color="orange" />
					<div>FONCTIONALITÉE EXPERIMENTALE</div>
				</div>
				<div>Accessible seulement pour les EloAdmin</div>
				<div>
					Trés groumant en ressources serveur, éviter de traiter trop
					de publications en une foi
				</div>
				<div>
					Peut prendre plus de 10 à 20 minutes (environ 20 secondes
					par publication)
				</div>
				<h5>
					!!! Ne pas toucher a l'ordinateur pendant le traitement !!!
				</h5>
			</div>
			<h5>{props.selected?.length} publications sélectionnées</h5>
			<div className="inner-step-cont">
				<Tabs items={tabs} />
			</div>
			<div className="d-flex gap-2">
				<Button
					className="btn-red"
					disabled={isSending}
					onClick={props.handleClose}
				>
					Annuler
				</Button>
				<Button
					className="btn-green"
					disabled={isSending}
					onClick={treat}
				>
					Valider
				</Button>
			</div>
		</div>
	);
}

function PublishBySites({ onSelect }) {
	const { account } = useAccount();

	function sitesSelect(values) {
		let siteIds = values.map((a) => a.value);
		onSelect(siteIds);
	}

	return (
		<div className="sites-drop-cont">
			<QuerySelect
				placeholder={t("Commons.SELECT_SITES")}
				className={"bg-color w-100 rounded"}
				classNamePrefix="dropdown"
				queryKey={["Sites", "SitesOfCompany", account.CompanyId]}
				queryFn={() =>
					genRequest("Sites/SitesOfCompany/" + account.CompanyId)
				}
				treat={(site) => ({
					value: site.SiteId,
					label: site.Name + " - " + site.Town,
				})}
				all={{
					label: t("Commons.ALL_SITES"),
					value: "ALL",
				}}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				onSelect={sitesSelect}
			/>
		</div>
	);
}

function PublishByGroups({ onSelect }) {
	const { account } = useAccount();

	function groupsSelect(values) {
		onSelect(values.map((a) => a.value));
	}

	return (
		<div className="groups-drop-cont">
			<QuerySelect
				placeholder={t("Groups.SELECT_GROUPS")}
				className={"bg-color w-100 rounded"}
				classNamePrefix="dropdown"
				queryKey={["Groups", "OfCompany", account.CompanyId]}
				queryFn={() =>
					genRequest("Groups/OfCompany/" + account.CompanyId)
				}
				treat={(cat) => ({
					value: cat.GroupId,
					label: cat.Name,
				})}
				all={{
					label: t("Groups.ALL_GROUPS"),
					value: "ALL",
				}}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				onSelect={groupsSelect}
			/>
		</div>
	);
}
