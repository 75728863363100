export const fr_FR = {
	Code: "fr-FR",
	CodeMin: "fr",

	Server: {
		CONNECTING: "Connexion au serveur",
		PING_ERROR: "Erreur de connexion au serveur",
		TIMEOUT: "Le serveur a mis trop de temps à répondre",
		RETRY_LATER:
			"Veuillez vérifier votre connexion internet, ou re-essayer ultérieurement, merci",
	},

	Desktop: {
		LOAD_ERROR: "Une erreur est survenue lors du chargement du Desktop",
		ERROR: "Erreur",
		ERROR_404: "Erreur 404",
		PAGE_NOT_FOUND: "La page demandée n'existe pas",
		UNAUTHORIZED:
			"Vous ne pouvez pas acceder à cette page, veuillez contacter un administrateur",
		ALLOW_COOKIES: "Acceptez les cookies de",
		TO_SHOW_CONTENT: "pour voir le contenu",
		ACCEPT: "Accepter",
		RELOAD_PAGE: "Recharger la page",
	},

	Create: {
		ENTER_INFOS: "Entrez vos informations",
		CHOOSE_PASS: "Choisissez un mot de passe",
		PASSWORD: "Mot de passe",
		PASS_CONFIRM: "Confirmation du mot de passe",
		NETWORK_NAME: "Nom du réseau",
		NETWORK_LOGIN: "Identifiant du réseau",
		LOGIN_EXAMPLE: "Exemple d'identifiant",
		LOGO: "Logo (facultatif)",
		ACCEPT: "Accepter les",
		CGU: "conditions générales d'utilisation",
		BACK_TO_LOGIN: "Retourner sur la page de connexion",
		ERROR_NO_FIRSTNAME: "Veuillez entrer votre prénom",
		ERROR_NO_LASTNAME: "Veuillez entrer votre nom",
		ERROR_NO_MAIL: "Veuillez entrer une adresse mail valide",
		ERROR_NO_PSW_MATCH: "Les mots de passe ne correspondent pas",
		ERROR_CGU_NOT_ACCEPTED:
			"Veuillez accepter les conditions générales d'utilisation",
		ERROR_NAME_ALREADY_IN_USE:
			"Le nom de la compagnie est déjà pris, veuillez en choisir un autre",
	},

	Commons: {
		WELCOME: "Bienvenue",
		ERROR: "Une erreur est survenue",
		PAGE_LOAD: "Chargement de la page",
		LOADING: "Chargement ...",
		OPTIONS: "Options",
		IDENTITY: "Identité",
		PUBLISH_OPTIONS: "Options de publication",
		DEFERRED_PUBLISH: "Publication différée",
		DEFERRED: "différé",
		DEFERRED_TO: "Différée au",
		PRIORITY_PUBLISH: "Publication prioritaire",
		PRIORITY_UNTIL: "Prioritaire jusqu'au",
		SEND_STEP: "Envoi",
		END_STEP: "Terminé",
		SENDING: "Envoi ...",
		CLOSE: "Fermer",
		BACK: "Retour",
		ADD: "Ajouter",
		CREATE: "Créer",
		APPLY: "Appliquer",
		CANCEL: "Annuler",
		CONFIRM: "Confirmer",
		SAVE: "Enregistrer",
		IMPORT: "Importer",
		DROP: "Glissez/déposez vos fichiers ici",
		MODIFY: "Modifier",
		MODIFIED: "Modifié",
		DUPLICATE: "Dupliquer",
		REJECT: "Refuser",
		PUBLISH: "Publier",
		SELECTED: "Sélectionné",
		DELETE: "Supprimer",
		DELETING: "Suppression",
		ITEM: "élément",
		ITEMS: "éléments",
		ITEMS_one: "{{count}} élément",
		ITEMS_other: "{{count}} éléments",
		SEARCH: "Rechercher",
		ALL_SITES: "Tous les sites",
		ALL_CATEGORIES: "Toutes les catégories",
		CATEGORIES: "Catégories",
		SELECT_SITES: "Sélectionnez un ou des sites",
		SELECT_CATEGORIES: "Sélectionnez une ou des catégories",
		PUBLISH_BY_SITES: "Publier par sites",
		FAVORITES: "Favoris",
		FAVORITE: "Favori",
		ADD_FAVORITE: "Ajouter aux favoris",
		DEL_FAVORITE: "Supprimer des favoris",
		AT: "à",
		THE: "Le",
		FROM: "Du",
		TO: "Au",
		LINK: "Lien",
		LINKS: "Liens",
		COPY_LINK: "Copier le lien",
		LINK_COPIED_TO_CLIPBOARD: "Lien copié dans le presse papier",
		DATA_COPIED_TO_CLIPBOARD: "Données copiées dans le presse papier",
		SEND_BY_MAIL: "Envoyer par mail",
		NOTIFY_USERS: "Notifier les utilisateurs",
		NOTIFICATION: "Notification",
		SENDING_NOTIF: "Envoi de la notification",
		CHANGE_DISP: "Changer l'affichage",
		NAME_ERROR: "L'élément doit avoir un nom",
		PREVIEW_LOAD: "Chargement de la prévisualisation",
		NEW: "New",
		TARGETS: "Cibles",
		TARGETS_zero: "Aucune cible",
		TARGETS_one: "{{count}} Cible",
		TARGETS_other: "{{count}} Cibles",
		REPORT_BTN: "Signaler un problème",
		REPORT: "Signaler",
		USER: "Utilisateur",
		ADMIN: "Admin",
		DRAFTS: "Brouillons",
		SHOW_DRAFTS: "Afficher les brouillons",
		USE_AS_TEMPLATE: "Utiliser comme modèle",
		LIST_END: "Fin de la liste",
		NO_RESULT: "Aucun résultat",
		NO_RESULT_FOR: "Aucun résultat pour",
		FILTERS: "Filtres",
		LAST_CHANGED: "Dernière modification",
		ASK_SAVE: "Voulez vous enregistrer les modifications ?",
		ASK_CONTINUE: "Voulez vous continuer ?",
		ASKDELETE_one:
			'L\'élément "{{name}}" sera supprimé. $t(Commons.ASK_CONTINUE)',
		ASKDELETE_other:
			"{{count}} éléments seront supprimés. $t(Commons.ASK_CONTINUE)",
		DELETING_one: "Suppression de l'élément",
		DELETING_other: "Suppression des éléments",
		CUSTOM_FORMAT_DATE: "D-M-Y",
		SHARE: "Partager",
	},

	Targets: {
		PUBLISHED: "Publié",
		published_sites_one: "$t(Targets.PUBLISHED) sur {{count}} site",
		published_sites_other: "$t(Targets.PUBLISHED) sur {{count}} sites",
		published_sites_all: "$t(Targets.PUBLISHED) sur tous les sites",

		published_groups_one: "$t(Targets.PUBLISHED) pour {{count}} groupe",
		published_groups_other: "$t(Targets.PUBLISHED) pour {{count}} groupes",
		published_groups_all: "$t(Targets.PUBLISHED) pour tous les groupes",
	},

	Joyride: {
		CLOSE: "Fermer",
		BACK: "Précédent",
		NEXT: "Suivant",
		LAST: "Fin",
		OPEN: "Ouvrir",
		SKIP: "Passer",
	},

	HelperTourEditor: {
		STEPS_one: "{{count}} Étape",
		STEPS_other: "{{count}} Étapes",
	},

	Editing: {
		UNSAVED_WARN:
			"ATTENTION, les changements non enregistrés seront perdus",
		NO_SAVE: "Fermer sans enregistrer",
		DRAFT_SAVE: "Enregistrer en brouillon",
		CONTINUE: "Continuer l'édition",
	},

	Toasts: {
		PROM_PENDING: "Envoi des informations 🚀",
		PROM_SUCCESS: "Opération effectuée 👌",
		PROM_ERROR: "Une erreur est survenue 🤯",
	},

	Reporting: {
		SEND_REPORT: "Envoyer un rapport ?",
		REPORTING: "Envoi du rapport d'erreur 🚀",
		REPORT_SUCCESS: "Rapport d'erreur envoyé 👌",
		REPORT_ERROR:
			"Une erreur est survenue lors de l'envoi du rapport d'erreur 🤯",
	},

	Table: {
		NAME: "Nom",
		TITLE: "Titre",
		TEXT: "Text",
		DATE: "Date",
		PUBLISHED_DATE: "Date de publication",
		AVAILABLE_TO_DATE: "Date limite",
		PREVIEW: "Aperçu",
		DESC: "Description",
		COLOR: "Couleur",
		TYPE: "Type",
		AUTHOR: "Auteur",
		NO_CONTENT: "Aucun élément a afficher",
		END_OF_TABLE: "Fin du tableau",
		ADD_COL: "Ajouter une colonne",
		ADD_ROW: "Ajouter une ligne",
		CLEAN: "Vider",
		CLEAN_ROW: "Vider la ligne",
		CLEAN_COL: "Vider la colonne",
		BEFORE: "Avant",
		AFTER: "After",
		DELETE: "Supprimer",
		DELETE_ROW: "Supprimer la ligne",
		DELETE_COL: "Supprimer la colonne",
	},

	MaterialTable: {
		body: {
			emptyDataSourceMessage: "Aucun élement à afficher",
			addTooltip: "Ajouter",
			deleteTooltip: "Supprimer",
			editTooltip: "Editer",
			filterRow: {
				filterTooltip: "Filtrer",
			},
			editRow: {
				deleteText: "Voulez-vous supprimer cette ligne?",
				cancelTooltip: "Annuler",
				saveTooltip: "Enregistrer",
			},
		},
		grouping: {
			placeholder: "Tirer l'entête ...",
			groupedBy: "Grouper par:",
		},
		header: {
			actions: "Actions",
		},
		pagination: {
			labelDisplayedRows: "{from}-{to} de {count}",
			labelRowsSelect: "lignes",
			labelRowsPerPage: "lignes par page:",
			firstAriaLabel: "Première page",
			firstTooltip: "Première page",
			previousAriaLabel: "Page précédente",
			previousTooltip: "Page précédente",
			nextAriaLabel: "Page suivante",
			nextTooltip: "Page suivante",
			lastAriaLabel: "Dernière page",
			lastTooltip: "Dernière page",
		},
		toolbar: {
			addRemoveColumns: "Ajouter ou supprimer des colonnes",
			nRowsSelected: "{0} ligne(s) sélectionée(s)",
			showColumnsTitle: "Voir les colonnes",
			showColumnsAriaLabel: "Voir les colonnes",
			exportTitle: "Exporter",
			exportAriaLabel: "Exporter",
			exportName: "Exporter en CSV",
			searchTooltip: "Chercher",
			searchPlaceholder: "Chercher",
		},
	},

	Categories: {
		TYPE: "Type de catégorie",
		CATEGORIES: "Catégories",
		ADD_CATEGORY: "Ajouter une catégorie",
		MODIFY_CATEGORY: "Modifier une catégorie",
		DUP: "Dupliquer",
		DUP_CATEGORY: "Copier vers type",
		DUP_FOR: "Dupliquer pour les modules suivants",
		DUPLICATION: "Duplication des catégories",
		DUPLICATING: "Duplication des catégories",
		"TYPE-ALL": "Toutes les catégories",
		"TYPE-post-1": "News",
		"TYPE-post-3": "Articles",
		"TYPE-post-4": "Annonces",
		"TYPE-post-13": "Alertes",
		"TYPE-post-14": "Annonces Pro",
		"TYPE-post-5": "Carrières",
		"TYPE-post-8": "Évènements",
		"TYPE-documents": "Documents",
		"TYPE-charts": "Indicateurs",
		ASKDELETE_one:
			'La catégorie "{{name}}" du type "{{type}}", sera supprimée. $t(Commons.ASK_CONTINUE)',
		ASKDELETE_other:
			"{{count}} catégories, seront supprimées. $t(Commons.ASK_CONTINUE)",
	},

	Groups: {
		GROUPS: "Groupes",
		ALL_GROUPS: "Tous les groupes",
		SELECT_GROUPS: "Sélectionnez un ou des groupes",
		PUBLISH_BY_GROUPS: "Publier par groupes",
		ADD_GROUP: "Ajouter un groupe",
		MODIFY_GROUP: "Modifier un groupe",
		IDENTITY_STEP: "Identité",
		MEMBERS: "Membres",
		USER_IN_GROUP: "utilisateur dans le groupe",
		USERS_IN_GROUP: "utilisateurs dans le groupe",
		NAME_ERROR: "Veuillez entrer un nom pour le groupe",
		DUP_POSTS:
			"Assigner au nouveau groupe les publications existantes qui sont reliées aux groupes suivants",
		SEND_GROUP: "Envoi du groupe",
		LOADING_USERS: "Chargement des utilisateurs",
		NO_USERS: "Aucun utilisateur",
		ASKDELETE_one:
			'Le groupe "{{name}}" sera supprimé. $t(Commons.ASK_CONTINUE)',
		ASKDELETE_other:
			"{{count}} groupes seront supprimés. $t(Commons.ASK_CONTINUE)",
		GROUP_one: "{{count}} groupe",
		GROUP_other: "{{count}} groupes",
	},

	WorkFamillies: {
		WORK_FAMILLIES: "Familles",
		ADD_FAM: "Ajouter une famille",
		MODIFY_FAM: "Modifier une famille",
		SELECT_FAM: "Sélectionnez une famille",
		ALL_FAMS: "Toutes les familles",
	},

	WorkFunctions: {
		WORK_FUNCTIONS: "Fonctions",
		ADD_FUNC: "Ajouter une fonction",
		MODIFY_FUNC: "Modifier une fonction",
		SELECT_FUNC: "Sélectionnez une fonction",
		ALL_FUNCTIONS: "Toutes les fonctions",
	},

	Images: {
		IMAGES_LOAD: "Chargement des images",
		IMAGE_LOAD: "Chargement de l'image",
		IMAGE_SEND: "Envoi de l'image",
		IMAGES_SEND: "Envoi des images",
		IMAGE_OVERLAY: "Cliquez ou déposez ici pour charger une image",
		MODIFY_IMAGE_OVERLAY:
			"Cliquez, ou glissez une image ici, pour modifier l'image actuelle",
		IMAGE_SIZE_ERROR: "La taille de l'image",
		IMAGE_SIZE_ERROR_2: "ne doit pas dépasser",
		ADD_IMAGE: "Ajouter une image",
		RM_IMAGE: "Supprimer l'image",
		NO_IMAGE: "Aucune image",
		RECOMMENDED_SIZE: "Taille recommandée: {{width}} x {{height}} pixels",
	},

	Files: {
		FILE: "Fichier (PDF, 20 Mo max)",
		FILES: "Fichiers",
		FILE_NAME: "Nom",
		FILES_LOAD: "Chargement des fichiers",
		FILE_LOAD: "Chargement du fichier",
		FILE_SEND: "Envoi du fichier",
		FILES_SEND: "Envoi des fichiers",
		FILE_PH: "Cliquez pour choisir un fichier PDF ou glissez le ici",
		FILE_SIZE_ERROR: "La taille du fichier",
		FILE_SIZE_ERROR_2: "ne doit pas dépasser",
		ADD_FILE: "Ajouter un fichier",
		RM_FILE: "Supprimer le fichier",
		NO_FILE: "Aucun fichier",
		IMPORT_FROM_PC: "Importer depuis votre appareil",
		SELECT_FROM_GALLERY: "Sélectionner depuis la gallerie",
		SET_NAME: "Veuillez entrer un nom pour le fichier",
		SELECT_FILE: "Veuillez selectionner un fichier",
	},

	AppFunctions: {
		FUNCTIONS: "Fonctionnalités",
		MSG: "Les fonctionnalités désactivées seront toujours visibles par les administrateurs",
		COMMENTS: "Commentaires des publications",
		ContactPage: "Contacts",
		CorporatePage_Articles: "A propos",
		CorporatePage_Charts: "Chiffres clés",
		CorporatePage_DocsUtils: "Documents utiles",
		DocumentsPage_AllowSharing: "Autoriser le partage de document",
		PeoplePage_Agenda: "Agenda",
		PeoplePage_Career: "Carrières",
		PeoplePage_PostAd: "Publier des annonces perso",
		PeoplePage_PostProAd: "Publier des annonces pro",
		PeoplePage_ViewAd: "Annonces",
		PeoplePage_Rss: "Flux externe",
		PeoplePage_ViewProAd: "Annonces Pro",
		ProfilePage_PersonalDocumentPage_MyAd: "Mes annonces",
		ProfilePage_PersonalDocumentPage_MyNews: "Mes news",
		ProfilePage_PersonalDocumentPage_PaySlip: "Mes documents",
		ProfilePage_PersonalDocumentPage_SavedNews: "Publications sauvegardés",
		ProfilePage_PersonalProfilPage_CanChangePassword:
			"Modification du mot de passe",
		ProfilePage_PersonalProfilPage_CanChangeProfil:
			"Modification du profil",
		AnimationsPage_CanParticipate: "Participer aux événements de l'agenda",
		NewsPage_PostQueueStyle: "Modération des news",
		NewsPage_Comments: "$t(AppFunctions.COMMENTS)",
		OffersPage_PostQueueStyle: "Modération des annonces",
		ProOffers_PostQueueStyle: "Modération des annonces pro",
		SitesPage: "Page des sites",
		PostsPage: "Publications",
		PostsPage_CanUseMarkdown:
			"Editeur de texte enrichit pour la rédaction (gras, italique, etc...)",
		Forms: "Enquêtes",
	},

	Login: {
		EMPTY_FORM: "Veuillez entrer un identifiant et un mot de passe",
		LOGIN_PH: "Identifiant",
		PASS_PH: "Mot de passe",
		SAVE_PASS: "Enregistrer le mot de passe",
		CONNECT: "Se connecter",
		CONNECTING: "Connexion ...",
		FORGOTTEN_IDS: "Identifiants oubliés ?",
		BACK_HOME: "Retour à la page de connexion",
		FILL_FIELDS: "Veuillez remplir les champs suivant",
		MAIL: "Adresse mail",
		MAIL_MSG:
			"Veuillez renseigner votre adresse mail pour demander de nouveaux identifiants",
		NAME_PH: "Entrez votre nom",
		FNAME_PH: "Entrez votre prénom",
		RESET_LOGIN_PH: "Entrez votre votre adresse mail",
		COMP_PH: "Entrez le nom de votre entreprise ou réseau",
		SEND_OK: "Votre demande a bien été prise en compte!",
		SEND_OK2: "Vous obtiendrez un nouveau mot de passe très prochainement",
		ERROR_1: "Veuillez entrer votre nom",
		ERROR_2: "Veuillez entrer votre prénom",
		ERROR_3: "Veuillez entrer votre identifiant ou adresse mail",
		ERROR_4: "Veuillez entrer le nom de votre entreprise ou réseau",
		MAIL_ERROR_1: "Aucun compte ne correspond à l'adresse mail renseignée.",

		// ForgetForm
		FORGET_ASK_NEW_MSG: "Demander de nouveaux identifiants",
		FORGET_THIS_ACCOUNT_BTN: "Demander pour ce compte",
		FORGET_CHANGE_ACCOUNT_BTN: "Demander pour un autre compte",

		// API ERRORS
		invalid_grant:
			"L'identifiant ou le mot de passe ne correspond à aucun compte",

		// Server Error
		ECONNABORTED:
			"Le serveur a mis trop de temps a répondre. Veuillez vérifier votre connexion internet puis re-essayer",
		SERV_ERROR_1: "Une erreur est survenue lors de l'envoi de la demande.",
		SERV_ERROR_2:
			"Veuillez vérifier votre connexion internet puis re-essayer.",
		SERV_ERROR_3: "Contacter un administrateur si le problème persiste.",
	},

	Accounts: {
		ADD_ACCOUNT: "Ajouter un compte",
		DISCONNECT: "Déconnexion",
		DISCONNECTING: "Déconnexion en cours",
	},

	Pages: {
		COMPANIES: "Entreprises",
		COMPANY: "Compagnie",
		APPEARANCE: "Apparence",
		HOME: "Accueil",
		MY_SPACE: "Mon OnlyAll",
		MY_SETTINGS: "Mes réglages",
		CORPORATE: "Entreprise",
		PEOPLES: "People",
		AGENDA: "Agenda",
		ARTICLES: "A propos",
		CAREERS: "Carrières",
		CHARTS: "Indicateurs",
		CONTACTS: "Contacts",
		DOCUMENTS: "Documents",
		FORMS: "Enquêtes",
		NEWS: "News",
		OFFERS: "Annonces",
		PRO_OFFERS: "Annonces Pro",
		ALERTS: "Alertes",
		CONFIGURATION: "Configuration",
		SITES: "Sites",
		PROFESSIONS: "Métiers",
		GROUPS: "Groupes",
		CATEGORIES: "Catégories",
		ANALYTICS: "Statistiques",
		REACTIONS: "Reactions",
		PROFILE: "Profil",
		RSS: "Rss",
	},

	Theme: {
		DARK_THEME: "Thème sombre",
		LIGHT_THEME: "Thème clair",
	},

	Settings: {
		LANGUAGE: "Langue",
		BUTTONS_STYLE: "Style des boutons",
		MENU_STYLE: "Style du menu",
		CUSTOM_COLOR: "Couleur de l'interface",
		DEFAULT: "Par défaut",
		RESET: "Réinitialiser",
		CLASSIC: "Classique",
		ROUNDED: "Arrondis",
		EXAMPLE: "Exemple",
		BUBBLES: "Bulles",
		FEATURES: "Fonctionalités",
		ADVANCED_MD: "Editeur Markdown avancé",
		DEFAULT_LINK: "Ciblage par défaut des publications",
		BY_SITES: "Par sites",
		BY_GROUPS: "Par groupes",
	},

	Companies: {
		COMPANIES: "Entreprises",
		NAME: "Nom",
		ALL_COMPANIES: "Toutes les entreprises",
	},

	Sites: {
		SITES: "Sites",
		FAV_SITES: "Sites favoris",
		ALL_SITES: "Tous les sites",
		SELECT_SITES: "Sélectionnez un ou des sites",
		SELECT_SITE: "Sélectionnez un site",
		ADD_BTN: "Ajouter un site",
		ADD_TITLE: "Ajouter un site",
		MODIFY_TITLE: "Modifier un site",
		NO_SITE_FOUND: "Aucun site trouvé",
		CONTACT_STEP: "Contact",
		LOCATION_STEP: "Localisation",
		INPUT_LOCATIOn: "Entrez une localisation",
		NAME: "Nom",
		MEMBER: "membre",
		MEMBERS: "membres",
		MEMBERS_one: "{{count}} membre",
		MEMBERS_other: "{{count}} membres",
		ADDRESS: "Adresse",
		TOWN: "Ville",
		COUNTRY: "Pays",
		POSTAL_CODE: "Code postal",
		LANGUAGE: "Langue",
		LANG_MSG: "Sélectionnez une langue pour le site",
		NAME_ERROR: "Veuilez entrer un nom pour le site",
		COUNTRY_ERROR: "Veuillez entrer un pays pour le site",
		ADRESS_ERROR:
			"Aucune adresse trouvé, veuillez vérifier les informations pour pouvoir afficher le site sur la carte",
		DUP_POSTS:
			"Dupliquer les publications en ligne pour tous les sites, sur le nouveau site",
		SEND_SITE: "Envoi du site",

		ASKDELETE_one:
			"Le site {{name}} et tous ses utilisateurs seront supprimés. $t(Commons.ASK_CONTINUE)",
		ASKDELETE_other:
			"{{count}} sites et tous leurs utilisateurs seront supprimés. $t(Commons.ASK_CONTINUE)",
	},

	Appearance: {
		LOGO_COLORS: "Logo et couleurs",
		LOAD_IMAGES: "Images de chargement",
		DEFAULT_IMAGES: "Images par défaut",
		GALLERY: "Galerie d'images",
		APP_FUNCTIONS: "Fonctionnalités",
		MENUS: "Menus & pages",
		LOGO: "Logo",
		LOGO_DESC: "Le logo de votre entreprise ou réseau",
		LOGO_DARK_THEME: "Logo pour le thème sombre",
		LOGO_DARK_THEME_DESC: "Logo pour le thème sombre (facultatif)",
		APP_ICON: "Icone de l'application web (WIP)",
		COLORS: "Couleurs",
		MAIN_COLOR: "Couleur principale",
		SECONDARY_COLOR: "Secondaire",
		CUSTOM_COLOR_WARN:
			"Une couleur d'interface personnalisée a été définie dans vos réglages",
		WEB_LOAD: "Web",
		MOBILE_LOAD: "Mobile",
		BANNER: "Bannière",
		CUSTOM_TITLES: "Menus et pages",
		CUSTOM_TITLES_MSG:
			"Les titres personnalisés pour les pages autres que 'News', 'Entreprise', 'Peoples' et 'Contacts' ne sont pas encore disponibles", // pour l'application mobile
		ADD_CUSTOM_TITLE: "Ajouter un titre personnalisé",
		DEFAULT_VALUES: "Remettre les valeurs par défaut",
		REMOVE_VALUES: "Supprimer les valeurs personnalisées",
	},

	Assignator: {
		TARGET: "Cible",
		NO_TYPE_SELECTED_ERROR: "Veuillez sélectionner un type de cible",
		NO_TARGET: "Veuillez sélectionner une ou des cibles",
		NEW_TARGET: "Nouvelle cible",
		ADD_FILTER: "Filtrer",
	},

	Posts: {
		LOADING: "Chargement des publications",
		LOAD_ERROR: "Erreur lors du chargement des pubications",
		LIST_END: "Fin des publications",
		NO_RESULT: "Aucune publication ne correspond à la recherche",
		ADD_BTN: "Rédiger une publication",
		MODERATED: "Modéré",
		// Post preview
		MODERATION_MSG: "Retour de modération",
		MOD_REASON: "Veuillez entrer une raison pour le refus",
		READ_MORE: "Lire la suite",
		VIEWS: "vues",
		VIEWS_one: "vue",
		VIEWS_other: "vues",
		LIKES: "likes",
		LIKES_one: "like",
		LIKES_other: "likes",
		NO_LIKES: "Aucun like pour la publication",
		REACTIONS: "Réactions",
		REACTIONS_zero: "Aucune réaction pour la publication",
		REACTIONS_one: "{{count}} Réaction",
		REACTIONS_other: "{{count}} Réactions",
		FAVORITE: "Favori",
		SAVE_POST: "Enregistrer la publication dans les favoris",
		SAVED_POST: "Publication enregistrée dans les favoris",
		DISPLAY_DOCS: "Afficher les documents reliés",
		DOCS_BTN_one: "Document",
		DOCS_BTN_other: "Documents",
		CLOSE_DOCS: "Fermer les documents",
		EXTEND_POST: "Agrandir la publication",
		REDUCE_POST: "Réduire la publication",
		EXTEND_IMAGES: "Agrandir les images",
		PREV_POST: "Publication précédente",
		NEXT_POST: "Publication suivante",
		// Edit drawer
		ADD_TITLE: "Rédiger une publication",
		MODIFY_TITLE: "Modifier une publication",
		EDIT_TITLE: "Editer une publication",
		PUBLISH_TITLE: "Valider une publication",
		// Steps
		CONTENT_STEP: "Rédaction",
		POST_RESPONSE: "Complément d'information",
		IMAGES_STEP: "Images",
		ATTACH_STEP: "Pièces jointes",
		SEND_STEP: "Envoi",
		PREVIEW_STEP: "Aperçu",

		POST_SUBTITLE: "Sur-titre de la publication",
		POST_TITLE: "Titre de la publication",
		POST_TEXT: "Contenu de la publication",
		SUBTITLE_ERROR: "Veuillez entrer le sur-titre de la publication",
		TITLE_ERROR: "Veuillez entrer le titre de la publication",
		TEXT_ERROR: "Veuillez entrer le contenu de la publication",
		DELETE_COMPLEMENT: "Supprimer le complément d'information",
		DELETE_COMPLEMENT_MSG:
			"Etes vous sur de vouloir supprimer le complément ?",
		DELETE_COMPLEMENT_MSG1: "(changement irreversible)",
		DELETING_COMPLEMENT: "Suppression du complément d'information",

		//delete
		ASKDELETE_one:
			'La publication "{{title}}" sera supprimée. $t(Commons.ASK_CONTINUE)',
		ASKDELETE_other:
			"{{count}} publications seront supprimées. $t(Commons.ASK_CONTINUE)",
		DELETING_one: "Suppression de la publication",
		DELETING_other: "Suppression des publications",

		//send
		SEND_POST: "Envoi de la publication",
		SEND_POST_RESP: "Envoi du complément d'information",
		SEND_IMAGES: "Envoi des images",
		SENDING_ATTACHMENTS: "Envoi des pièces jointes",
		POST_PUBLISHED: "Votre publication est en ligne !",
		POST_DIFFERED: "Votre publication sera en ligne le",
		POST_DRAFTED: "Publication enregistrée en brouillon",
		POST_MODERATION: "Votre publication a bien été envoyée !",
		POST_MODERATION_2:
			"Elle sera vérifiée puis publiée par un administrateur trés prochainement",
		// States
		WAITING_BTN: "A valider",
		WAITING_MODIF_BTN: "Modérées",
		PUBLISHED_BTN: "Publiées",
		DRAFT_BTN: "Brouillons",
		// Comments
		COMMENTS: "Commentaires",
		LOADING_COMMENTS: "Chargement des commentaires",
		SHOW_COMMENTS: "Afficher les commentaires",
		CLOSE_COMMENTS: "Fermer les commentaire",
		NO_COMMENT: "Aucun commentaire",
		REPLY: "Répondre",
		CAREER_APPLY: "Candidater",
		COMMENT_PH: "Entrez votre réponse",
		RESP_SEND: "Envoyer",
		RESP_SENDED: "Votre réponse a bien été envoyée!",
		NEW: "New",

		// Stats
		POST_STATS: "Statistiques",
		CLOSE_STATS: "Fermer les statistiques de la publication",
		LAST_CHANGED: "Date de dernière modification",
		PUBLISHED_DATE: "Date de publication",
		REACHED_USERS: "Utilisateurs atteints",
		READED_zero: "Aucun utilisateur a lu la publication",
		READED_one: "{{count}} utilisateur a lu la publication",
		READED_other: "{{count}} utilisateurs ont lu la publication",
		CREATED_FROM: "Créé depuis",
		ORIGIN: "Emplacement original",

		//Alerts
		SELECTED_TARGETS_zero: "Aucun utilisateur sélectionné",
		SELECTED_TARGETS_one: "{{count}} utilisateur sélectionné",
		SELECTED_TARGETS_other: "{{count}} utilisateurs sélectionnés",
	},

	Comments: {
		DELETE_ERROR: "Erreur de suppression",
		REPORT_COMMENT:
			"Non disponible pour le moment - Signaler le commentaire",
		DELETE_COMMENT: "Supprimer le commentaire",
		MODIFIED: "modifié",
	},

	EditDrawer: {
		PREV_BTN: "Précédent",
		NEXT_BTN: "Suivant",
		DRAFT_BTN: "Enregistrer en brouillon",
		SAVE_BTN: "Enregistrer les modifications",
		SAVE_NO_PUBLISH_BTN: "Enregistrer sans publier",
		VALIDATE_BTN: "Valider",
		END_BTN: "Fermer",
		RETRY_BTN: "Recommencer",
		SEND_BTN: "Envoyer",
		SEND_STEP: "Envoi",
		END_STEP: "Fin",
		PREVIEW_STEP: "Aperçu",
		SENDED_WARN: "Une erreur non bloquante est surevenue durant l'envoi",
		// SENDED_WARN: "Envoi partiellement réussi. Cliquez sur le bouton \"Recommencer\" pour re essayer"
	},

	Gallery: {
		IMAGES_ROW_TITLE: "Images de la publication",
		GALLERY_TITLE: "Galerie",
		LOADING: "Chargement de la galerie ...",
		LOAD_ERROR: "Une erreur est survenue lors du chargement de la galerie",
		NO_IMAGE: "Aucune image dans la galerie",
		DROP_MSG:
			"Glissez les images dans le bloc ci-dessous pour les ajouter à la galerie",
	},

	MDE: {
		PH: "Veuillez entrer votre texte",
		HEADER_BTN: "En-tête",
	},

	Events: {
		LOADING: "Chargement des évènements",
		LOAD_ERROR: "Erreur lors du chargement des évènements",
		LOAD_ERROR_2:
			"Une erreur est survenue lors de la récupération de la publication liée à l'évènement",
		SHOW: "Afficher l'évènement",
		MODIFY: "Modifier l'évènement",
		EXPORT: "Exporter dans mon calendrier",
		PARTICIPATION: "Participation",
		ENABLE_PARTICIPATION: "Permettre aux utilisateurs de participer",
		PARTICIPATE: "Participer à l'évènement",
		NO_PARTICIPATE: "Ne plus participer à l'évènement",
		PARTICIPANT_zero: "Aucun participant",
		PARTICIPANT_one: "{{count}} participant",
		PARTICIPANT_other: "{{count}} participants",
		DELETING: "Supression de l'événement",
		YEAR: "Année",
		MONTH: "Mois",
		WEEK: "Semaine",
		GRID: "Grille",
		LIST: "Liste",
		NO_EVENT: "Aucun évènement pour le jour sélectionné",
		NO_UPCOMING_EVENT: "Aucun évènement à venir",
		TILE_DATE_FORMAT: "DD/MM/YYYY",
		DATE_INPUT_FORMAT: "dd/MM/yyyy",
		DATE_TIME_FORMAT: "DD/MM/YYYY HH:mm",
		DATE_TIME_AT_FORMAT: "DD/MM/YYYY [à] HH:mm",
		DATE_TIME_INPUT_FORMAT: "dd/MM/yyyy HH:mm",
		COMING_EVENTS: "Évènements à venir",
		ADD_TITLE: "Ajouter un évènement",
		MODIFY_TITLE: "Modifier un évènement",
		EVENT_TITLE: "Titre de l'évènement",
		EVENT_DESC: "Description",
		TITLE_ERROR: "Veuillez entrer un nom pour l'évènement",
		SENDING_EVENT: "Envoi de l'évènement",
		DATES: "Dates",
		START_DATE: "Date de début",
		END_DATE: "Date de fin",
		START: "Début",
		END: "Fin",
		ALL_DAY: "Toute la journée",
		FULL_DAY: "Toute la journée",
		THE_EVENT: "L'évènement",
		WILL_BE_DELETE: "sera supprimé, voulez-vous continuer ?",
	},

	Docs: {
		LOADING: "Chargement des documents",
		LOAD_ERROR: "Erreur lors du chargement des documents",
		LOAD_DOC_ERROR:
			"Une erreur est survenue lors du chargement du document",
		LIST_END: "Fin des documents",
		NO_DOC: "Aucun document a afficher",
		NO_RESULT: "Aucun document ne correspond à la recherche",
		DOC_LOAD: "Chargement du document",
		FAVORITES: "Docs favoris",
		SENDING_DOC: "Envoi du document",
		SENDING_DOCS: "Envoi des documents",
		NO_FAV: "Aucun documents favoris",
		DEL_DOC_TITLE: "Supprimer le document",
		DELETING_one: "Suppression du document",
		DELETING_other: "Suppression des documents",
		LINKED_DOCS_one: "{{count}} Document relié",
		LINKED_DOCS_other: "{{count}} Documents reliés",
		EXISTINGS_DOCS: "Documents existants",
		IMPORTED_DOCS: "Document importé",
		ADD_DOC: "Nouveau document",
		ADD_TO_DOCS_UTIL: "Ajouter aux documents utiles",
		PREVIEW_SIZE_ERROR:
			"La taille du document est trop élevée pour afficher la prévisualisation",
		MULTI_RM_MSG: "documents seront supprimés, voulez-vous continuer ?",
		THE_DOC: "Le document",
		WILL_BE_DELETE: "sera supprimé, voulez-vous continuer ?",
		SENDED: "Document envoyé",
		ALLOW_SHARE: "Autoriser le partage du document",
		SHARED_DOC: "Document partagé",
		SHARE_DOC: "Partager un document",
	},

	Charts: {
		ADD_CHART: "Nouvel indicateur",
		LOADING: "Chargement des indicateurs",
		LOAD_ERROR: "Erreur lors du chargement des indicateurs",
		LIST_END: "Fin des indicateurs",
		NO_CHART: "Aucun indicateur a afficher",
		TYPE_STEP: "Type d'indicateur",
		VALUES_STEP: "Valeurs",
		SENDING: "Envoi de l'indicateur",
		CHART_SENDED: "L'indicateur a bien été publié",
		CHART_DRAFTED: "L'indicateur a été enregistré en brouillon",
		CTX_MENU_MSG:
			"Utilisez votre clic droit sur les cellules pour plus d'actions",
		TYPE: "Type",
		TITLE: "Titre",
		OPTIONS: "Options",
		PREVIEW: "Prévisualisation",
		VALUES: "Valeurs",
		bars: "Horizontales",
		bars_groupName: "Barres",
		columns: "Colonnes",
		columns_100: "Colonnes empilées",
		rounded: "Arrondies",
		rounded_area: "Arrondies",
		stacked_area: "Empilées",
		area: "Aires",
		stacked_area_100: "Empillées à 100%",
		pie: "Tarte",
		round: "Donut",
		round_gauge: "Cible",
		gauges_and_text: "Jauges et texte",
		gauge: "Jauge",
		text: "Texte",
		NO_TITLE_ERROR: "Veuillez entrer un titre pour l'indicateur",
		NO_VALUE_ERROR: "Veuillez entrer des valeurs pour l'indicateur",
		ASKDELETE_one:
			'L\'indicateur "{{title}}" sera supprimé, voulez vous continuer ?',
		ASKDELETE_other:
			"{{count}} indicateurs seront supprimés, voulez vous continuer ?",
		DELETING_one: "Suppression de l'indicateur",
		DELETING_other: "Suppression des indicateurs",
	},

	Contacts: {
		ADD_BTN: "Nouvel utilisateur",
		MODIFY_TITLE: "Modifier un utilisateur",
		LOADING: "Chargement des contacts",
		LOAD_ERROR: "Erreur lors du chargement des contacts",
		LIST_END: "Fin des contacts",
		HIDDEN_INFOS: "L'utilisateur a masqué ses informations de contact",
		PHONE: "Téléphone",
		MAIL: "Mail",
		WEBSITE: "Site internet",
		MAIN_CONTACT: "Contact principal",
		MAIN_CONTACT_PH: "Sélectionnez un administrateur",
		MAIN_CONTACT_MSG:
			'Le contact sera relié au bouton "Contacter un administrateur" de l\'application mobile',
		ERROR_LASTNAME: "Veuillez entrer un nom",
		ERROR_FIRSTNAME: "Veuillez entrer un prénom",
		ERROR_SITE: "Veuillez sélectionner un site",
		ERROR_FAMILY: "Veuillez sélectionner une famille",
		ERROR_FUNCTION: "Veuillez sélectionner une fonction",
		ERROR_NO_CONTACT: "Veuillez entrer un numéro de téléphone ou un mail",
		ERROR_MAIL:
			"Le format du mail est incorrect, veuillez vérifier votre saisie",
		OLD_ERROR_PHONE:
			"Le numéro de téléphone ne peut être composé que de 10 chiffres, sans espaces",
		ERROR_PHONE:
			"Le format du téléphone est incorrect, veuillez vérifier votre saisie",
		ERROR_WEBSITE:
			"Le format de l'url du site est incorrect, veuillez vérifier votre saisie",
		ERROR_ROLE: "Veuillez sélectionner un rôle",
		IDENTITY_STEP: "Identité",
		RIGHTS_STEP: "Rôle et droits",
		DOCS_STEP: "Documents personnels",
		USER_ROLE_TXT: "Rôle de l'utilisateur",
		READ_RIGHT: "Lire",
		WRITE_RIGHT: "Créer",
		MODIFY_RIGHT: "Modifier",
		ADMIN_RIGHT: "Administer",
		READER_ROLE: "Lecteur",
		USER_ROLE: "Utilisateur",
		ADMIN_ROLE: "Administrateur",
		SUPER_ADMIN_ROLE: "Super administrateur",
		ELOADMIN: "JoonDev (EloAdmin)",
		ONLINE: "En Ligne",
		RESET_PASS: "Réinitialiser le mot de passe",
		RESET_PASS_MSG:
			"Êtes-vous sûr de vouloir générer un nouveau mot de passe pour l'utilisateur",
		RESET_PASS_MSG2:
			"Ses nouveaux identifiants lui seront transmis par mail",
		EXPORT: "Exporter",
		EXPORT_TITLE: "Exporter des utilisateurs",
		EXPORT_FILEDS_MSG: "Sélectionnez les champs à exporter",
		SELECTED_one: "{{count}} utilisateur sélectionné",
		SELECTED_other: "{{count}} utilisateurs sélectionnés",
		NO_SELECTED_ERROR: "Veuillez sélectionner des utilisateurs a exporter",
		NO_FIELDS_ERROR: "Veuillez sélectionner des champs a exporter",
		SELECTED_USERS: "utilisateurs sélectionnés",
		ASKDELETE_one:
			'L\'utilisateur "{{name}}" sera supprimé, voulez vous continuer ?',
		ASKDELETE_other:
			"{{count}} utilisateurs seront supprimés, voulez vous continuer ?",
		DELETING_one: "Suppression de l'utilisateur",
		DELETING_other: "Suppression des utilisateurs",
	},

	Rss: {
		RSS_POPUP_TITLE: "Nouveau flux",
		ADD_FEED: "Ajouter un flux",
		RSS_ADMIN: "Flux Externe",
		ASKDELETE_one:
			'Le flux RSS "{{name}}" sera supprimé, voulez vous continuer ?',
		ASKDELETE_other:
			"{{count}} flux RSS seront supprimés, voulez vous continuer ?",
		DELETING_one: "Suppression du flux rss",
		DELETING_other: "Suppression des flux rss",
		SELECT_RSS_FEEDS: "Sélectionnez un ou plusieurs flux RSS",
		SEARCH_RSS_FEEDS: "Renseignez une url",
		NOTIFY: "notifier les nouvelles actualitées du flux",
		RSS_NAME: "Nom du flux",
		RSS_INSPIRATION: "Inspiration",
		RSS_GO_BACK: "Retour au news",
		SUBMIT: "Valider",
		NAME: "Nom",
		IS_NOTIFIED: "Notif",
		INSPIRED_BY_RSS: "S'inspirer de cette actu",
		IMAGE_TITLE: "Image ajoutée à la publication",
		ERROR_MSG: "Aucuns flux n'a été trouvé",
		FULL_POST: "Cliquez ici pour accéder à l'article complet",
		EMPTY_ITEMS: "Aucun article n'a été trouvé",
	},

	MyOnlyAll: {
		PROFILE_BTN: "Mon profil",
		NEWS_BTN: "Mes news",
		OFFERS_BTN: "Mes annonces",
		PRO_OFFERS_BTN: "Mes annonces pro",
		CAREERS_BTN: "Mes carrières",
		DOCS_BTN: "Mes documents",
		PROFILE_ERROR: "Erreur de chargement du profil",
	},

	User: {
		USER: "Utilisateur",
		FIRST_NAME: "Prénom",
		LAST_NAME: "Nom",
		MAIL: "Email",
		PHONE: "Téléphone",
		IMAGE: "Image de profil (facultative)",
		VISIBLE: "Visible",
		LOGIN: "Identifiant",
		COMPANY: "Entreprise",
		COMPANY_NETWORK: "Entreprise / Réseau",
		SITE: "Site",
		GROUPS: "Groupes",
		ROLE: "Rôle",
		WORK_FAM: "Famille de métier",
		WORK_FUNC: "Fonction",
		HOBBY: "Hobby",
		SAVE_BTN: "Enregistrer les informations",
		SENDING_BTN: "Envoi des informations ...",
		LAST_CONN: "Dernière connexion",
		NO_CONN: "jamais",
	},

	Password: {
		DISPLAY: "Afficher le mot de passe",
		OLD_PASS: "Ancien mot de passe",
		NEW_PASS: "Nouveau mot de passe",
		CONFIRM_NEW_PASS: "Confirmation du nouveau mot de passe",
		EMPTY_OLD_ERROR: "Entrez votre ancien mot de passe",
		OLD_ERROR: "L'ancien mot de passe est incorrect",
		NOT_VALID_ERROR:
			"Le nouveau mot de passe doit satisfaire les conditions suivantes:",
		LENGTH_ERROR: "- Minimum 8 caractères",
		CHARS_ERROR: "- Contenir majuscules et minuscules",
		SPEC_CHARS_ERROR:
			"- Un chiffre et un caractère spécial ( ,;#.!@$%^&*- )",
		NO_MATCH_ERROR: "Les mots de passe ne correspondent pas",
		SAVE_PASS: "Changer le mot de passe",
	},

	ImagesEditor: {
		APPLY: "Appliquer",
		CANCEL: "Annuler",
		LOAD: "Charger",
		HAND: "Déplacer",
		CROP: "Recadrer",
		FLIP: "Inverser",
		ROTATE: "Tourner",
		DRAW: "Dessiner",
		SHAPE: "Ajouter une forme",
		TEXT: "Ajouter du texte",
		ZOOM_IN: "Zoomer",
		ZOOM_OUT: "Réduire le zoom",
		HISTORY: "Historique des actions",
		UNDO: "Annuler l'action",
		REDO: "Rétablir l'action",
		RESET: "Retablir l'image d'origine",
		DELETE: "Supprimer la modification",
		DELETE_ALL: "Supprimer toutes les modifications",
		CROP_TOOLTIP: "Recadrer l'image",
		CANCEL_TOOLTIP: "Annuler l'envoi de l'image",
		SAVE: "Enregistrer",
		SAVE_TOOLTIP: "Enregistrer l'image",
		HELP: "Comment utiliser l'éditeur d'image",
	},

	Weather: {
		WEATHER: "Météo",
		ERROR: "Erreur de chargement de la météo",
		LOAD: "Chargement de la météo",
		SUNRISE: "Levé",
		SUNSET: "Couché",
		HUMIDITY: "Humidité",
		WIND: "Vent",
		PRESSURE: "Pression",
		FEEL: "Ressenti",
		PROMPT_CITY:
			"Entrer une ville ou une adresse ci-dessous pour afficher la météo",
	},

	Welcome: {
		WELCOME: "Bienvenue {{FirstName}}",
		DELIGHTED: "Enchanté {{FirstName}}",
		NTMY: "Heureux de vous rencontrer {{FirstName}}",

		MORNING: "Bonjour {{FirstName}}",
		AFTERNOON: "Bon après-midi {{FirstName}}",
		EVENING: "Bonne soirée {{FirstName}}",
		NIGHT: "Bonne nuit {{FirstName}}",

		MENU_WELCOME: "Bienvenue,",
		MENU_MORNING: "Bonjour,",
		MENU_AFTERNOON: "Bon après-midi,",
		MENU_EVENING: "Bonne soirée,",
		MENU_NIGHT: "Bonne nuit,",

		FORGOT: "Avez-vous oublié quelque chose {{FirstName}} ?",
		ALREADY_BACK: "Déjà de retour {{FirstName}} ?",
		MISSING_YOU: "Je vous manque déjà {{FirstName}} ?",

		GO: "Embarquement immédiat ! 🚀",
		SEATBELT: "Accrochez votre ceinture {{FirstName}} ! 🚀",
		FORCE: "Que la force soit avec vous {{FirstName}} ",
		LOVE: "Moi aussi je vous aime {{FirstName}} 💛",
		STRETCH: "Pensez à vous étirer de temps en temps {{FirstName}}",
		GOOD_DAY: "Votre journée se passe bien {{FirstName}} ?",

		INSOMNIA: "Vous ne dormez pas {{FirstName}} ?",
		GOOD_SLEEP: "Avez-vous bien dormi {{FirstName}} ?",
		MORNING_DRINK: "Plutôt thé ou café {{FirstName}} ?",
		BG: "Vous avez bonne mine {{FirstName}} !",
		EAT: "Prenez le temps de manger {{FirstName}}",
		HAPPY_MEAL: "Bon appétit {{FirstName}} !",

		WELCOME_BACK: "Bon retour parmis nous {{FirstName}} !",
		EVOLVED:
			"Je me suis grandement amélioré depuis votre dernière visite {{FirstName}} !",
		MISSING: "Je ne pensais jamais vous revoir {{FirstName}}",
		MISSING_WOW: "{{FirstName}}, c'est bien vous ?!",
	},

	Forms: {
		ADD_FORM: "Nouvelle enquête",
		ANSWER_FORM: "Répondre a l'enquête",
		EDIT_FORM: "Editer l'enquête",
		LOADING_FORM: "Chargement de l'enquête",
		EXPORT_ANSWERS: "Exporter les réponses",
		FORM_SELECTION: "Sélection d'enquêtes",
		LINKED_FORMS_one: "{{count}} Enquête reliée",
		LINKED_FORMS_other: "{{count}} Enquêtes reliées",
		AVAILABLE_TO_DATE: "Disponible jusqu'au {{date}}",
		FORM_TITLE: "Titre de l'enquête",
		SECTION_TITLE: "Titre de la section",
		DESC: "Description (facultative)",
		QUEST_TITLE: "Entrez votre question",
		QUEST_INFOS: "Informations supplémentaires (facultatif)",
		QUEST_TYPES: "Types de question",
		ADD_SECTION: "Ajouter une section a l'enquête",
		ADD_QUESTION: "Ajouter une question à la section",
		ADD_OPTION: "Ajouter une option de réponse",
		CLONE_QUEST: "Duppliquer la question",
		RM_SECTION: "Supprimer la section",
		RM_QUEST: "Supprimer la question",
		REQUIERED: "Obligatoire",
		REQUIERED_TXT: "Rendre la question obligatoire",
		REQUIERED_ERR:
			'La question "{{title}}" est obligatoire, veuillez y répondre',
		SHORT_TEXT: "Texte court",
		SHORT_TEXT_EX: "Exemple de texte court",
		LONG_TEXT: "Texte multiligne",
		LONG_TEXT_EX: "Exemple de texte multiligne",
		UNIQUE_CHOICES: "Choix unique",
		MULTIPLE_CHOICE: "Choix multiples",
		RANGE_INPUT: "Intervalle / Note",
		RANGE_MIN: "Minimum",
		RANGE_MAX: "Maximum",
		ENTER_ANSWER: "Entrez votre réponse",
		YOUR_ANSWER: "Votre réponse",
		FORM_NO_SECTION_ERROR: "Veuillez ajouter une section a l'enquête",
		FORM_EMPTY_TITLE: "Veuillez entrer un titre pour l'enquête",
		FORM_EMPTY_SECTION_TITLE: "Veuillez entrer un titre pour la section",
		FORM_NO_SECTION_QUESTION:
			"Veuillez ajouter au moins une question pour la section",
		FORM_QUESTION_EMPTY_TITLE: "Veuillez entrer un titre pour la question",
		FORM_SELECT_VALUE: "Veuillez sélectionner une valeur",
		FORM_MULTIPLE_ANSWERS_MISSING_OPTION:
			"La question n'a pas d'options de réponse",
		FORM_ANSWER_MISSING_VALUE: "L'option de réponse n'a pas de valeur",
		FORM_ANSWER_OUT_OF_RANGE:
			"La valeur selectionnée n'est pas comprise entre {{min} et {{max}}",
		ANON_ANSWERS: "Réponses anonymes",
		CHANGE_ANON_ERROR_MSG:
			"Cette option n'est pas modifiable car les réponses de l'enquête sont déjà anonymes",
		DEFFERED_PUBLISH: "Publication différée",
		ANS_LIMIT_DATE: "Date limite de réponse",
		LIMIT_DATE_ERROR:
			"La date limite de réponse est inférieure à la date de publication",
		SENDING_FORM: "Envoi de l'enquête",
		FORM_DRAFTED: "L'enquête a bien été enregistrée en brouillon",
		FORM_SENDED: "L'enquête a bien été envoyée",
		VIEW: "Visualisation",
		BARS_VIEW: "Barres",
		PIE_VIEW: "Parts",
		TABLE_VIEW: "Tableau",
		SECTION_INDEX: "Section {{index}}",
		QUESTIONS: "Questions",
		QUESTIONS_one: "{{count}} Question",
		QUESTIONS_other: "{{count}} Questions",
		OPTION: "Option",
		ANSWERS: "Réponses",
		ANSWERS_one: "{{count}} Réponse",
		ANSWERS_other: "{{count}} Réponses",
		ALL_ANSWERS: "Toutes les réponses",
		NO_ANSWER: "Aucun utilisateur n'a répondu à la question",
		USER: "Utilisateur",
		DISP_PER_USER: "Afficher les résultats par utilisateur",
		NO_USER_ANS: "L'utilisateur n'a pas répondu",
		LOWEST_ANS: "Réponse la plus basse",
		MEDIAN_ANS: "Moyenne des réponses",
		HIGHEST_ANS: "Réponse la plus haute",
		ANON_ANSWERS_WARN: "Vos réponses sont anonymes",
		NON_ANON_ANSWERS_WARN: "Vos réponses ne sont PAS anonymes",
		ASKDELETE_one:
			'L\'enquête "{{title}}" sera supprimée, voulez vous continuer ?',
		ASKDELETE_other:
			"{{count}} enquêtes seront supprimées, voulez vous continuer ?",
		DELETING_one: "Suppression de l'enquête",
		DELETING_other: "Suppression des enquêtes",
	},

	Reactions: {
		ADD_RTYPE: "Nouveau type de réaction",
		MODIFY_RTYPE: "Modifier le type de réaction",
		SEND_RTYPE: "Envoi du type de réaction",
		DEFINE_DEFAULT:
			"Définissez ici les réactions par défaut pour chaque type de publication",
	},

	Analytics: {
		SINCE_DEPLOY: "Depuis le déploiement",
		FIRST_CONN: "Comptes activés",
		TOTAL_CONN: "Nombre de connexions",
		NEW_POSTS: "Nouvelles publications",
		DATE_START: "Début",
		DATE_END: "Fin",
		HOT_POSTS: "Publications populaires",
		POSTS: "publications",
		NO_POSTS: "Aucune publication",
		NO_USERS: "Aucun utilisateurs",
		LAST_CONN: "Dernières connexions",
		ACTIVATED_ACCOUNTS: "Comptes activés",
		ACTIVATIONS_BTN: "Activations",
		BTN_CONNECTIONS: "Connexions",
		ONLINE: "En ligne",
		ALL: "Tout",
		MONTH: "Mois",
		WEEK: "Semaine",
		DAY: "Jour",
		AND: "Et",
		others_one: "$t(Analytics.AND) {{count}} autre",
		others_other: "$t(Analytics.AND) {{count}} autres",
		postscount_one: "{{count}} publication",
		postscount_other: "{{count}} publications",

		userscount_activated_one: "{{count}} activation",
		userscount_activated_other: "{{count}} activations",
		userscount_connection_one: "{{count}} connexion",
		userscount_connection_other: "{{count}} connexions"
	},
	Alerts: {
		New_Alert: "Nouvelle alerte",
	},
	"section \"Réglages": {
		Dev_Function: "Fonctionnalités des développeurs",
		Display_Dev_Function: "Afficher les fonctionnalités en développement",
		Switch_Company: "Changer de compagnie OnlyAll",
		Enable_Tutorials: "Tutoriels",
		Enable_Reactions: "Réactions",
		Enable_Highlighted_categories: "Highlighted categories"
	},
	Indicators_creation: {
		Indicator_Type: "Sélectionnez un type d'indicateur",
		Indicator_Type_Bar: "Barres",
		Indicator_Type_Areas: "Aires",
		Indicator_Type_Rounding: "Arrondis",
		Indicator_Type_Gauges_text: "Jauges & Textes",
		Indicator_Column_Title: "Titre des colonnes",
		Indicator_Value_Unit: "Unité de valeurs",
		Incicator_Column: "Colonne 1",
		Indicator_column_2: "Colonne 2",
		Indicator_Value: "Valeur",
		Indicator_Donut_Thickness: "Epaisseur (%)",
		Indicator_Gauge_Secondary_Thickness: "Epaisseur secondaire (%)",
		Indicator_Gauge_Text_Unit: "Unité",
		Indicator_Gauge_Text_Color: "Couleur",
		Indicator_Gauge_Text_Secondary_Color: "Couleur secondaire"
	},
	Contact_Page: {
		Contact_Manage_User: "Gérer les utilisateurs"
	},
	Modify_User_Page: {
		Modify_User_Personal_documents: "Documents personnels",
		Modify_User_Import_Document: "Importer un document"
	},
	Statistic_Page: {
		Statistic_Origins: "Origines des publications",
		Statistic_Type: "Type de publication",
		Platform: "Plateforme",
		Origin: "Origine",
		Sort: "Trié par",
		Publication: "Publications"
	},
	MyOnlyAll_Page: {
		MyOnlyAll_Password_No_Change: "Le mot de passe ne peut pas être changé car vous ne vous êtes pas connecté avec ce compte"
	},
	ErrorMsg: {
		Target: "Veuillez sélectionner une ou des cibles",
		Chart: "Veuillez sélectionnez un type d'indicateur"
	}
}