import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import "./PostPreviewTopBar.css";
import Button from "components/Button/Button";
import BackButton from "components/Button/BackButton";

export default function PostPreviewTopBar({close, ref, post, nextPost, prevPost, open}) {

	function getTitle() {
		if (post.PostTypeId !== 3)
			return (post.Title);
		let splitted = post.Title.replace(/\/__CUT__\\/gi, " - ");
		return (splitted);
	}

	let title = getTitle();
	return (
		<div className="PostPreviewTopBar" ref={ref}>
			<div className="d-flex align-items-center overflow-hidden gap-2">
				<BackButton onClick={close}/>
				{
					title &&
					<div className="title font-bold text-uppercase">
						{ title }
					</div>
				}
			</div>
			<div className="d-flex ms-2">
				{
					prevPost &&
						<button className="unstyled p-2" onClick={prevPost} title={t("Posts.NEXT_POST")}>
							<FontAwesomeIcon icon={faChevronLeft}/>
						</button>
				}
				{
					nextPost &&
						<button className="unstyled ms-2 p-2" onClick={nextPost} title={t("Posts.NEXT_POST")}>
							<FontAwesomeIcon icon={faChevronRight}/>
						</button>
				}
				{
					open &&
						<Button primary onClick={open} text={"Ouvrir dans la page des news"}/>
				}
			</div>
		</div>
	);
}
