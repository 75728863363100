import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button/Button";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import FormInput from "components/Inputs/FormInput";
import Summary from "components/Summary/Summary";
import TagLabel from "components/Tags/Label/TagLabel";
import { t } from "i18next";
import getDomPath from "includes/getDomPath";

export default function StepTarget(props) {

	const [CurPath, setCurPath] = useState("");
	const [PathError, setPathError] = useState(false);

	const checkPath = (cur_paths, new_path, fromAdder) => {
		setPathError(false)
		let new_paths = [...cur_paths]
		if (new_path)
			new_paths.push(new_path);
		let paths = new_paths.filter((a, b) => {
			try {				
				let res = document.querySelector(a);
				if (res)
					return (a);
				throw (new Error("Element not found"))
			} catch (e) {
				setPathError({
					index: b,
					target: a,
					fromAdder: fromAdder
				})
				return (false)
			}
		}).join(" ")

		let replaced = paths.replace(".tour-editor-highlight", "");
		let elem = null;
		if (replaced?.length)
			elem = document.querySelector(replaced);
		props.highlight({target: elem}, true);
		return (replaced);
	}
	
	const [Paths, setPaths] = useState(() => {
		if (props.step.target)
			return (props.step.target.split(" "))
		let paths = getDomPath(props.target, {
			noSiblings: true,
			noNodeName: false,
			removeClass: ".tour-editor-highlight"
		});
		let check = checkPath(paths);
		if (check)
			props.step.target = check;
		return (paths);
	})

	function addPath() {
		let new_paths = [...Paths];
		new_paths.push(CurPath)
		updatePaths(new_paths);
		setCurPath("")
	}

	const rmPath = (index) => {
		let new_paths = [...Paths];
		new_paths.splice(index, 1)
		updatePaths(new_paths)
	}

	const updatePaths = (new_paths) => {
		let target = checkPath(new_paths);
		props.step.target = target;
		setPaths(new_paths)
	}

	const updatePath = (index, val) => {
		let new_paths = [...Paths];
		new_paths[index] = val;
		updatePaths(new_paths);
	}

	return (
		<Summary
			title={
				<div className="d-inline-flex gap-1 align-items-center">
					Elément ciblé
					{
						PathError && <TagLabel color=" red">{t("Desktop.ERROR")}</TagLabel>
					}
				</div>
			}
			className="w-100"
		>
			<div className="d-flex flex-column gap-1 w-100">
				{
					Paths.map((a, b) => {
						return (
							<div className={"path-item d-flex gap-2 align-items-center" + (PathError.index === b ? " error" : "")} key={b}>
								<Button className="w-auto" onClick={() => rmPath(b)}>
									<FontAwesomeIcon icon={faTimes} color="red"/>
								</Button>
								<FormInput value={a} onChange={(val) => updatePath(b, val)} style={{minWidth: "300px"}} externvalue={"true"}/>
							</div>
						)
					})
				}
				<div className="mt-2">
					{ (PathError && !PathError.fromAdder) && <ErrorsCont className="my-1" errors={`Node "${PathError.target}" non trouvée`}/>}
					<div>Ajouter une node</div>
					<div className="d-flex gap-2">
						<FormInput
							className={"w-100" + (PathError?.fromAdder ? " path-item error" : "")}
							placeholder="Node CSS selector"
							value={CurPath}
							onChange={(val) => {
								setPathError(false)
								if (val.length)
									checkPath(Paths, val, true);
								setCurPath(val)
							}}
							externvalue={"true"}
						/>
						<Button className="btn-primary w-auto" onClick={addPath}>+</Button>
					</div>
				</div>
			</div>
		</Summary>
	)
}
