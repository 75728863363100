import React, { useState } from "react";
import { useQueryClient } from "react-query";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import useAccount from "classes/Accounts/hooks/useAccount";
import { AsyncScrollTable } from "components/ScrollTable/ScrollTable";
import { t } from "i18next";
import "./Categories.css";
import CategoryObject from "classes/Categories/CategoryObject";
import genRequest from "includes/request";
import TagLabel from "components/Tags/Label/TagLabel";
import useTemplate from "hooks/useTemplate";
import CategoriesTypesDropDown from "./components/CategoriesTypesDropDown/CategoriesTypesDropDown";
import CategoryEditDrawer from "./components/CategoryEditDrawer/CategoryEditDrawer";
import CategoriesTableToolbar from "./CategoriesTableToolbar/CategoriesTableToolbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import CategoriesHighlight from "./components/CategoriesHighlight/CategoriesHighlight";

const DEFAULT_TYPE = "ALL";

function CategoriesPage(props) {
	const { template } = useTemplate();
	const { account, getSettings } = useAccount();
	const [CurType, setCurType] = useState({
		label: t("Categories.TYPE-" + DEFAULT_TYPE),
		value: DEFAULT_TYPE,
	});
	const [search, setSearch] = useState();
	const [editing, setEditing] = useState(false);
	const [selecteds, setSelected] = useState([]);
	const [sending, setSending] = useState(false);
	const QCL = useQueryClient();
	const { isMenuOpen, setIsMenuOpen } = props;

	function handleSearch(resp) {
		setSearch(resp);
	}

	function handleAdd(to_modify) {
		setSelected([]);
		let type = CurType?.value;
		if (CurType.value === "ALL") type = "post-1"; // news type by default
		let obj = new CategoryObject({
			CompanyId: account.CompanyId,
			DefaultHexaColor: template?.primaryColor(),
			CategoryType: type,
			IsFavorite: false,
			FavoritePosition: 0,
			...to_modify,
		});
		setSending(false);
		setEditing(obj);
		setSelected([]);
	}

	function getQuery() {
		if (!CurType?.value || CurType.value === "ALL")
			return {
				queryKey: ["Categories", "Company", account.CompanyId],
				queryFn: () =>
					genRequest("Categories/Company/" + account.CompanyId),
			};
		return {
			queryKey: [
				"Categories",
				"CompanyTyped",
				account.CompanyId,
				CurType.value,
			],
			queryFn: () =>
				genRequest(
					"Categories/CompanyTyped/" +
						account.CompanyId +
						"/" +
						CurType.value
				),
		};
	}

	function cancelEdit() {
		setEditing(false);
		setSending(false);
		setSelected([]);
	}

	return (
		<div className="CategoriesPage d-flex flex-column h-100">
			<PageHeadBar
				title={t("Pages.CATEGORIES")}
				siteSelect={false}
				search={handleSearch}
				handleAdd={handleAdd}
				addTitle={t("Categories.ADD_CATEGORY")}
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
				buttons={[
					{
						className: "me-auto",
						component: CategoriesTypesDropDown,
						props: {
							curType: CurType,
							all: true,
							setCurType,
						},
					},
					{
						title: "Mise en avant",
						component: CategoriesHighlight,
						props: {
							curType: CurType,
						},
						enabled: getSettings("FavCategories"),
					},
				]}
			/>
			<div className="d-flex flex-column overflow-hidden">
				<CategoriesTableToolbar
					setSelected={setSelected}
					selected={selecteds}
				/>
				<AsyncScrollTable
					companyId={account.CompanyId}
					itemId={"CategoryId"}
					{...getQuery()}
					checkbox={true}
					search={search}
					onItemClick={handleAdd}
					setSelected={setSelected}
					selected={selecteds}
					className={"CategoriesTable"}
					cols={[
						{
							name: "Name",
							title: t("Table.NAME"),
						},
						{
							name: "Description",
							title: t("Table.DESC"),
						},
						{
							name: "DefaultHexaColor",
							title: t("Table.COLOR"),
							component: ColorTag,
							classes: "text-center",
						},
						{
							name: "TypeName",
							title: t("Table.TYPE"),
							component: TypeTag,
							hidden: CurType?.value !== "ALL",
							sortBy: "TypeName",
							classes: "text-center",
						},
						{
							name: "Favs",
							title: "Mise en avant",
							component: FavCell,
							sort: false,
							classes: "text-center",
							hidden: !getSettings("FavCategories"),
						},
					]}
					sortBy="Name"
				/>
			</div>
			{editing && (
				<CategoryEditDrawer
					editing={editing}
					onClose={() => {
						setEditing(false);
						QCL.resetQueries(["Categories"]);
					}}
					sending={sending}
					cancelEdit={cancelEdit}
					template={template}
				/>
			)}
		</div>
	);
}

const FavCell = ({ item }) => {
	return (
		<div>
			<div title="Catégorie mise en avant">
				{item.IsFavorite && (
					<FontAwesomeIcon color={"#ffbd16"} icon={faStar} />
				)}
			</div>
		</div>
	);
};

function ColorTag({ item }) {
	return (
		<TagLabel
			color={item.DefaultHexaColor}
			title={item.Description}
			text={item.DefaultHexaColor}
		/>
	);
}

function TypeTag({ item }) {
	item.TypeName = t("Categories.TYPE-" + item.CategoryType);
	return t("Categories.TYPE-" + item.CategoryType);
}

export default CategoriesPage;
