import React, { useState } from "react";
import Moment from "react-moment";
import useAccount from "classes/Accounts/hooks/useAccount";
import DocumentsController from "classes/Documents/DocumentsController";
import "./DocsSelector.css";
import SearchBar from "components/SearchBar/SearchBar";
import ScrollTable from "components/ScrollTable/ScrollTable";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import DocUploader from "Pages/Documents/components/DocUploader/DocUploader";
import Button from "components/Button/Button";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import BackButton from "components/Button/BackButton";
import { TypeRights, returnRightsFromName } from "includes/rightType";

function DocsSelector(props) {
	const { account } = useAccount();
	const [uploading, setUploading] = useState(props.curDoc !== true || false);

	function handleUpload(file) {
		setUploading(false);
		if (props.handleUpload) props.handleUpload(file);
	}

	return !uploading ? (
		<>
			<BackButton onClick={props.handleClose}></BackButton>
			<div className="UploadDoc">
				<Button
					className="d-flex justify-content-between align-items-center btn-transparent btn-h-transparent"
					onClick={() => setUploading(true)}
				>
					<div>
						<h5 className="m-0">{t("Files.IMPORT_FROM_PC")}</h5>
					</div>
					<div className="btn-blue upload-btn">
						<FontAwesomeIcon icon={faUpload} />
					</div>
				</Button>
			</div>
			<hr />
			<ExistingDocs {...props} />
		</>
	) : (
		<DocUploader
			typeId={4}
			doc={props.curDoc}
			companyId={account.CompanyId}
			onConfirm={handleUpload}
			onCancel={() => setUploading(false)}
			docsUtilImport={true}
			title={
				<div>
					<h4>{t("Docs.ADD_DOC")}</h4>
				</div>
			}
			className="light-bg"
		/>
	);
}

function ExistingDocs(props) {
	const [search, setSearch] = useState("");
	const { account } = useAccount();

	const handleSearch = (val) => {
		setSearch(val);
	};

	return (
		<div className="ExistingsDocs">
			<div className="mb-2">{t("Docs.EXISTINGS_DOCS")}</div>
			<SearchBar className="w-100 mb-2" onChange={handleSearch} />
			<DocumentsController
				typeId={4}
				companyId={account.CompanyId}
				childComponent={DocsList}
				childProps={{
					search: search,
					selected: props.selected,
					setSelected: props.setSelected,
					mouseover: props.mouseover,
					onItemClick: props.onItemClick,
					noImport: true,
				}}
			/>
		</div>
	);
}

export function DocsList(props) {
	const {
		docs,
		search,
		uploaded,
		setUploaded,
		selected,
		setSelected,
		mouseover,
		onItemClick,
	} = props;

	if (!docs?.length) return false;

	const updateItem = (item) => {
		let index = uploaded.findIndex(
			(a) =>
				(item.TempId && item.TempId === a.TempId) ||
				item.DocumentId === a.DocumentId
		);

		if (index > -1) {
			let temp = [...uploaded];
			temp[index] = item;
			setUploaded(temp);
		}
	};

	const cols = [
		{
			name: "Name",
			title: t("Table.NAME"),
		},
		{
			name: "Aperçu",
			title: t("Table.PREVIEW"),
			sort: false,
			component: PreviewCell,
		},
		{
			name: "Docs utiles",
			title: "Docs utiles",
			sort: false,
			component: DocsUtilesSwitch,
			childProps: {
				updateItem,
			},
			hidden: props.noImport,
		},
		{
			name: "LastChanged",
			title: t("Table.DATE"),
			component: DateCell,
		},
	];

	return (
		<div className="DocsList">
			<ScrollTable
				items={docs.map((a) => {
					if (a.TempId) return { ...a, DocumentId: a.TempId };
					return a;
				})}
				cols={cols}
				itemId={"DocumentId"}
				sortBy="Name"
				className="dark-bg"
				search={search}
				selected={selected}
				setSelected={setSelected}
				checkbox={true}
				mouseover={mouseover}
				onItemClick={onItemClick}
			/>
		</div>
	);
}

function PreviewCell(props) {
	let doc = props.item;

	if (!doc?.Url_Origin) return <div>{t("Commons.ERROR")}</div>;
	if (doc.TempId) return <div>{t("Docs.IMPORTED_DOCS")}</div>;
	return (
		<a
			href={doc.Url_Origin}
			rel="noreferrer"
			onClick={(e) => e.stopPropagation()}
			target="_blank"
		>
			{t("Table.PREVIEW")}
		</a>
	);
}

function DocsUtilesSwitch(props, a, b, c) {
	const { item } = props;
	const { account } = useAccount();
	const checkRight = () => {
		const rigthName = TypeRights.AdminDocument;
		try {
			return returnRightsFromName(account.Rights, rigthName);
		} catch (error) {
			return true;
		}
	};

	if (!item.TempId) return "Non modifiable";
	return (
		<>
			{checkRight() && (
				<SwitchInput
					disabled={!item.TempId}
					checked={!item.TempId || item.AddToDocsUtiles}
					onClick={(e) => {
						// e.preventDefault();
						e.stopPropagation();
					}}
					className="w-100"
					onChange={(val) => {
						item.AddToDocsUtiles = val;
						props.updateItem(item);
					}}
					onlyState={true}
					label={item.TempId ? "Importer" : "Déjà présent"}
				/>
			)}
		</>
	);
}

function DateCell(props) {
	return (
		<Moment format={t("Events.TILE_DATE_FORMAT")}>
			{props.item.LastChanged}
		</Moment>
	);
}

export default DocsSelector;
