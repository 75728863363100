import AuthorPreview from "components/User/AuthorPreview/AuthorPreview";
import { t } from "i18next";
import React from "react";
import {useQuery} from "react-query";
import "./UsersList.css";

export function AsyncUsersList(props) {
	const {queryKey, queryFn} = props;
	const { isLoading, data } = useQuery(queryKey, queryFn, {retry: false});

	if (isLoading)
		return (<div className="userlist-placeholder">{t("Groups.LOADING_USERS")}</div>);
	if (!data?.length)
		return (
			props.hideIfNoData ?
				false
				:
				<div className="userlist-placeholder">{t("Groups.NO_USERS")}</div>
		);

	let items = data;
	if (props.treat)
		items = items.map(props.treat);
	if (props.sort)
		items = items.sort(props.sort);
	else
		items = items.sort((a, b) => (a.LastName < b.LastName ? -1 : 1));
	return (
		<UsersList users={items} {...props}/>
	);
}

function UsersList(props) {
	const {users, max, width, className, total} = props;
	const row_ref = React.createRef();

	if (!users?.length)
		return (props.hideIfNoData ? false : <div>{t("Groups.NO_USERS")}</div>);

	function handleScroll(e) {
		e.stopPropagation();
		if (row_ref?.current)
			row_ref.current.scrollLeft = row_ref.current.scrollLeft + e.deltaY;
	}

	function genList() {
		let ret = [];
		let x = 0;
		for (x = 0; x < users.length; x++)
			if (!max || x < max)
			{
				let user = users[x];
				ret.push(
					<div key={user.EmployesId}>
						<AuthorPreview
							// className="me-2"
							companyId={user.CompanyId}
							employeId={user.EmployesId}
							title={user.FirstName + " " + user.LastName.toUpperCase()}
							imgWidth={width}
							imgRes={"Url_Min"}
						/>
					</div>
				);
			}
			else
				break ;
		return (ret);
	}

	return (
		<div className={"UsersList" + (className ? " " + className : "") }>

			<div className="list gap-2" ref={row_ref} onWheel={handleScroll}>
				{ genList() }
			</div>
			<div className="more-users">
				{
					(users.length > max || total) &&
						<div>
							<div
								title={users.slice(max, users.length).map(a => (a.LastName + " " + a.FirstName)).join("\n")}
								className="more">{ !total ? (users.length - max) + "+" : users.length}
							</div>
						</div>
				}
			</div>
		</div>
	);
}

export default UsersList;
