import React, { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "i18next";
import UserCard from "components/User/Card/UserCard";

export default function UsersTiles(props) {
	let ref = useRef();

	function handleScroll() {
		let el = ref.current?.el;
		if (el.offsetHeight >= el.scrollHeight && props.hasMore)
			props.getNextItems(10);
	}

	React.useEffect(() => {
		let el = ref.current?.el;

		handleScroll();
		el.addEventListener("mousewheel", handleScroll);
		return () => {
			el.removeEventListener("mousewheel", handleScroll);
		};
	}, []);

	return (
		<div
			id="users-cont"
			className={
				"users-cont xd w-100" + (!props.curPost ? " no-cur" : "")
			}
		>
			<InfiniteScroll
				className="user-tiles-scroll d-flex flex-wrap overflow-x-hidden gap-3 gap-md-4"
				ref={ref}
				dataLength={props.displayed?.length} //This is important field to render the next data
				next={props.getNextItems}
				hasMore={props.hasMore}
				loader={<h4>{t("Commons.LOADING")}</h4>}
				height={"100%"}
				endMessage={
					<div className="w-100 infinite-scroll-end-msg mt-4">
						<p style={{ textAlign: "center" }}>
							<b>
								{props.search?.length &&
								!props.displayed?.length
									? t("Commons.NO_RESULT")
									: t("Contacts.LIST_END")}
							</b>
						</p>
					</div>
				}
			>
				{props.displayed.map((user) => {
					return (
						<UserCard
							key={user.EmployesId}
							user={user}
							// isSaved={isSaved}
							// handleSave={this.retrieveFavsUser}
							groupsFilter={props.groupsFilter}
							onClick={props.handleClick}
						/>
					);
				})}
			</InfiniteScroll>
		</div>
	);
}
