export const getRightFromTypeId = (typeId) => {
	switch (typeId) {
		case 1:
			return "AdminNews";
		case 3:
			return "AdminArticle";
		case 4:
			return "AdminAds";
		case 13:
			return "AdminAlert";
		case 14:
			return "AdminProAds";
		case 5:
			return "AdminCareer";
		case 8:
			return "AdminEvent";
		//Fake id to have the same behavior as the others
		case "chartTypeId":
			return "AdminChart";
		case "documentTypeId":
			return "AdminDocument";
		case "formTypeId":
			return "AdminForms";
	}
};
