import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import SearchBar from "components/SearchBar/SearchBar";
import "components/Select/SelectInput.css";
import "./PageHeadBar.css";
import MenuToggler from "components/MenuToggler/MenuToggler";
import Button from "components/Button/Button";
import FiltersDrawer from "./FiltersDrawer/FiltersDrawer";
import DisplayButton from "./DisplayButton/DisplayButton";
import SiteLogo from "Pages/Sites/components/SiteLogo/SiteLogo";
import useAccount from "classes/Accounts/hooks/useAccount";
import { getRightFromTypeId } from "includes/mappingTypeIdName";
import { TypeRights, returnRightsFromName } from "includes/rightType";
import { useAccountContext } from "providers/AccountProvider";
import clsx from "clsx";
import { RssFilter } from "components-v2/Rss/rssFilters.component";

export default function PageHeadBar(props) {
	const { account } = useAccount();
	const [accountRights, setAccountRights] = useState([]);
	const { userOrAdmin } = useAccountContext();
	const [filtersMenu, setFiltersMenu] = useState(false);

	const checkRight = () => {
		const rigthName = getRightFromTypeId(props.typeId);
		if (
			accountRights && (
				rigthName === TypeRights.AdminArticle ||
				rigthName === TypeRights.AdminAlert ||
				rigthName === TypeRights.AdminChart ||
				rigthName === TypeRights.AdminDocument ||
				rigthName === TypeRights.AdminForms ||
				rigthName === TypeRights.AdminEvent
			)
		) {
			try {
				return returnRightsFromName(account.Rights, rigthName);
			} catch (error) {
				return true;
			}
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (account) {
			setAccountRights(account.Rights);
		}
	}, [account]);

	const AddButton = () => (
		<div className="head-bar-btn-cont ms-auto">
			<Button
				className="headbar-add-btn btn btn-primary gap-2"
				onClick={props.handleAdd}
			>
				<div className="add-btn-title">
					{props.addTitle ? props.addTitle : t("Posts.ADD_BTN")}
				</div>
				<FontAwesomeIcon icon={faEdit} />
			</Button>
		</div>
	);

	const genButtons = (buttons) => {
		if (!buttons?.length) return false;
		let ret = buttons.map((a, key) => {
			if (
				!a ||
				a.enabled === false ||
				(a.display && a.display !== props.display)
			)
				return false;
			return (
				<div
					key={key}
					className={
						"head-bar-btn-cont" +
						(a.className ? " " + a.className : "")
					}
					style={a.style}
					title={a.title}
				>
					{a.component ? (
						<a.component {...a.props} />
					) : (
						<Button
							className={
								"headbar-btn btn" +
								(a.classes ? " " + a.classes : "")
							}
							onClick={a.onClick}
						>
							<div>
								{a.icon && (
									<FontAwesomeIcon
										icon={a.icon}
										color={a.iconColor}
									/>
								)}
								{!a.iconOnly ? a.title : false}
							</div>
						</Button>
					)}
				</div>
			);
		});
		return ret;
	};

	const getFiltersCount = () => {
		let count = 0;
		count += props.dispCats?.length;
		if (userOrAdmin === "admin") {
			count += props.favSites?.length;
		}

		if (props.curSite && props.siteSelect)
			if (props.curSite?.length) count += props.curSite?.length;
			else if (props.curSite?.value !== 0) count += 1;
		if (props.filterChilds?.length)
			props.filterChilds.forEach((a) => (count += a.filterCount));
		return count;
	};

	const resetFilters = (e) => {
		e.preventDefault();
		e.stopPropagation();
		props.favSitesSelect([]);
		if (props.resetCatsFilter) props.resetCatsFilter();
		if (props.siteSelect) props.siteSelect(false);
		if (props.filterChilds?.length)
			props.filterChilds.forEach((a) => {
				if (a.resetFilter) a.resetFilter();
			});
	};

	const filtersCount = getFiltersCount();

	return (
		<div className="PageHeadBar d-flex flex-wrap justify-content-between align-items-center gap-1">
			<div
				className={
					"headbar-right-cont d-flex justify-content-between align-items-center flex-wrap mb-lg-0 gap-2" +
					(props.handleAdd ? " w-100" : "")
				}
			>
				{props.menuToggler !== false && (
					<div className="d-flex align-items-center">
						<MenuToggler
							className="me-3"
							isMenuOpen={props.isMenuOpen}
							setIsMenuOpen={props.setIsMenuOpen}
						/>
						<div className="page-title">{props.title}</div>
					</div>
				)}
				{props.logo !== false && (
					<SiteLogo
						companyId={account.CompanyId}
						siteId={account.SiteId}
					/>
				)}
				<div className="d-flex gap-2">
					{props.headButtons && genButtons(props.headButtons)}
					{checkRight() && props.handleAdd && !props.inlineAddBtn && (
						<AddButton />
					)}
				</div>
			</div>
			{props.showRss ? (<RssFilter />) : (
				<div
					className="headbar-left-cont gap-2"
					style={{ flex: !props.handleAdd ? "1 1 50%" : "" }}
				>
					{genButtons(props.buttons)}
					{props.filters && (
						<div>
							<Button
								title="Filtres"
								onClick={() => setFiltersMenu(!filtersMenu)}
								className={
									"filter-button " +
									(filtersMenu || filtersCount > 0
										? "btn-primary"
										: "")
								}
							>
								<FontAwesomeIcon
									className="pe-none"
									icon={faFilter}
								/>
								{filtersCount > 0 && (
									<div className="d-flex ms-2 gap-2 align-items-center">
										<div className="">{filtersCount}</div>
										<div
											className="reset-filters-btn"
											onClick={resetFilters}
											title={"Réinitialiser les sites"}
										>
											<FontAwesomeIcon icon={faTimes} />
										</div>
									</div>
								)}
							</Button>
						</div>
					)}
					{props.display && (
						<DisplayButton
							isDisabled={props.disableDisplayBtn}
							display={props.display}
							setDisplay={props.setDisplay}
						/>
					)}
					{props.search !== false && (
						<div className="search-cont">
							<SearchBar onChange={props.search} timeout={500} />
						</div>
					)}
					{checkRight() && props.handleAdd && props.inlineAddBtn && (
						<AddButton />
					)}
					{props.cont}
				</div>
			)}
			{props.filters && (
				<FiltersDrawer
					{...props}
					hideFavSites={props.hideFavSites}
					favSitesSelect={props.favSitesSelect}
					className="w-100 mt-2"
					isOpen={filtersMenu}
					onClose={() => setFiltersMenu(false)}
				/>
			)}
		</div>
	);
}
