import React, { useState, useEffect, useRef } from "react";
import useDisplay from "hooks/useDisplay";
import useTheme from "hooks/useTheme";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import { t } from "i18next";
import ChartEditDrawer from "./components/ChartEditDrawer/ChartEditDrawer";
import ChartObject from "classes/Charts/ChartObject";
import useAccount from "classes/Accounts/hooks/useAccount";
import ChartsController from "classes/Charts/ChartsController";
import "./ChartsPage.css";
import PreviewDrawer from "components/PreviewDrawer/PreviewDrawer";
import ChartPreviewDrawer from "./components/ChartPreviewDrawer/ChartPreviewDrawer";
import useEditing from "components/EditDrawer/useEditing";
import { useQueryClient } from "react-query";
import ChartTile from "components/Charts/Tile/ChartTile";
import ChartsTable from "components/Charts/ChartsTable/ChartsTable";

export default function ChartsPage(props) {
	const { display, setDisplay } = useDisplay("tiles");
	const { account, isAdmin } = useAccount();
	const [DispState, setDispState] = useState("published");
	const [CurObj, setCurObj] = useState(false);
	const [CurChart, setCurChart] = useState(false);
	const LastDisplay = useRef();
	const LastPlatform = useRef();
	const { setEditing } = useEditing();

	const QCL = useQueryClient();

	useEffect(() => {
		LastDisplay.current = props.display;
		if (props.platform !== LastPlatform.current) {
			if (props.platform === "admin" && display !== "table")
				setDisplay("table");
			else if (props.platform !== "admin" && display !== "tiles")
				setDisplay("tiles");
		}
		LastPlatform.current = props.platform;
	}, []);

	function handleAdd() {
		let obj = new ChartObject({
			CompanyId: account.CompanyId,
		});
		setCurObj(obj);
		setEditing(true);
	}

	function handleModify(chart) {
		let obj = new ChartObject(chart);
		setCurObj(obj);
		setEditing(true);
	}

	const closeEdit = () => {
		CurObj.isDraft() ? setDispState("drafts") : setDispState("published");
		setCurObj(false);
		QCL.refetchQueries("Charts");
	};

	function toggleDrafts() {
		if (DispState !== "drafts") {
			setDispState("drafts");
			setDisplay("table");
		} else setDispState("published");
	}

	function dispDrafts() {
		if (CurObj) closeEdit();
		setDispState("drafts");
	}

	const disableDisplayBtn = () => {
		return DispState === "drafts" && display === "table";
	};

	return (
		<div
			className={
				"charts-cont scroll-cont no-cur" +
				(!props.charts?.length ? " flex-column" : "")
			}
		>
			<PageHeadBar
				//Fake id to add the same behavior as the other pages
				typeId={"chartTypeId"}
				siteSelect={false}
				title={props.title}
				search={false}
				isMenuOpen={props.isMenuOpen}
				setIsMenuOpen={props.setIsMenuOpen}
				addTitle={t("Charts.ADD_CHART")}
				handleAdd={isAdmin() ? handleAdd : false}
				display={isAdmin() ? display : false}
				setDisplay={isAdmin() ? setDisplay : false}
				disableDisplayBtn={isAdmin() ? disableDisplayBtn() : false}
				buttons={[
					{
						title: t("Posts.DRAFT_BTN"),
						classes: DispState === "drafts" ? "btn-primary" : "",
						onClick: toggleDrafts,
						enabled: props.drafts !== false && isAdmin(),
					},
				]}
			/>
			<ChartsController
				companyId={account.CompanyId}
				typeId={1}
				childComponent={ChartsList}
				showDrafts={DispState === "drafts"}
				childProps={{
					display,
					showDrafts: DispState === "drafts",
					setCurChart,
					curChart: CurChart,
				}}
			/>
			{CurObj && (
				<ChartEditDrawer
					chart={CurObj}
					onClose={closeEdit}
					dispDrafts={dispDrafts}
					initQueries={[
						{
							queryKey: ["ChartEdit", "FetchForEdit", Date.now()],
							queryFn: () => CurObj.fetchForEdit(),
							enabled: CurObj.chartId() > 0,
						},
					]}
				/>
			)}
			{display === "table" && CurChart && (
				<PreviewDrawer
					slideRight={true}
					isOpen={true}
					child={ChartPreviewDrawer}
					childProps={{
						chart: CurChart,
						handleModify,
						onDelete: () => setCurChart(false),
					}}
					onClose={() => setCurChart(false)}
				/>
			)}
		</div>
	);
}

function ChartsList(props) {
	if (props.display === "table") return <ChartsTable {...props} />;
	return <ChartsTiles {...props} />;
}

function ChartsTiles({ charts }) {
	const { theme } = useTheme();

	if (!charts?.length) return <div>{t("Charts.NO_CHART")}</div>;
	document
		.querySelectorAll("style[id*='chartId-']")
		.forEach((a) => a.remove());

	return (
		<div className="ChartsTiles">
			{charts.map((chart) => {
				if (chart.IsDraft) return false;
				return (
					<ChartTile data={chart} theme={theme} key={chart.ChartId} />
				);
			})}
		</div>
	);
}
