import React, { useEffect } from "react";
import { useQuery } from "react-query";
import "./Page.css";
import "components/Tiles/Tiles.css";
import useAccount from "classes/Accounts/hooks/useAccount";
import PageLoad from "components/PageLoad/PageLoad";
import Welcome from "components/Welcome/Welcome";
import useTemplate from "hooks/useTemplate";
import ServError from "components/ServError/ServError";
import { t } from "i18next";

export default function Page(props) {
	const { account, disconnect, isAdmin, isSup, fetchInfos, setAccount } =
		useAccount();
	const { template, getAppFunction } = useTemplate();

	const { data, error, isLoading } = useQuery(
		["Account", "UserInfo", account.CompanyId],
		() => fetchInfos(!account.IsTemp),
		{ refetchOnWindowFocus: false }
	);

	// const tweekRights = (rights) => {
	// 	return rights.map((right) => {
	// 		if (right.Name === "AdminForms") {
	// 			return {
	// 				...right,
	// 				IsAdminRight: false,
	// 			};
	// 		}
	// 		return right;
	// 	});
	// 	return rights.filter((right) => right.Name !== "AdminAds");
	// };

	useEffect(() => {
		if (data && !isLoading) {
			setAccount({
				...account,
				Role: data.Role,
				Rights: data.Rights,
				Infos: data.Infos,
			});
		}
	}, [data]);

	const { isMenuOpen, setIsMenuOpen } = props;
	if (error) {
		disconnect();
		return false;
	}

	if (isLoading)
		return <PageLoad color={template?.primaryColor()} text={<Welcome />} />;

	if (
		props.curPlatform &&
		props.platform &&
		props.curPlatform !== props.platform &&
		!isAdmin()
	)
		return (
			<ServError
				title={t("Desktop.ERROR") + " 401"}
				text={t("Desktop.UNAUTHORIZED")}
			/>
		);
	return (
		<div className={"page-cont " + props.name + "-page-cont"}>
			{props.component && (
				<props.component
					role={data.Role}
					rights={data.Rights}
					platform={props.curPlatform}
					account={account}
					pages={props.items}
					isAdmin={isAdmin()}
					isSup={isSup}
					title={
						props.adminTitle && props.curPlatform === "admin"
							? props.adminTitle
							: props.title
					}
					theme={props.theme}
					baseUrl={props.url}
					curPage={props.curPage}
					template={template}
					getAppFunction={getAppFunction}
					isMenuOpen={isMenuOpen}
					setIsMenuOpen={setIsMenuOpen}
					setLangCode={props.setLangCode}
					{...props.childprops}
				/>
			)}
		</div>
	);
}
