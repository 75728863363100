import useAccount from "classes/Accounts/hooks/useAccount";
import SiteLogo from "Pages/Sites/components/SiteLogo/SiteLogo";
import React from "react";
import ReactModal from "react-modal";
import "./IntroPopper.css"
import { hexToCSSFilter } from "hex-to-css-filter";
import Button from "components/Button/Button";
import { useState } from "react";
import useTemplate from "hooks/useTemplate";
import UIHelperTour from "Pages/Tutorials/tours/general/UIHelperTour";
import { useQueryClient } from "react-query";

export default function IntroPopper(props) {
	const QCL = useQueryClient();
	const {account, getSettings, setSettings} = useAccount();
	const {template} = useTemplate();
	const [IsOpen, setIsOpen] = useState(!getSettings("IntroPopperReaded") || false);
	const [IsLaunched, setIsLaunched] = useState(false);

	const handleSkip = () => {
		setSettings("IntroPopperReaded", true);
		setIsOpen(false);
	}

	const launchTour = () => {
		setSettings("IntroPopperReaded", true);
		setIsOpen(false);
		setIsLaunched(true);
		QCL.setQueryData(["Helper", "CurrentTour"], UIHelperTour)
	}

	const hexFilter = hexToCSSFilter(template.primaryColor(), {
		acceptanceLossPercentage: 5,
		forceFilterRecalculation: true
	})

	if (!IsOpen || IsLaunched || !getSettings("Tutorials"))
		return (false);
	return (
		<ReactModal
			isOpen={IsOpen}
			style={{
				overlay: {
					overflow: "auto",
					backgroundColor: "rgba(var(--background-color-secondary-rgb), 0.7)",
				},
				content: {
					padding: 0,
					margin: "auto",
					marginTop: "5vh",
					marginBottom: "5vh",
					width: "fit-content",
					height: "fit-content"
				}
			}}
		>
			<div className="IntroPopper">
				<div className="head">
					<div className="bg"style={{
						filter: hexFilter.filter.replace(";", "")
					}}></div>
					<SiteLogo siteId={account.SiteId} companyId={account.CompanyId}/>
				</div>
				<div className="body">
					<div className="title w-100 text-center">
						Bienvenue sur OnlyAll !
					</div>
					<div className="intro-text mt-4 text-center">
						Pas besoin d’être un geek.<br/>
						Aussi facile à utiliser qu’à administrer, sa prise en main ne prend que quelques minutes !<br/>
						En un mot l’essayer, c’est l’adopter !<br/>
						N'ayez pas peur et laissez vous guider<br/>
						Nous serons toujours là pour vous accompagner.<br/>
					</div>
					<div className="mt-3 text-center tuto-text">
						Cliquez sur suivant pour suivre le tutoriel d'introduction
					</div>
					<div className="d-flex justify-content-between mt-4">
						<Button className="w-auto" onClick={handleSkip}>Passer</Button>
						<Button className="w-auto btn-primary" onClick={launchTour}>Suivant</Button>
					</div>
				</div>
			</div>
		</ReactModal>
	)
}
