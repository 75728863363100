import React from "react";
import Account from "classes/Accounts/Account/Account";
import useAccounts from "classes/Accounts/hooks/useAccounts";
import {motion} from "framer-motion";
import "./AccountsList.css";
import { t } from "i18next";
import Button from "components/Button/Button";

export default function AccountsList({onClick, active, className, refreshList}) {
	const {getAccounts, removeAccount} = useAccounts();
	let disp_accounts = getAccounts();

	if (!disp_accounts?.length)
		return (false);

	function newAccount() {
		onClick({});
	}

	function handleRemove(login) {
		removeAccount(login);
		refreshList();
	}

	const itemAnim = {
		hidden: {
			opacity: 0,
			zIndex: 3,
			width: 0,
			height: 0,
			transition: {
				duration: 0.5,
				ease: "easeInOut"
			}
		},
		show: {
			opacity: 1,
			zIndex: 3,
			width: "auto",
			height: "auto",
			transition: {
				duration: 0.5,
				ease: "easeInOut"
			}
		}
	};

	return (
		<>
			<div className={"AccountsList" + (className ? " " + className : "") + (active ? " hasActive" : "")}>
				{
					disp_accounts.map((a) => {
						let isActive = active?.Login === a.Login;
						return (
							<motion.div
								key={a.Login}
								initial={active && !isActive ? "hidden" : "show"}
								animate={(!active || isActive) ? "show" : "hidden"}
								variants={itemAnim}
							>
								<Account
									account={a}
									onClick={() => onClick(a)}
									handleRemove={() => handleRemove(a.Login)}
									isActive={isActive}
								/>
							</motion.div>
						);
					})
				}
			</div>
			{!active && <Button className="unstyled text-center" onClick={newAccount}>{t("Accounts.ADD_ACCOUNT")}</Button>}
		</>
	);
}
