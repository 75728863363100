import React, {useState} from "react";
import {useQuery} from "react-query";
import useAccount from "classes/Accounts/hooks/useAccount";
import { fetchImages } from "../ImagesIncludes";
import "./TileImage.css";

export default function TileImage({postId, postTypeId, children}) {
	const { account } = useAccount();
	const [image, setImage] = useState(false);
	const [imgIndex, setImgIndex] = useState(0);
	const [imgError, setImgError] = useState(false);

	function handleError(e) {
		if (imgIndex < 3)
			setImgIndex(imgIndex + 1);
		else {
			e.target.remove();
			setImgError(true);
		}
		e.target.onError = handleError;
	}

	function handleLoad(e) {
		e.target.remove();
	}

	function getImage()
	{
		if (!data || !image)
			return ;
		let indexs = ["Url_Mobile", "Url_Origin", "Url_Min", "Url_Default"];
		let img = image[indexs[imgIndex]];
		if (img)
			return (img);
		return ("image error");
	}

	const { isLoading, data, error } = useQuery(
		["Documents", "DocumentOfPostAndType", postId, 3],
		() => fetchImages(account.CompanyId, postTypeId, postId),
		{
			onSuccess: (resp) => {
				if (!image || image.Url_Origin !== resp[0]?.Url_Origin)
					setImage(resp[0]);
				return (resp[0]);
			},
			enabled: (postId && postTypeId !== 13) ? true : false,
		}
	);

	if (error || imgError)
		return (false);
	if (isLoading)
		return (
			<div className="TileImage loading">
				<div className="w-100 h-100"></div>
			</div>
		);
	if (postTypeId === 13)
		return (false);
	return (
		<div className="TileImage" key={imgIndex}>
			<img loading="lazy" className="d-none" alt="post-img" src={getImage()} onLoad={handleLoad} onError={handleError}/>
			<div className="post-image w-100" alt="post-img" style={{backgroundImage: "url(" + getImage() + ")"}}/>
			{children}
		</div>
	);
}
