import React, { useState } from "react";
import { t } from "i18next";
import CategoriesTypesDropDown from "../CategoriesTypesDropDown/CategoriesTypesDropDown";
import Button from "components/Button/Button";
import Modal from "react-modal";
import BackButton from "components/Button/BackButton";
import genRequest from "includes/request";
import { useQuery, useQueryClient } from "react-query";
import useAccount from "classes/Accounts/hooks/useAccount";
import LoadCont from "components/LoadCont/LoadCont";
import TagLabel from "components/Tags/Label/TagLabel";
import SelectionList from "components/SelectionList/SelectionList";
import CategoryObject from "classes/Categories/CategoryObject";
import promiseToast from "components/Toasts/PromiseToast";

const CategoriesHighlight = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setIsOpen(!isOpen)}>Mise en avant</Button>
			{isOpen && (
				<HGModal
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					curType={props.curType}
				/>
			)}
		</>
	);
};

const HGModal = (props) => {
	const QCL = useQueryClient();
	const { isOpen, setIsOpen } = props;
	const { account } = useAccount();
	const [CurType, setCurType] = useState(
		props.curType?.value !== "ALL"
			? props.curType
			: {
					label: t("Categories.TYPE-post-1"),
					value: "post-1",
			  }
	);

	const fetch = () =>
		genRequest(
			"Categories/CompanyTyped/" + account.CompanyId + "/" + CurType.value
		);

	const { isLoading, isFetching, data } = useQuery(
		["CategoriesHG", "CompanyTyped", account.CompanyId, CurType.value],
		fetch,
		{
			refetchOnWindowFocus: false,
		}
	);

	const treatData = (cats) => {
		let ret = cats.map((a) => {
			return {
				...a,
				id: "itemId-" + a.CategoryId,
				active: a.IsFavorite,
				content: (
					<TagLabel
						style={{ fontSize: "0.7rem" }}
						color={a.DefaultHexaColor}
						text={a.Name}
					/>
				),
			};
		});
		return ret;
	};

	const handleChange = (selected) => {
		let proms = selected.map((a, index) => {
			let obj = new CategoryObject({
				...a,
				IsFavorite: a.active || false,
				FavoritePosition: index + 1,
			});
			return obj.sendCat();
		});
		let ret = Promise.allSettled(proms).then((resp) => {
			QCL.resetQueries(["Categories"]);
		});
		promiseToast(ret, { toastId: "SendFavCats", autoClose: 3000 });
		return ret;
	};

	return (
		<Modal isOpen={isOpen}>
			<div className="d-flex flex-column gap-2">
				<div className="d-flex gap-2 align-items-center mb-2">
					<BackButton onClick={() => setIsOpen(false)} />
					<div>Mettre en avant des catégories</div>
				</div>
				<div>
					Sélectionnez jusqu'a 4 catégories par type a mettre en avant
					sur les pages correspondantes
				</div>
				<hr />
				<div>
					<CategoriesTypesDropDown
						title="Type de catégories"
						className="light-bg"
						curType={CurType}
						setCurType={setCurType}
						onlyHighlightable={true}
					/>
				</div>
				<div className="mt-4">
					{isLoading || isFetching ? (
						<LoadCont />
					) : (
						<SelectionList
							max={4}
							items={treatData(data)}
							onChange={handleChange}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default CategoriesHighlight;
