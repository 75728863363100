import React from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import "./UserCard.css";
import UserImage from "../Image/UserImage";
import genRequest from "includes/request";
import { QueryTagsList } from "components/Tags/TagsList/TagsList";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import SaveUserBtn from "../SaveUserBtn/SaveUserBtn";
import { t } from "i18next";
import TagLabel from "components/Tags/Label/TagLabel";
import { dateAdd } from "includes/formatDate";
import useAccount from "classes/Accounts/hooks/useAccount";

function UserCard(props) {
	let user = props.user;

	function handleClick() {
		if (props.onClick)
			props.onClick(user);
	}

	const retrieveGroups = () => {
		let req = genRequest("Groups/GroupOfEmploye/" + user?.EmployesId);
		return (req);
	};

	const {data, isLoading} = useQuery(
		["Groups", "GroupsOfEmploye", user?.EmployesId],
		retrieveGroups,
		{
			enabled: (user && user.EmployesId) ? true : false
		}
	);

	const minimal_req = useQuery(
		["Employes", "MinimalContactEmployesFromId", user?.EmployesId],
		() => genRequest("Employes/MinimalContactEmployesFromId/" + user?.EmployesId),
		{
			enabled: (user && user.EmployesId) ? true : false
		}
	);

	if (!user && !props.isLoading)
		return (false);

	if (isLoading || props.isLoading)
		return (
			<div className={"user-card tile loading" + (props.className ? " " + props.className : "")} >
				<div className="w-100"></div>
				<div className="w-25"></div>
				<div className="w-50"></div>
			</div>
		);

	let filter = props.groupsFilter;
	let group_check = true;
	let groups = data;

	user.Groups = data;

	if (filter?.length)
	{
		group_check = filter.every((filter_group) => {
			let check = false;
			if (!groups)
				return (false);
			groups.map((a) => {
				if (filter_group === a.GroupId)
					check = true;
				return (false);
			});
			return (check);
		});
	}
	if (!group_check)
		return (false);
	return (
		<div className={"user-card tile" + (props.className ? " " + props.className : "")} onClick={handleClick}>
			<div className="d-flex align-items-start justify-content-between mb-2">
				<div className="d-flex">
					<div className="me-2 position-relative">
						<UserImage
							companyId={user.CompanyId}
							employeId={user.EmployesId}
							res={"Url_Min"}
							// title={user.FirstName + " " + user.LastName}
						/>
						<OnlineStatus employeId={user?.EmployesId} lastConnexion={user?.LastConnexion}/>
					</div>
					<div className="user-contacts d-flex flex-column justify-content-center">
						{
							user.Display_Mail && user.Mail &&
								<div className="user-mail"><a href={"mailto:" + user.Mail}><FontAwesomeIcon className="user-contact-icon" icon={faEnvelope}/></a></div>
						}
						{
							user.Display_Phone && user.Telephone &&
								<div className="d-flex align-items-center">
									<div className="user-whatsapp"><a href={"whatsapp:" + user.Telephone}><FontAwesomeIcon className="user-contact-icon" icon={faWhatsapp}/></a></div>
									<div className="user-phone"><a href={"tel:" + user.Telephone}><FontAwesomeIcon className="user-contact-icon" icon={faPhoneAlt}/></a></div>
								</div>
						}
					</div>
				</div>
				<div>
					<Role role={user?.Role}/>
					<SaveUserBtn className={"unstyled ms-auto"} userId={user.EmployesId}/>
				</div>
			</div>
			<div className="user-infos">
				<div className="user-site">
					<InfosFetcher
						queryFn={() => genRequest("Sites/" + user.SiteId)}
						queryKey={["Sites", user.SiteId]}
						treat={(infos) => {
							let town = infos?.Town?.trim();
							return (infos.Name + (town?.length ? (" - " + town) : ""));
						}}
					/>
				</div>
				<div className="user-fullname font-bold">
					{(user.FirstName.toLocaleLowerCase() + " " + user.LastName.toUpperCase())}
				</div>
				<div className="user-work-function mt-1">{minimal_req.data?.WFU}</div>
				<div className="user-work-family">{minimal_req.data?.WFY}</div>
			</div>
			<QueryTagsList
				className="pt-2 mt-auto overflow-auto"
				queryKey={["Groups", "GroupsOfEmploye", user.EmployesId]}
				queryFn={() => genRequest("Groups/GroupOfEmploye/" + user.EmployesId)}
				enabled={(user && user.EmployesId) ? true : false}
			/>
		</div>
	);
}

export function OnlineStatus({lastConnexion, delayInMinutes, employeId}) {
	const {account} = useAccount();

	if (!lastConnexion)
		return (false);
	let isOnline = new Date(lastConnexion) > dateAdd(new Date(), {minutes: (delayInMinutes ? (delayInMinutes * -1) : -5)});
	if (isOnline || employeId === account.EmployesId)
		return (
			<div
				className="OnlineStatus"
				title={t("Contacts.ONLINE")}
			/>
		);
	return (false);
}

function Role({role}) {
	if (!role)
		return (false);
	let text = t("Contacts.USER_ROLE");
	let color = "grey";
	let isDev = false;

	if (["SiteAdmin", "SuperAdmin"].indexOf(role) !== -1)
	{
		text = t("Contacts.ADMIN_ROLE");
		color = "red";
	} else if (["EloAdmin"].indexOf(role) !== -1)
	{
		text = t("Contacts.ELOADMIN");
		isDev = true;
	} else
		return (false);
	return (
		<TagLabel className={"user-role" + (isDev ? " rainbow" : "")} color={color} text={text}/>
	);
}

export function AsyncUserCard({employeId}) {
	const {data, isLoading} = useQuery(
		["Employes", employeId],
		() => genRequest("Employes/" + employeId),
		{
			enabled: employeId ? true : false
		}
	);

	return (
		<UserCard user={data} isLoading={isLoading}/>
	);
}

export default UserCard;
