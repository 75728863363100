import CategoryObject from "classes/Categories/CategoryObject";
import DocumentObject from "classes/Documents/DocumentObject";
import documents_treat from "includes/documents_treat";
import formatDate from "includes/formatDate";
import noAccent from "includes/no_accents";
import genRequest from "includes/request";
import generateUUID from "includes/UUID";

export default function PostObject(post) {
	let INIT_DATE = formatDate();
	let that = this;

	let PostId;
	let PostTypeId;
	let SubTitle;
	let Title;
	let EmployesId;
	let Txt;
	let SiteId;
	let ValidationModeration;
	let Draft;
	let Adress;
	let ViewCount;
	let LikesCount;
	let PublishedDate = INIT_DATE;
	let PriorityTo = INIT_DATE;
	let ModerationComment;
	let LastChanged;
	let LastChange_AuthorId;
	let HasDuplicate;
	let IsTargetPost;
	let LanguageId;

	let IsRejected = false;
	let Account = post?.account;
	let NotifyPost =
		typeof post?.NotifyPost !== "undefined" ? post.NotifyPost : true;
	let CompanyId = post?.CompanyId !== undefined ? post.CompanyId : null;
	let RssImages = [];
	let Images = [];
	let MDE_Ref = false;
	let LinkType = "sites";
	let LinkTo = [];
	let LinkedTo = [];
	let Categories = [];
	let LinkedSites = [];
	let LinkedGroups = [];
	let LinkedForms = [];
	let Targets = [];

	let ReactionType = false;
	let Response = false;

	let Docs = [];

	this.setProps = function (props, force_notif) {
		PostId = props && props.PostId !== undefined ? props.PostId : undefined;
		PostTypeId =
			props && props.PostTypeId !== undefined ? props.PostTypeId : null;

		if (PostTypeId === 3 && props.Title) {
			let split = props.Title.split(/\/__CUT__\\/gi);
			SubTitle = split[0];
			Title = split[1];
		} else Title = props?.Title !== undefined ? props.Title : "";
		EmployesId = props?.EmployesId !== undefined ? props.EmployesId : null;
		Txt = props?.Txt !== undefined ? props.Txt : "";
		SiteId = props?.SiteId !== undefined ? props.SiteId : null;
		ValidationModeration =
			props?.ValidationModeration !== undefined
				? props.ValidationModeration
				: false;
		Draft = props?.Draft !== undefined ? props.Draft : false;
		Adress = props?.Adress !== undefined ? props.Adress : "DesktopV2";
		ViewCount = props?.ViewCount !== undefined ? props.ViewCount : 0;
		LikesCount = props?.LikesCount !== undefined ? props.LikesCount : 0;
		PublishedDate =
			props?.PublishedDate !== undefined
				? props.PublishedDate
				: formatDate();
		PriorityTo = props?.PriorityTo !== undefined ? props.PriorityTo : null;
		ModerationComment =
			props?.ModerationComment !== undefined
				? props.ModerationComment
				: null;
		LastChanged =
			props?.LastChanged !== undefined ? props.LastChanged : null;
		LastChange_AuthorId =
			props?.LastChange_AuthorId !== undefined
				? props.LastChange_AuthorId
				: null;
		HasDuplicate =
			props?.HasDuplicate !== undefined ? props.HasDuplicate : false;
		IsTargetPost =
			props?.IsTargetPost !== undefined ? props.IsTargetPost : false;
		LanguageId = props?.LanguageId !== undefined ? props.LanguageId : 1;

		IsRejected = props?.IsRejected !== undefined ? props.IsRejected : false;

		ReactionType =
			props?.ReactionType !== undefined
				? props.ReactionType
				: ReactionType;

		if (typeof force_notif !== "undefined") NotifyPost = force_notif;
		else {
			if (props?.NotifyPost) NotifyPost = true;
			else NotifyPost = PostId ? false : true;
		}
	};

	this.setProps(post);

	this.fetchForEdit = (asClone) => {
		if (!PostId) return Promise.resolve(true);
		// return (Promise.allSettled([
		// 	this.fetchReactionType()
		// ]));
		return Promise.allSettled([
			this.fetchLinkedGroups(),
			this.fetchLinkedSites(),
			this.fetchResponse(),
			this.fetchImages().then(() => that.fetchImagesFile(asClone)),
			this.fetchCategories(),
			this.fetchLinkedDocs(asClone),
			this.fetchLinkedForms(),
			this.fetchTargets(),
			this.fetchReactionType(),
		]);
	};

	this.fetchForDuplicate = () => {
		let prom = this.fetchForEdit(true).then(() => {
			PostId = false;
			if (Response) this.setResponse("", null, true);
		});
		return prom;
	};

	this.fetchAuthor = () => {
		if (!EmployesId) return Promise.reject(false);
		let req = genRequest("Employes/" + EmployesId).then((resp) => {
			Account = resp;
			return resp;
		});
		return req;
	};

	this.fetchTargets = () => {
		if (PostTypeId !== 13) return Promise.resolve([]);
		let req = genRequest("LtEmployeAlerts/PostId/" + PostId).then(
			(resp) => {
				if (resp.length) Targets = resp;
				else Targets = [];
			},
			() => {
				Targets = [];
			}
		);
		return req;
	};

	this.fetchLinkedSites = () =>
		genRequest("LtPostSites/PostId/" + PostId).then(
			(resp) => {
				LinkedSites = resp?.length ? resp : [];
				if (resp?.length) LinkType = "sites";
			},
			() => {
				LinkedSites = [];
			}
		);

	this.fetchResponse = () =>
		genRequest("Posts/ResponseOfPost/" + PostId)
			.then((resp) => {
				Response = resp ? new PostObject(resp) : false;
			})
			.catch(() => (Response = false));

	this.fetchLinkedGroups = () =>
		genRequest("Groups/GroupOfPost/" + PostId).then(
			(resp) => {
				LinkedGroups = resp?.length ? resp : [];
				if (resp?.length) LinkType = "groups";
				return resp;
			},
			() => {
				LinkedGroups = [];
				return [];
			}
		);

	this.fetchLinkedDocs = (asClone) =>
		genRequest("Documents/DocumentOfPostAndType/" + PostId + "/4")
			.then((resp) => {
				if (!resp?.length) {
					Docs = [];
					return Docs;
				}
				Docs = resp.map((a) => {
					let doc = { ...a };
					if (asClone) {
						doc.OriginalDocumentId = false;
						doc.DocumentId = false;
						doc.IsNew = true;
						doc.IsCloned = true;
						doc.DocumentId = false;
					}
					return new DocumentObject(doc);
				});
				return Docs;
			})
			.catch(() => {
				return [];
			});

	this.fetchLinkedForms = () =>
		genRequest("Forms/FormAffectedToPost/" + PostId)
			.then((resp) => {
				LinkedForms = resp?.length ? resp : [];
			})
			.catch(() => {
				LinkedForms = [];
			});

	this.fetchImages = () =>
		genRequest("Documents/DocumentOfPostAndType/" + PostId + "/3")
			.then((resp) => {
				Images = documents_treat(resp, "Name").map((a) => {
					a.OriginalDocumentId = a.DocumentId;
					return a;
				});
				return Images;
			})
			.catch(() => {
				return [];
			});

	this.fetchImagesFile = (cloned) => {
		if (!Images?.length) return Promise.resolve(true);
		let proms = Images.map((a) => {
			return fetchDocumentFile(a.DocumentId).then((resp) => {
				a.DocumentFile = resp;
				if (cloned) {
					a.OriginalDocumentId = false;
					a.DocumentId = false;
					a.IsNew = true;
					a.IsCloned = true;
				}
			});
		});
		return Promise.all([proms]);
	};

	const fetchDocumentFile = (documentId) =>
		genRequest("Documents/DocFileOfDoc/" + documentId).then(
			(documentFile) => {
				return documentFile;
			}
		);

	this.fetchDefaultReactionType = () =>
		genRequest(
			`LT_DefaultReactionOfPostForCompany/ofPostTypeIdAndCompanyId/${PostTypeId}/${CompanyId}`
		).then((resp) => {
			ReactionType = resp?.length
				? { ReactionTypeId: resp[0].ReactionTypeId }
				: false;
			return resp;
		});

	this.fetchReactionType = () => {
		let req = genRequest("Lt_PostAvailableReaction/PostId/" + PostId).then(
			(resp) => {
				if (!resp?.length) return this.fetchDefaultReactionType();
				ReactionType = resp?.length ? resp[0] : false;
				return resp;
			},
			() => {
				return this.fetchDefaultReactionType();
			}
		);
		return req;
	};

	this.unlinkReactionType = () =>
		genRequest("Lt_PostAvailableReaction/PostId/" + PostId, null, "delete");

	this.linkReactionType = () => {
		if (!ReactionType?.ReactionTypeId) return Promise.resolve(true);
		let req = genRequest(
			"LT_Post_Available_Reaction",
			{
				ReactionTypeId: ReactionType.ReactionTypeId,
				PostId,
			},
			"post"
		);
		return req;
	};

	this.send = function () {
		let prom = new Promise((resolve, reject) => {
			let xhr = that
				.sendPost()
				.then(() => {
					let proms = [
						that.unlinkSites(),
						that.unlinkGroups(),
						that.unlinkCategories(),
						that.unlinkForms(),
						that.unlinkDocs(),
						that.unlinkResponse(),
						that.unlinkReactionType(),
					];
					return Promise.allSettled(proms);
				}, reject)
				.then(() => {
					if (LinkType === "sites") return that.duplicateOnSites();
					return that.duplicateOnGroups();
				}, reject)
				.then(() => {
					return that.linkCategories();
				}, reject)
				.then(() => {
					return that.linkReactionType();
				}, reject)
				.then(() => {
					return that.sendReponse();
				}, reject)
				.then(() => {
					resolve(true);
				}, reject);
			return xhr;
		});
		return prom;
	};

	this.autoSave = function () {
		let prom = new Promise((resolve) => {
			let xhr = that
				.send()
				.then(() => {
					let proms = [that.sendImages(), that.sendDocs()];
					return Promise.allSettled(proms);
				})
				.then(() => {
					resolve(that);
				});
			return xhr;
		});
		return prom;
	};

	this.sendPost = function () {
		let post = {
			PostId,
			PostTypeId,
			Title: PostTypeId === 3 ? SubTitle + "/__cut__\\" + Title : Title,
			EmployesId,
			Txt,
			SiteId,
			ValidationModeration,
			Draft,
			Adress,
			ViewCount,
			LikesCount,
			PublishedDate,
			PriorityTo,
			ModerationComment,
			LastChanged: formatDate(),
			LastChange_AuthorId,
			HasDuplicate: LinkTo?.length ? true : false,
			LanguageId,
			IsTargetPost:
				LinkType === "groups" && LinkTo?.length ? true : IsTargetPost,
		};
		if (!PostId) delete post.PostId;
		let prom = genRequest(
			"Posts" + (PostId ? "/" + PostId : ""),
			post,
			PostId ? "put" : "post"
		).then((resp) => {
			if (!PostId) {
				let notify = NotifyPost && ValidationModeration && !Draft;
				that.setProps(resp, notify);
			}
		});
		return prom;
	};

	this.rejectPost = function (reason) {
		if (!reason?.length)
			return Promise.reject("Reject reason is requiered");
		this.moderationComment(reason);
		let prom = this.sendPost().then(() => this.notify());
		return prom;
	};

	this.unlinkSites = () =>
		genRequest("LtPostSites/PostId/" + PostId, null, "delete");

	this.duplicateOnSites = function () {
		if (!LinkTo?.length) return true;
		let prom = genRequest(
			"Posts/PostDuplicateOnSites",
			{
				OriginalpostId: PostId,
				OriginalDocumentId: generateUUID(),
				SiteToPostDuplicate: LinkTo,
				Notify: NotifyPost,
			},
			"post"
		);
		return prom;
	};

	this.unlinkGroups = function () {
		if (Account.Role === "UserPublisher") return Promise.resolve(true);
		let req = genRequest(
			"Lt_PostTargetedToGroup/postId/" + PostId,
			null,
			"delete"
		);
		return req;
	};

	this.duplicateOnGroups = function () {
		if (!LinkTo?.length) return Promise.resolve(true);
		let prom = genRequest(
			"Posts/PostOnSeveralGroups",
			{
				OriginalpostId: PostId,
				OriginalDocumentId: generateUUID(),
				GroupToPost: LinkTo,
				Notify: NotifyPost,
			},
			"post"
		);
		return prom;
	};

	this.fetchCategories = () =>
		genRequest("Categories/Post/" + PostId).then((resp) => {
			Categories = !resp?.length ? [] : resp;
			return resp;
		});

	this.unlinkCategories = () =>
		genRequest("LtPostCategories/PostId/" + PostId, null, "delete");

	this.linkCategories = function () {
		let proms = [];
		if (Categories?.length)
			proms = Categories.map((a) => {
				if (a.CategoryId) return this.linkCategory(a.CategoryId);
				return this.addCategory(a).then((resp) =>
					this.linkCategory(resp.CategoryId)
				);
			});
		return Promise.all(proms);
	};

	this.addCategory = function (cat) {
		let obj = new CategoryObject({
			Name: cat.Name,
			CategoryType: "post-" + PostTypeId,
			Description: "",
			CompanyId: CompanyId,
		});
		return obj.send();
	};

	this.linkCategory = (catId) =>
		genRequest(
			"LtPostCategories",
			{
				CategoryId: catId,
				PostId: PostId,
			},
			"post"
		);

	this.attachements = () => [...Docs, ...LinkedForms];

	this.linkAttachements = function () {
		let to_link = this.attachements();

		if (!to_link?.length) return Promise.resolve(true);
		let proms = [this.sendDocs(), this.linkForms()];
		return Promise.all(proms);
	};

	this.unlinkForms = () => {
		if (Account.Role === "UserPublisher") return Promise.resolve(true);
		return genRequest(
			"Lt_FormAffectation/AffectationOfPost/" + PostId,
			null,
			"delete"
		);
	}

	this.linkForms = function () {
		if (!LinkedForms?.length) return Promise.resolve(true);
		let proms = LinkedForms.map((a) => this.linkForm(a.FormId));
		return Promise.all(proms);
	};

	this.linkForm = (formId) =>
		genRequest(
			"LT_Form_Affectation",
			{
				PostId,
				FormId: formId,
			},
			"post"
		);

	this.sendDocs = function () {
		if (!Docs?.length) return Promise.resolve(true);
		let postLinks = this.getLinksTo();
		let links = Docs.map((a) => {
			a.postId(PostId);
			if (a.isNew()) {
				if (a.addToDocsUtiles())
					a.setLinkTo(postLinks.links, postLinks.type);
				else a.setLinkTo([], postLinks.type);
				a.documentId(null);
				a.urls(null);
				return a.send();
			}
			return a.linkDoc();
		});
		return Promise.all(links);
	};

	this.unlinkDocs = function () {
		if (!PostId) return Promise.resolve(true);
		let prom = genRequest(
			"LtDocumentPosts/PostId/" + PostId,
			null,
			"delete"
		)
			.catch(() => Promise.resolve(true))
			.then(() => Promise.resolve(true));
		return prom;
	};

	this.sendImageRss = () => {
		let datas = {
			ImageSourceUrl: RssImages[0],
			Name: "RssImage-" + PostId,
			PostId: PostId,
			CompanyId: CompanyId,
			SiteId: SiteId,
			DocTypeId: 3,
		};

		let prom = genRequest(
			"Documents/DocumentFileFromUrl",
			datas,
			"post"
		).then((documentId) => {
			return that.linkImage(documentId);
		});
		return prom;
	};

	this.sendImages = function () {
		let proms = Images.map((img, index) => {
			if (
				!img.IsDeleted &&
				(img.HasChanged || img.NewIndex !== undefined || img.IsNew)
			) {
				if (
					img.OriginalDocumentId &&
					!img.FromGallery &&
					!img.IsCloned
				) {
					return that.deleteImage(img.OriginalDocumentId).then(() => {
						return that.sendImage(img, index);
					});
				}
				return that.sendImage(img, index);
			} else if (
				img.IsDeleted &&
				!img.IsNew &&
				img.OriginalDocumentId &&
				!img.FromGallery
			)
				return that.deleteImage(img.OriginalDocumentId);
			else if (img.OriginalDocumentId && !img.IsDeleted)
				return that.putImage(img, index);
			return false;
		});
		if (RssImages.length > 0) proms.push(that.sendImageRss());
		return Promise.all(proms);
	};

	this.putImage = function (img, index) {
		let img_index = img.NewIndex !== undefined ? img.NewIndex : index;
		let title = noAccent(Title).replace(/[\W]{1,}/gi, "_");
		let date = formatDate();
		let datas = {
			...img,
			Name: "pos:" + img_index + "_" + title + "_" + date,
			PostId: PostId,
			CompanyId: CompanyId,
			SiteId: SiteId,
			DocTypeId: 3,
			DocumentTypeId: 3,
			LastChanged: date,
			Url_Min: null,
			Url_Mobile: null,
			Url_Origin: null,
		};

		delete datas.DocumentFile;

		let prom = genRequest("Documents/" + img.DocumentId, datas, "put").then(
			() => {
				return that.linkImage(img.DocumentId);
			}
		);
		return prom;
	};

	this.sendImage = function (img, index) {
		let file = img.DocumentFile;

		index = img.NewIndex !== undefined ? img.NewIndex : index;
		if (file?.indexOf("data:image") !== -1) file = file.split(",")[1];
		let title = noAccent(Title).replace(/[\W]{1,}/gi, "_");
		let date = formatDate();
		let datas = {
			Name: "pos:" + index + "_" + title + "_" + date,
			PostId: PostId,
			CompanyId: CompanyId,
			SiteId: SiteId,
			FileDocString: file,
			DocTypeId: 3,
			LastChanged: date,
		};

		let prom = genRequest(
			"Documents/DocumentFileFromString",
			datas,
			"post"
		).then((documentId) => {
			return that.linkImage(documentId);
		});
		return prom;
	};

	this.deleteImage = (docId) =>
		genRequest(
			"Documents/DocumentId=" + docId + "AnddeleteExpenses=true",
			null,
			"delete"
		).catch(() => { });

	this.linkImage = (documentId) =>
		genRequest(
			"LtDocumentPosts",
			{
				PostId: PostId,
				DocumentId: documentId,
			},
			"post"
		);

	this.setAsPublished = function () {
		ValidationModeration = true;
		Draft = false;
		ModerationComment = "";
	};

	this.setAsDraft = function () {
		ValidationModeration = false;
		Draft = true;
		ModerationComment = "";
	};

	this.setAsToModerate = function () {
		ValidationModeration = false;
		Draft = false;
		ModerationComment = "";
	};

	this.setAsToModify = function (reason) {
		ValidationModeration = false;
		Draft = false;
		ModerationComment = reason;
	};

	this.notify = function (url_overload, data_overload) {
		if (
			!url_overload &&
			!IsRejected &&
			(!NotifyPost || LinkType === "groups" || PostTypeId === 8)
		)
			// don't notify if Animation (events) Post
			return Promise.resolve(true);

		let type = "News";
		let url;
		let data = {
			Message: Title,
			Author: Account,
			NewsId: PostId
		};

		if (url_overload) url = url_overload;
		else {
			if (PostTypeId === 3) type = "Article";
			else if (PostTypeId === 5) type = "CareerPublished";
			else if (PostTypeId === 14) type = "ProAds";
			else if (PostTypeId === 4) type = "SmallAds";
			if (IsRejected) url = type + "NotifyRefused";
			else {
				if (new Date(PublishedDate) > new Date())
					url = type + "NotifyDelay";
				else url = type + "Notify";
				if (LinkType === "sites") {
					if (!url_overload && type === "News") url += "Sites";
					data = {
						Message: Title,
						ListSiteId: LinkTo,
						Author: Account,
						PublicationDate: PublishedDate,
						NewsId: PostId

					};
				} else {
					data = {
						Message: Title,
						Author: Account,
						PublicationDate: PublishedDate,
						NewsId: PostId
					};
				}
			}
		}
		if (data_overload) data = data_overload;
		let req = this.fetchAuthor()
			.then((resp) => {
				data.Author = resp;
				let prom = genRequest("PushNotification/" + url, data, "post");
				return prom;
			})
			.then((resp) => {
				if (url.indexOf("Delay") === -1) return Promise.resolve(resp);
				let notif = {
					OneSignalId: resp.NotifId,
					PostId: PostId,
					TimeToNotify: data.PublicationDate,
				};
				return this.saveNotif(notif);
			});
		return req;
	};

	this.saveNotif = (data) =>
		genRequest("ScheduledNotifications/", data, "post");

	this.delete = function () {
		if (!PostId) return Promise.resolve(true);
		let req = genRequest(
			"Posts/PostId" +
			PostId +
			"AndDeleteDocument=falseAndDeleteComment=trueAndDeleteAnimation=trueAndDeleteParticipation=true",
			null,
			"delete"
		);
		return req;
	};

	this.lastChanged = () => LastChanged;

	this.validationModeration = function (setNew) {
		if (typeof setNew !== "undefined") ValidationModeration = setNew;
		return ValidationModeration;
	};

	this.draft = function (setDraft) {
		if (setDraft !== undefined) Draft = setDraft;
		return Draft;
	};

	this.isPublished = () => ValidationModeration && !Draft;

	this.postTypeId = () => PostTypeId;

	this.linkType = (set_type) => {
		if (set_type && set_type !== LinkType) LinkType = set_type;
		IsTargetPost = LinkType === "groups";
		return LinkType;
	};

	this.setLinkTo = function (items_list, link_type, default_site_id) {
		if (link_type) this.linkType(link_type);
		if (LinkType === "sites") {
			IsTargetPost = false;
			if (default_site_id) SiteId = default_site_id;
			if (
				!default_site_id &&
				items_list.indexOf(SiteId) === -1 &&
				items_list?.length
			)
				SiteId = items_list[0];
			LinkTo = items_list;
		} else {
			LinkTo = items_list;
			IsTargetPost = true;
		}
		return LinkTo;
	};

	this.getLinksTo = function () {
		if (!(LinkType && LinkTo)) return false;
		return {
			type: LinkType,
			links: LinkTo,
		};
	};

	this.linkedTo = () => LinkedTo;

	this.title = function (setTitle) {
		if (typeof setTitle !== "undefined") Title = setTitle;
		return Title;
	};

	this.subTitle = function (setTitle) {
		if (typeof setTitle !== "undefined") SubTitle = setTitle;
		return SubTitle;
	};

	this.txt = function (setTxt) {
		if (typeof setTxt !== "undefined") Txt = setTxt;
		return Txt;
	};

	this.mdeRef = function (setRef) {
		if (setRef) MDE_Ref = setRef;
		return MDE_Ref;
	};

	this.images = function (setImages) {
		if (setImages) Images = setImages;
		return Images;
	};

	this.rssImages = function (setImages) {
		if (setImages) RssImages = setImages;
		return RssImages;
	};

	this.removeRssImages = function () {
		RssImages = [];
	};

	this.postId = function (setId) {
		if (typeof setId !== "undefined") PostId = setId;
		return PostId;
	};

	this.moderationComment = function (setComm) {
		if (typeof setComm !== "undefined") {
			ValidationModeration = false;
			Draft = true;
			ModerationComment = setComm;
		}
		return ModerationComment;
	};

	this.notification = function (setNotif) {
		if (typeof setNotif !== "undefined") NotifyPost = setNotif;
		return NotifyPost;
	};

	this.categories = function (setCats) {
		if (typeof setCats !== "undefined") Categories = setCats;
		return Categories;
	};

	this.documents = function (setDocs) {
		if (typeof setDocs !== "undefined") {
			if (setDocs.length) {
				let res = setDocs.map((a) => {
					let doc = false;
					if (a.typeof) doc = a;
					else doc = new DocumentObject(a);
					let link = this.getLinksTo();
					if (link && doc.isNew())
						doc.setLinkTo(link.links, link.type);
					return doc;
				});
				Docs = res;
			} else Docs = setDocs;
		}
		return Docs;
	};

	this.publishedDate = function (setDate) {
		if (typeof setDate !== "undefined") PublishedDate = setDate;
		return PublishedDate;
	};

	this.isDiffered = () => new Date() < new Date(PublishedDate);

	this.isPriority = () => {
		if (!PriorityTo) return false;
		return new Date() < new Date(PriorityTo);
	};

	this.priorityTo = function (setDate) {
		if (typeof setDate !== "undefined") PriorityTo = setDate;
		return PriorityTo;
	};

	this.links = () => {
		if (LinkedSites?.length)
			return { type: "sites", links: LinkedSites.map((a) => a.SiteId) };
		if (LinkedGroups?.length)
			return {
				type: "groups",
				links: LinkedGroups.map((a) => a.GroupId),
			};
		return { type: "sites", links: [] };
	};

	this.linkedSites = () => LinkedSites;

	this.linkedGroups = () => LinkedGroups;

	this.linkedForms = (setForms) => {
		if (setForms) LinkedForms = setForms;
		return LinkedForms;
	};

	this.targets = (values) => {
		if (values) Targets = values;
		return Targets;
	};

	this.linkTargets = () => {
		if (!Targets?.length) return Promise.resolve();
		let items = Targets.map((a) => ({
			EmployesId: a.EmployesId,
			PostId,
		}));
		let prom = genRequest("LtEmployeAlerts/MultiplePOST", items, "post");
		return prom;
	};

	this.linkTarget = (employeId) =>
		genRequest("LtEmployeAlerts", {
			EmployesId: employeId,
			PostId,
		});

	this.sendReponse = () => {
		let that = this;
		if (!Response) return Promise.resolve(true);
		if (Response && !Response.txt()) return this.deleteResponse();
		let prom = new Promise((resolve, reject) => {
			Response.send()
				.then(() => {
					return that.linkResponse();
				}, reject)
				.then(resolve, reject);
		});
		return prom;
	};

	this.deleteResponse = () => {
		if (!Response) return Promise.resolve(true);
		let prom = new Promise((resolve, reject) => {
			this.unlinkResponse()
				.then(
					() => {
						return Response.delete();
					},
					() => Response.delete()
				)
				.then(() => {
					Response = false;
					resolve(true);
				}, reject);
		});
		return prom;
	};

	this.unlinkResponse = () => {
		if (!Response || !Response?.postId()) return Promise.resolve(true);
		let req = genRequest(
			"LtNewsResponse/PostIdAndResponseId/" +
			PostId +
			"/" +
			Response.postId(),
			null,
			"delete"
		);
		return req;
	};

	this.linkResponse = () => {
		if (!Response || !Response?.postId()) return Promise.resolve(true);
		let req = genRequest(
			"LtNewsResponse",
			{
				NewsId: PostId,
				ResponseId: Response.postId(),
			},
			"post"
		);
		return req;
	};

	this.setResponse = (txt, forceEmployeId, forceReset) => {
		let obj = Response;
		if (!obj || forceReset)
			obj = new PostObject({
				PostTypeId: 2,
				EmployesId: forceEmployeId ? forceEmployeId : EmployesId,
				SiteId,
				Title: "Complement d'information",
			});

		obj.txt(txt);
		Response = obj;
		return Response;
	};

	this.response = () => {
		if (!Response) {
			let obj = new PostObject({
				PostTypeId: 2,
				EmployesId,
				SiteId,
				Title: "Complement d'information",
				Txt: "",
			});
			Response = obj;
		}
		return Response;
	};

	this.siteId = () => SiteId;

	this.employeId = () => EmployesId;

	this.reactionType = (setType) => {
		if (typeof setType !== "undefined") ReactionType = setType;
		return ReactionType;
	};

	this.values = (with_sub_title) => ({
		PostId: PostId,
		PostTypeId: PostTypeId,
		Title:
			(with_sub_title && SubTitle?.length
				? `${SubTitle}/__CUT__\\`
				: "") + Title,
		EmployesId: EmployesId,
		Txt: Txt,
		SiteId: SiteId,
		ValidationModeration: ValidationModeration,
		Draft: Draft,
		Adress: Adress,
		ViewCount: ViewCount,
		LikesCount: LikesCount,
		PublishedDate: PublishedDate,
		PriorityTo: PriorityTo,
		ModerationComment: ModerationComment,
		LastChanged: LastChanged,
		LastChange_AuthorId: LastChange_AuthorId,
		HasDuplicate: HasDuplicate,
		LanguageId: LanguageId,
		IsTargetPost: IsTargetPost,
	});
}
