import React, { useEffect, useState } from "react";
import { t } from "i18next";
import genRequest from "includes/request";
import { QuerySelect } from "components/Inputs/CustomAsyncSelect/CustomAsyncSelect";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import Tabs from "components/Tabs/Tabs";
import useAccount from "classes/Accounts/hooks/useAccount";
import useTemplate from "hooks/useTemplate";

export default function AnimationSendOptionsStep(props) {
	const { getSettings, account } = useAccount();
	const [tabs, setTabs] = useState([]);
	let anim = props.object;
	const { getAppFunction } = useTemplate();

	const [NotifyAnim, setNotifyAnim] = useState(anim.notification());
	const [Participation, setParticipation] = useState(
		anim.participation() !== null
			? anim.participation()
			: getAppFunction("AnimationsPage.CanParticipate")
	);

	function handleNotification(e) {
		anim.notification(e.target.checked);
		setNotifyAnim(e.target.checked);
	}

	function handleParticipation(e) {
		anim.participation(e.target.checked);
		setParticipation(e.target.checked);
	}

	useEffect(() => {
		setTabs([
			{
				title: t("Commons.PUBLISH_BY_SITES"),
				isVisible: true,
				child: () => <PublishBySites anim={anim} />,
			},
			{
				title: t("Groups.PUBLISH_BY_GROUPS"),
				isVisible: true,
				child: () => <PublishByGroups anim={anim} />,
			},
		]);
	}, [account]);

	let cur_index = 0;
	let linksTo = anim.getLinksTo();
	let initialLinks = anim.links();

	if (!linksTo.links?.length)
		anim.setLinkTo(initialLinks.links, initialLinks.type);

	let default_link = getSettings("DefaultLinkType");

	if (!anim.animationId()) cur_index = default_link === "groups" ? 1 : 0;
	else if (anim.linkType() === "groups") cur_index = 1;

	return (
		<div>
			<div className="inner-step-cont bg-color-secondary">
				<Tabs
					items={tabs}
					allowOverflow={true}
					activeIndex={cur_index}
					onChange={(tab) => {
						anim.linkType(tab.id === 1 ? "groups" : "sites");
					}}
				/>
			</div>
			<div className="mt-2 inner-step-cont">
				<div className="mb-1">{t("Commons.NOTIFICATION")}</div>
				<SwitchInput
					label={t("Commons.NOTIFY_USERS")}
					checked={NotifyAnim}
					onChange={handleNotification}
				/>
			</div>
			<div className="mt-2 inner-step-cont">
				<div className="mb-1">{t("Events.PARTICIPATION")}</div>
				<SwitchInput
					label={t("Events.ENABLE_PARTICIPATION")}
					checked={Participation}
					onChange={handleParticipation}
				/>
			</div>
		</div>
	);
}

function PublishBySites({ anim }) {
	const { account } = useAccount();

	function sitesSelect(values) {
		anim.setLinkTo(
			values.map((a) => a.value),
			"sites"
		);
	}

	function getInitialValues() {
		let initialValues = anim.getLinksTo();

		if (!initialValues?.links?.length)
			return anim
				.linkedSites()
				.map((a) => ({ label: t("Commons.LOADING"), value: a.SiteId }));
		return initialValues.links.map((a) => ({
			label: t("Commons.LOADING"),
			value: a,
		}));
	}

	return (
		<div className="sites-drop-cont">
			<QuerySelect
				placeholder={t("Commons.SELECT_SITES")}
				className={"bg-color w-100 rounded"}
				classNamePrefix="dropdown"
				queryKey={["Sites", "SitesOfCompany", account.CompanyId]}
				queryFn={() =>
					genRequest("Sites/SitesOfCompany/" + account.CompanyId)
				}
				treat={(site) => ({
					value: site.SiteId,
					label: site.Name + " - " + site.Town,
				})}
				all={{
					label: t("Commons.ALL_SITES"),
					value: "ALL",
				}}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				onSelect={sitesSelect}
				initialValue={getInitialValues()}
			/>
		</div>
	);
}

function PublishByGroups({ anim }) {
	const { account } = useAccount();

	function groupsSelect(values) {
		anim.setLinkTo(
			values.map((a) => a.value),
			"groups"
		);
	}

	function getInitialValues() {
		let initialValues = anim.getLinksTo();

		if (!initialValues?.links?.length)
			return anim
				.linkedGroups()
				.map((a) => ({ label: "Chargement", value: a.GroupId }));
		return initialValues.links.map((a) => ({
			label: "Chargement",
			value: a,
		}));
	}

	return (
		<div className="groups-drop-cont">
			<QuerySelect
				placeholder={t("Groups.SELECT_GROUPS")}
				className={"bg-color w-100 rounded"}
				classNamePrefix="dropdown"
				queryKey={["Groups", "OfCompany", account.CompanyId]}
				queryFn={() =>
					genRequest("Groups/OfCompany/" + account.CompanyId)
				}
				treat={(cat) => ({
					value: cat.GroupId,
					label: cat.Name,
				})}
				all={{
					label: t("Groups.ALL_GROUPS"),
					value: "ALL",
				}}
				isMulti={true}
				isSearchable={true}
				closeMenuOnSelect={false}
				onSelect={groupsSelect}
				initialValue={getInitialValues()}
			/>
		</div>
	);
}
