import useAccount from "classes/Accounts/hooks/useAccount";
import Button from "components/Button/Button";
import { t } from "i18next";
import React, { useState } from "react";

export default function ButtonsStyle() {
	const {getSettings, setSettings} = useAccount();
	const [current, setCurrent] = useState(getSettings("ButtonsStyle") === "rounded" ? "rounded" : "default");

	function changePreset(state) {
		window.document.body.classList.remove(current + "-buttons");
		window.document.body.classList.add(state + "-buttons");
		setCurrent(state);
		setSettings("ButtonsStyle", state);
	}

	return (
		<div className="d-flex gap-3 align-items-center">
			<div>
				<Button onClick={() => changePreset("default")} className={"not-rounded" + (current === "default" ? " btn-primary" : "")}>{t("Settings.DEFAULT")}</Button>
			</div>
			<div className="rounded-buttons">
				<Button onClick={() => changePreset("rounded")} className={current === "rounded" ? "btn-primary" : ""}>{t("Settings.ROUNDED")}</Button>
			</div>
		</div>
	);
}
