import React, { useState } from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import { dateAdd } from "includes/formatDate";
import genRequest from "includes/request";
import {useQuery} from "react-query";
import "./OnlineUsers.css";
import { t } from "i18next";
import LoadCont from "components/LoadCont/LoadCont";
import AuthorPreview, { DatePreview } from "components/User/AuthorPreview/AuthorPreview";
import Button from "components/Button/Button";

export default function OnlineUsers(props) {
	const {account} = useAccount();
	const [display, setDisplay] = useState("day");
	const [ShowActived, setShowActived] = useState(false);

	let propKey = "LastConnexion";
	if (ShowActived)
		propKey = "FirstConnexion";

	const fetchEmployes = () => genRequest("Employes/EmployeOfCompany/" + account.CompanyId);

	const {isLoading, data} = useQuery(
		["Employes", "EmployeOfCompany", account.CompanyId],
		fetchEmployes,
		{
			refetchInterval: 1000 * 60
		}
	);

	const getDate = () => {
		let date = new Date();
		if (display === "day")
			return (date.setHours(0, 0, 0, 0));
		else if (display === "week")
			return (dateAdd(date, {weeks: -1}));
		else if (display === "month")
			return (dateAdd(date, {months: -1}));
	};

	const getUsers = (list) => {
		let diffDate = getDate();

		if (!list)
			return ([]);
		let ret = list.sort((a, b) => (a[propKey] < b[propKey] ? 1 : -1)).map((a) => {
			let date = new Date(a[propKey]);
			if (diffDate < date && a.EmployesId !== account.EmployesId)
				return (a);
			return (false);
		}).filter(a => a);
		return (ret);
	};

	let users = getUsers(data);

	return (
		<div className="OnlineUsers">
			<div className="head d-flex flex-wrap mb-2 justify-content-between flex-column">
				<div className="me-2">
					<div className="stat-module-title">
						{ t(ShowActived ? "Analytics.ACTIVATED_ACCOUNTS" : "Analytics.LAST_CONN") }
					</div>
					<div className="sub-info">
						{
							t("Analytics.userscount",
								{
									context: ShowActived ? "activated" : "connection",
									count: users?.length
								}
							)
						}
					</div>
				</div>
				<div className="d-flex justify-content-between flex-wrap gap-1">
					<div className="head-btns buttons-group">
						<Button onClick={() => setShowActived(true)} className={"unstyled" + (ShowActived ? " active" : "")}>{t("Analytics.ACTIVATIONS_BTN")}</Button>
						<Button onClick={() => setShowActived(false)} className={"unstyled" + (!ShowActived ? " active" : "")}>{t("Analytics.BTN_CONNECTIONS")}</Button>
					</div>
					<div className="ms-auto head-btns buttons-group">
						<Button onClick={() => setDisplay("month")} className={"unstyled" + (display === "month" ? " active" : "")}>{t("Analytics.MONTH")}</Button>
						<Button onClick={() => setDisplay("week")} className={"unstyled" + (display === "week" ? " active" : "")}>{t("Analytics.WEEK")}</Button>
						<Button onClick={() => setDisplay("day")} className={"unstyled" + (display === "day" ? " active" : "")}>{t("Analytics.DAY")}</Button>
					</div>
				</div>
			</div>
			{
				isLoading ?
					<div className="d-flex justify-content-center">
						<LoadCont text={t("Commons.LOADING")}/>
					</div>
					:
					<UsersList max={props.max} users={users} firstConnexion={ShowActived}/>
			}
		</div>
	);
}

function UsersList({users, max, firstConnexion}) {
	if (!users?.length)
		return (
			<div className="list-ph">
				{ t("Analytics.NO_USERS") }
			</div>
		);
	let propKey = "LastConnexion";
	if (firstConnexion)
		propKey = "FirstConnexion";
	let list = users.sort((a, b) => (a[propKey] < b[propKey] ? 1 : -1));
	if (max)
		list = list.slice(0, max);
	return (
		<div className="OnlineUsersList">
			{
				list.map((a) => <UserTile key={a.EmployesId} user={a} firstConnexion={firstConnexion}/>)
			}
			{
				list?.length < users?.length &&
				<div className="list-ph w-100">
					{
						t(
							"Analytics.others",
							{ count: users?.length - list?.length }
						)
					}
				</div>
			}
		</div>
	);
}

function UserTile({user, firstConnexion}) {
	let propKey = "LastConnexion";
	if (firstConnexion)
		propKey = "FirstConnexion";
	let isOnline = new Date(user[propKey]) > dateAdd(new Date(), {minutes: -5});
	return (
		<div className="UserTile d-flex align-items-center justify-content-between">
			<div className="d-flex align-items-center">
				<AuthorPreview
					className={"user-preview"}
					imgWidth={20}
					employeId={user.EmployesId}
					format={[
						"EmployeFirstName EmployeLastName",
					]}
					modifiers={{
						EmployeLastName: (item) => item.toUpperCase()
					}}
				/>
			</div>
			<div className="last-conn">
				{
					isOnline ?
						<div className="d-flex align-items-center">
							<div className="me-2">
								{t("Analytics.ONLINE")}
							</div>
							<div className="online-dot"></div>
						</div>
						:
						<DatePreview
							date={user[propKey]}
							max={1}
						/>
				}
			</div>
		</div>
	);
}
