import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import "./SearchBar.css";

export default function SearchBar(props) {
	const [value, setValue] = useState("");
	const [timeout, setTm] = useState(false);

	function handleSearch(val, cleared) {
		if (val !== value) setValue(val);
		if (props.onChange) {
			clearTimeout(timeout);
			let ts = setTimeout(
				() => {
					props.onChange(val);
				},
				!cleared ? props.timeout : 0
			);
			setTm(ts);
		}
	}

	return (
		<div
			className={
				"SearchBar search-bar-cont" +
				(props.className ? " " + props.className : "")
			}
			title={t("Commons.SEARCH")}
		>
			<input
				type="search"
				value={value}
				placeholder={t("Commons.SEARCH")}
				onChange={(e) => handleSearch(e.target.value)}
			/>
			{value.length > 0 && (
				<div
					className="clear-field"
					onClick={() => handleSearch("", true)}
				>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			)}
			<button
				className="search-btn"
				type="submit"
				onClick={() => handleSearch(value, true)}
			>
				<FontAwesomeIcon icon={faSearch} />
			</button>
		</div>
	);
}
