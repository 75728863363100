import React, { useState } from "react";
import {useQueryClient} from "react-query";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import useAccount from "classes/Accounts/hooks/useAccount";
import { t } from "i18next";
import GroupObject from "classes/Groups/GroupObject";
import GroupEdit from "./components/GroupEdit/GroupEdit";
import genRequest from "includes/request";
import { AsyncUsersList } from "components/Users/UsersList/UsersList";
import GroupsTiles from "./components/GroupTiles/GroupsTiles";
import useDisplay from "hooks/useDisplay";
import useTemplate from "hooks/useTemplate";
import GroupsTable from "./components/GroupsTable/GroupsTable";
import useEditing from "components/EditDrawer/useEditing";

function GroupsPage(props) {
	const { template } = useTemplate();
	const { display, setDisplay } = useDisplay();
	const {account} = useAccount();
	const [search, setSearch] = useState();
	const [editObj, setEditObj] = useState(false);
	const {setEditing} = useEditing();
	const [selecteds, setSelected] = useState([]);
	const {isMenuOpen, setIsMenuOpen} = props;
	const QC = useQueryClient();

	function handleSearch(resp) {
		setSearch(resp);
	}

	function changeDisplay() {
		if (display === "table")
			setDisplay("tiles");
		else
			setDisplay("table");
		setSelected([]);
	}

	function handleAdd(to_modify) {
		let group = new GroupObject({
			CompanyId: account.CompanyId,
			DefaultHexaColor: template?.primaryColor(),
			...to_modify
		});
		if (to_modify) {
			group.fetchUsers().then((users) => {
				group.linksTo(users);
				setEditObj(group);
				setEditing(true);
			});
		} else {
			setEditObj(group);
			setEditing(true);
		}
		setSelected([]);
	}

	function closeEdit() {
		setEditObj(false);
		setEditing(false);
		setSelected([]);
		QC.resetQueries(["Groups", "OfCompany"]);
	}

	return (
		<div className="d-flex flex-column h-100">
			<PageHeadBar
				title={t("Pages.GROUPS")}
				siteSelect={false}
				display={display}
				setDisplay={changeDisplay}
				search={handleSearch}
				handleAdd={handleAdd}
				addTitle={t("Groups.ADD_GROUP")}
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
			/>
			<PageBody disp={display} search={search} handleAdd={handleAdd} setSelected={setSelected} selected={selecteds}/>
			<GroupEdit
				onClose={closeEdit}
				group={editObj}
				isOpen={editObj}
				companyId={account?.CompanyId}
				title={(editObj && editObj?.groupId() ? t("Groups.MODIFY_GROUP") : t("Groups.ADD_GROUP"))}
			/>
		</div>
	);
}

function PageBody(props) {
	const {search, handleAdd, setSelected, selected} = props;

	if (props.disp === "table") {
		return (
			<GroupsTable
				search={search}
				onItemClick={handleAdd}
				setSelected={setSelected}
				selected={selected}
			/>
		);
	}
	return (
		<GroupsTiles onClick={handleAdd} search={search}/>
	);
}

export function Members(props) {
	return (
		<AsyncUsersList
			queryFn={() => genRequest("Employes/EmployeOfCategory/" + props.item?.GroupId)}
			queryKey={["Employes", "EmployeOfCategory", props.item?.GroupId]}
			max={10}
			width={20}
		/>
	);
}

export default GroupsPage;
