import React, { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "i18next";
import Post from "classes/Posts/PostModel";
import LoadCont from "components/LoadCont/LoadCont";

export default function PostsTilesList(props) {
	let ref = useRef();

	function handleScroll() {
		let el = ref.current?.el;
		if (el.scrollHeight <= el.clientHeight && props.hasMore)
			props.getNextItems();
	}

	React.useEffect(() => {
		let el = ref.current?.el;

		handleScroll();
		el.addEventListener("mousewheel", handleScroll, { passive: true });
		return () => {
			el.removeEventListener("mousewheel", handleScroll);
		};
	}, []);

	return (
		<div
			id="posts-cont"
			className={
				"posts-cont xd w-100" + (!props.curPost ? " no-cur" : "")
			}
		>
			<InfiniteScroll
				className="d-flex flex-wrap overflow-x-hidden"
				ref={ref}
				dataLength={props.displayed?.length} //This is important field to render the next data
				next={props.getNextItems}
				hasMore={props.hasMore}
				loader={
					<LoadCont className="w-100" text={t("Commons.LOADING")} />
				}
				height={"100%"}
				endMessage={
					<div className="w-100 infinite-scroll-end-msg mt-4 mb-4">
						<p style={{ textAlign: "center" }}>
							<b>
								{props.search?.length &&
								!props.displayed?.length
									? t("Posts.NO_RESULT")
									: t("Posts.LIST_END")}
							</b>
						</p>
					</div>
				}
			>
				{props.displayed.map((post) => {
					let isActive =
						props.curPost && props.curPost.PostId === post.PostId;
					return (
						<Post
							typeId={post.TypeId}
							key={post.PostId}
							datas={post}
							isActive={isActive}
							onClick={props.handlePostClick}
							dispImages={props.images}
							dispCats={props.dispCats}
						/>
					);
				})}
			</InfiniteScroll>
		</div>
	);
}
